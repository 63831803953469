const fiftyDollars = [
    "Captain America",
    "Spider-Man (Avengers: Infinity War)",
    "Groot (Avengers: Infinity War)",
    "Black Panther",
    "Iron Man Mk XLVI (Captain America: Civil War)",
    "The Classic Master Box Set",
    "Ant-Man",
    "Captain America (Avengers: Endgame)",
    "Captain Marvel",
    "Loki (The Avengers)",
    "Star-Lord (Avengers: Infinity War)",
    "Thor (Avengers: Infinity War)",
    "War Machine (Iron Man 2)",
    "Winter Soldier (Avengers: Infinity War)"
]
const fortyFiveDollars = [
    "Bane Special Edition",
    "1st Doctor & Susan, Ian and Barbara Companion Set (Black and White Variant)",
    "Weeping Angel (Statue of Liberty)",
    "Cyber-Controller on Throne",
    "Rhaegal Dragon",
    "Viserion Dragon"
];
const fortyDollars = [
    "4 Doctors Set",
    "WWE Wrestlemania 25 Double Pack: The Undertaker & Shawn Michaels",
    "WWE Iconic Tag Team: Rock ’N’ Sock Connection",
    "The Eighth Doctor, Liv Chenka, Dalek (Big Finish) Figurine Set",
    "Star Trek Tardigrade Figure (Ripper)",
    "The Incredible Hulk & She-Hulk (Hero Rooks) Special Edition",
    "Baby Nifflers",
    "WWE Iconic Tag Team: Edge & Christian",
    "Iron Man Mark VII Helmet Replica",
    "Black Panther’s Mask Replica",
    "The Infinity Gauntlet Replica",
    "Captain America’s Shield Replica",
    "Mjolnir (Thor's Hammer) Replica",
    "Revolution of the Daleks Set",
    "WWE Iconic Family: Jim \"The Anvil\" Neidhart & Natalya",
    "Green Goblin Marvel VS.",
    "Iron Man Marvel VS.",
    "Captain America Marvel VS.",
    "Spider-Man Marvel VS.",
    "Thor Marvel VS.",
    "Loki Marvel VS."
]
const thirtyFiveDollars = [
    "4 Doctors Set",
    "Kroton Special Edition",
    "4th Doctor & Sarah Jane Companion Set",
    "Dragon Biomechanoid",
    "Ace & The Seventh Doctor Companion Set",
    "Dalek Drone and Dalek Emperor Set",
    "Superman and Wonder Woman Boxset",
    "Dalek Time Commander and Dalek Scientist Set",
    "Missy and Rassilon Time Lords Set",
    "The Melkur"
];
const thirtyDollars = [
    "Rubeus Hagrid Special Edition",
    "8th Doctor, Lucie Miller, Dale Companion Set",
    "Dobby the Elf"
]
const twentyFiveDollars = [
    "Evolution of The Cybermen Set 2",
    "2nd Doctor & Jamie McCrimmon",
    "The Niffler (Fantastic Beasts)",
    "Pickett the Bowtruckle",
    "Borusa and Romana Time Lords Set",
    "Rob Gronkowski",
    "Pennywise (IT: Chapter Two)",
    "Pennywise (IT, 1990)",
    "Annabelle (Annabelle Comes Home)",
    "Valak (The Nun)",
    "The Crooked Man",
    "Stripe (Gremlins)",
    "Batmobile (Batman 1966)",
    "Batmobile (Batman 1989)"
]
const twentyDollars = [
    "Cyber Controller",
    "Silurian Warrior",
    "Time Lord Rassilon",
    "Omega",
    "Kahler-Tek",
    "Terileptil Leader",
    "Dalek Sec Hybrid",
    "Auton",
    "Chantho",
    "Venom (Black Pawn)",
    "Scarecrow",
    "Jabe",
    "Sensorite",
    "Sontaran Commander Linx",
    "The Hath",
    "Carrionite",
    "The Destroyer",
    "Penguin",
    "Poison Ivy",
    "The Joker",
    "Robin",
    "Ice Queen",
    "Superman",
    "Two-Face",
    "Golden Eagle Armor Wonder Woman",
    "Wonder Woman",
    "Green Lantern",
    "First Appearance Wonder Woman",
    "Stone Dalek",
    "Classic Ice Warrior",
    "Martian Manhunter",
    "Professor Dumbledore (Michael Gambon)",
    "Divine Armor Wonder Woman",
    "Lord Voldemort (Battle of Hogwarts)",
    "Dementor (Guard of Azkaban)",
    "Newt Scamander",
    "Occamy",
    "Tina Goldstein",
    "Queenie Goldstein",
    "Harry Potter (Battle of Hogwarts)",
    "Fawkes",
    "Ron Weasley with Scabbers",
    "AJ Styles",
    "Hermione Granger",
    "Albus Dumbledore (Fantastic Beasts)",
    "Batman",
    "Xavier Woods",
    "The Undertaker",
    "Braun Strowman",
    "Batman and The Joker Boxset",
    "Joker",
    "The Rock",
    "Seth Rollins",
    "Bellatrix Lestrange",
    "Harley Quinn",
    "Gellert Grindelwald (Fantastic Beasts)",
    "Theseus Scamander (Fantastic Beasts)",
    "Sniper Bot",
    "Macho Man Randy Savage",
    "Triple H",
    "Alastor Mad-Eye Moody",
    "Luna Lovegood",
    "Bayley",
    "Bret Hart",
    "Ray Stantz",
    "Egon Spengler",
    "Scarecrow",
    "Seraphina Picquery (Fantastic Beasts)",
    "Leta Lestrange (Fantastic Beasts)",
    "Daniel Bryan",
    "Peter Venkman",
    "Minerva McGonagall",
    "Ronda Rousey",
    "Kofi Kingston",
    "Winston Zeddemore",
    "Batman Silver Age 1960s",
    "Batman Bronze Age 1970s",
    "Batman Modern Age 1980s",
    "Batman Debut 1940s",
    "Kurt Angle",
    "Batman Golden Age 1950s",
    "Jacob Kowalski (Fantastic Beasts)",
    "Dougal Demiguise (Fantastic Beasts)",
    "Lucius Malfoy",
    "Draco Malfoy (Death Eater)",
    "Cyber Scout",
    "Ribbons of the Seven Stomachs",
    "New Paradigm Eternal Dalek",
    "The Morax Queen",
    "Lin-Dalek",
    "Batman Modern Age 1990s",
    "Batman Modern Age 2000s",
    "Kevin Owens",
    "Slimer (The Green Ghost)",
    "Big E",
    "Sami Zayn",
    "Asuka",
    "Becky Lynch",
    "Credence Barebone Figurine (Fantastic Beasts)",
    "Maledictus Figurine (Fantastic Beasts)",
    "Neville Longbottom",
    "Nagini",
    "Ginny Weasley",
    "Samoa Joe",
    "T’zim-Sha",
    "Dolores Umbridge",
    "Pete Dunne",
    "Shinsuke Nakamura",
    "Nicolas Flamel (Fantastic Beasts)",
    "Yusuf Kama (Fantastic Beasts)",
    "Steve Austin",
    "Cactus Jack",
    "The Miz",
    "Ron Weasley 7th Year (Harry Potter)",
    "Hermione Granger 7th Year (Harry Potter)",
    "Batman Modern Age 2010s",
    "Super Voc",
    "Pol-Kon-Don (Judoon Captain)",
    "The Monk and The Master Time Lords Figurine Set",
    "Albus Dumbledore (Richard Harris)",
    "Percival Graves",
    "Fred Weasley",
    "George Weasley",
    "Cho Chang",
    "Razor Ramon",
    "Victor Zsasz",
    "Peter Pettigrew",
    "Cedric Diggory",
    "Hermione Granger (Yule Ball)",
    "Remus Lupin",
    "The Dreg",
    "Harry Potter (Yule Ball)",
    "Novice Hame",
    "Sea Devil Warrior",
    "Garrick Ollivander",
    "Hulk Hogan",
    "Eddie Guerrero",
    "Jeff Hardy",
    "Ric Flair",
    "The Skithran Queen",
    "The Fugitive Doctor",
    "The Snowman",
    "Spider-Man (Hero Knight)",
    "Iron Man (Hero Bishop)",
    "The Toymaker",
    "Wolverine (Hero Knight)",
    "Loki (Villain Bishop)",
    "Professor Pomona Sprout",
    "Professor Filius Flitwick",
    "Newt Scamander Figurine (Fantastic Beasts 2)",
    "Ron Weasley Figurine (Yule Ball)",
    "Professor Quirinus Quirrell",
    "Gilderoy Lockhart",
    "Morty Smith",
    "Mr. Meeseeks",
    "Summer Smith",
    "Griphook",
    "Amazing Spider-Man #65 Felipe Massafera Eaglemoss Exclusive Variant",
    "Mestor",
    "Chameleon",
    "Bok",
    "Primord",
    "Navarino",
    "Mutt",
    "Batman (White King)",
    "Joker (Black King)",
    "Robin (White Bishop)",
    "The Penguin (Black Knight)",
    "Catwoman (White Queen)",
    "Batgirl (White Knight)",
    "Two Face (Black Knight)"
];

const makeUpchargeArray = (count, amount) => new Array(count).fill(amount);

const upcharge = [
    ...makeUpchargeArray(twentyDollars.length, 0),
    ...makeUpchargeArray(twentyFiveDollars.length, 5),
    ...makeUpchargeArray(thirtyDollars.length, 10),
    ...makeUpchargeArray(thirtyFiveDollars.length, 15),
    ...makeUpchargeArray(fortyDollars.length, 20),
    ...makeUpchargeArray(fortyFiveDollars.length, 25),
    ...makeUpchargeArray(fiftyDollars.length, 30)
];
const localeComapreSort = (a, b) => a.localeCompare(b);
export const figureOptions = [{
    name: 'Character',
    options: [
        ...twentyDollars.sort(localeComapreSort),
        ...twentyFiveDollars.sort(localeComapreSort),
        ...thirtyDollars.sort(localeComapreSort),
        ...thirtyFiveDollars.sort(localeComapreSort),
        ...fortyDollars.sort(localeComapreSort),
        ...fortyFiveDollars.sort(localeComapreSort),
        ...fiftyDollars.sort(localeComapreSort)
    ],
    upcharge
}];

const hundredDollarShips = [
    "Exclusive Collector's Set of Star Trek Shuttles 2",
    "Exclusive Collector's Set of Star Trek Shuttles 4",
    "Exclusive Collector's Set of Star Trek Shuttles 5",
    "Exclusive Collector's Set of Star Trek Shuttles 6",
    "Exclusive Collector's Shuttlecraft Set 7"
];

const eightyDollarShips = [
    "U.S.S. Enterprise NCC-1701 Collector's XL Edition",
    "U.S.S Enterprise NCC 1701-E Collector's XL Edition",
    "U.S.S. Enterprise NCC-1701-D Collector's XL Edition",
    "U.S.S. Bonchune Nebula-Class XL Edition",
    "U.S.S. Equinox NCC-72381 XL Edition",
    "Eagle One Booster Pod",
    "Eagle One Laboratory",
    "U.S.S. Pegasus NCC-53847 XL Edition",
    "La Sirena XL Edition",
    "Eagle One Transporter"
];

const seventyFiveDollarShips = [
    "Enterprise NCC-1701 Set",
    "Enterprise NX-01 8.5-inch XL Edition",
    "U.S.S. Voyager 10-inch XL Edition",
    "U.S.S. Enterprise NCC-1701-A 10.5-inch XL Edition",
    "U.S.S. Enterprise NCC-1701-B 10.5-inch XL Edition",
    "U.S.S. Reliant 8.5-inch XL Edition",
    "Klingon Bird-of-Prey Box Set",
    "U.S.S. Enterprise NCC-1701 10-inch XL Edition (Discovery)",
    "Sarcophagus Special Edition",
    "Klingon Bird-of-Prey XL Edition",
    "Runabout 8.5-inch XL Edition",
    "U.S.S. Thunderchild (Akira Class) 9-Inch XL Edition",
    "Romulan Warbird 8.5-inch XL Edition",
    "K'T'inga-Class Battle Cruiser XL Edition",
    "Deep Space 9 XL Edition",
    "I.S.S. Charon Special Edition",
    "U.S.S. Enterprise NCC-1701-J XL Edition",
    "Future U.S.S. Enterprise NCC-1701-D (All Good Things)",
    "U.S.S. Shenzhou XL Edition",
    "ECV-197 Orville XL Edition",
    "Starbase-1 Space Station",
    "U.S.S. Enterprise (Star Trek 2009) XL Edition",
    "Delta Flyer XL Edition",
    "U.S.S. Defiant 8.5-inch XL Edition"
];

const sixtyFiveDollarShips = [
    "Cylon Raider (2004)",
    "Narcissus",
    "Viper Mk VII",
    "Cylon Raider (Classic)",
    "Raptor (2004)",
    "Cylon Raider (Blood & Chrome)",
    "Lander One (Alien Covenant)",
    "Basestar (2004)",
    "Battlestar Valkyrie",
    "Colonial One",
    "Viper Mk III (Blood and Chrome)",
    "U.S.S. Enterprise NCC-1707-D Gold Model Starship",
    "Cylon Raider (Scar)",
    "The Betty",
    "Blackbird",
    "Alien Covenant Lifter",
    "Classic Landram",
    "Armored Personnel Carrier",
    "Cylon Heavy Raider",
    "Colonial Heavy Raptor",
    "U.S.C.S.S. Prometheus",
    "U.S.S. Enterprise NCC-1701 Gold Model Starship",
    "Loki Heavy Cruiser",
    "Osiris Battleship"
];

const fiftyFiveDollarShips = [
    "U.S.S. Enterprise XCV-330 Special Edition",
    "U.S.S. Shenzhou",
    "U.S.S. Discovery NCC-1031",
    "U.S.S. Kerala",
    "Klingon Bird-of-Prey Starship",
    "U.S.S. Europa",
    "Vulcan Cruiser",
    "U.S.S. Buran",
    "Klingon Qugh-Class Destroyer",
    "Klingon Qoj Class",
    "U.S.S. Clarke",
    "Section 31 Drone",
    "U.S.S. Shran",
    "U.S.S Enterprise NCC-1701 Starship",
    "Klingon Cleave Ship",
    "The Festoon Yacht",
    "Planet Killer Special Edition",
    "T'Plana-Hath",
    "U.S.S. Discovery's Shuttlecraft (Type-C)",
    "U.S.S. Hiawatha NCC-815",
    "Stealth Ship",
    "Beacon of Kahless Starship",
    "Klingon Daspu' Class",
    "Klingon D7-Class Battle Cruiser",
    "Landing Pod",
    "U.S.S. Zimmerman NCC-1616",
    "Section 31 Nimrod-Class",
    "I.S.S. Shenzhou Variant",
    "Ba'ul Fighter",
    "Klingon Batlh Class",
    "Section 31 Shiva-Class",
    "Son’a Collector Spaceship",
    "Section 31 Hou Yi-Class",
    "Klingon Chargh-Class Battleship",
    "La Sirena Starship",
    "Romulan Bird-of-Prey",
    "Seven of Nine’s Fenris Ranger Ship",
    "Romulan Vessel",
    "Narek’s Snake Head",
    "Starfleet Wallenberg Tug",
    "Section 31 Deimos-Class",
    "Romulan Flagship"
];

const fiftyDollarShips = [
    "Deep Space 9 Special Edition",
    "Star Trek U.S.S. Enterprise NCC-1701 Special Edition",
    "U.S.S. Kelvin Special Edition",
    "S.S. Enterprise NX-01 Refit Special Edition",
    "Klingon D4 Bird-of-Prey Special Edition",
    "Spock's Jellyfish Special Edition",
    "U.S.S. Franklin Special Edition",
    "Deep Space Station K-7 Special Edition",
    "Klingon Battle Cruiser Special Edition",
    "U.S.S. Zheng He",
    "U.S.S. Kobayashi Maru Special Edition",
    "Spacedock Special Edition",
    "The U.S.S. Enterprise NCC-1701-D Box Set",
    "Scimitar Starship",
    "Son’a Flagship",
    "U.S.S. Enterprise NCC-1701 & 1701-A Illustrated Handbook Plus Collectible",
    "Long Range Vulcan Shuttle",
    "T’Plana-Hath Vulcan Lander",
    "Regula I Space Laboratory",
    "Relay Station 47",
    "Son’a Battleship Special Edition",
    "U.S.S. Vengeance Special Edition",
    "Swarm Ship Special Edition"
];

const fortyDollarShips = [
    "Future U.S.S.Enterprise NCC-1701-D",
    "Star Trek Tardigrade Figure (Ripper)",
];

const thirtyDollarShips = [
    "I.S.S. Enterprise NX-01",
    "I.S.S. Defiant NX-74205",
    "U.S.S. Enterprise NCC-1701 (Phase II Concept)",
    "Klingon Bird-of-Prey (Landed)",
    "U.S.S. Voyager (Sternbach Concept)",
    "U.S.S. Altair",
    "U.S.S Enterprise Shuttlecraft (Jefferies Concept)",
    "U.S.S. Enterprise Shuttlecraft (Phase II Concept)",
    "S.S. Conestoga",
    "Cardassian Orbital Weapons Platform",
    "Klingon D4 Concept Ship",
    "Friendship 1",
    "U.S.S. Enterprise 'The Cage'",
    "U.S.S. Reliant Concept",
    "U.S.S. Gagarin NCC-97930",
    "Romulan Vastam-class Command Warbird",
    "ECV-197-1 Shuttle",
    "Jem’Hadar Vanguard Carrier",
    "U.S.S. Europa NCC-97640",
    "Mat'ha-class Klingon Raptor",
    "Aelahl-class Romulan Light Warbird Battlecruiser",
    "Shran-Class Federation Light Pilot Escort",
    "U.S.S. Enterprise NCC-1701-F (Refit)",
    "A.F.S. Khitomer Battlecruiser",
    "U.S.S. Mayflower NCC-1621",
    "Edison-Class Federation Temporal Warship",
    "Recluse-class Tholian Carrier",
    "U.S.S. Valiant (Defiant Concept)",
    "U.S.S. Newton NCC-1727"
];

const twentyFiveDollarShips = [
    "Starfleet Runabout",
    "K't'inga-Class Battle Cruiser",
    "Romulan Bird-Of-Prey (2152)",
    "Oberth Class",
    "Klingon Raptor",
    "Malon Export Vessel",
    "Klingon IKS Negh'var",
    "S.S. Botany Bay",
    "U.S.S. Equinox NCC-72381",
    "Nebula Class",
    "Starfleet Delta Flyer",
    "United Earth Starfleet Intrepid",
    "U.S.S. Enterprise NCC-1701",
    "Steamrunner Class",
    "Vulcan D'Kyr Type",
    "Romulan Bird-of-Prey (2260s)",
    "Phoenix",
    "U.S.S. Enterprise NCC-1701-D",
    "U.S.S. Excelsior NCC-2000",
    "Borg Sphere",
    "U.S.S. Thunderchild NCC-63549",
    "Jem'Hadar Battlecruiser",
    "Cardassian Galor Class",
    "Tholian Starship (2152)",
    "Maquis Raider",
    "Jem'Hadar Fighter",
    "Nausicaan Fighter",
    "Cardassian Hideki Class",
    "Vulcan Surak Class",
    "Romulan Drone",
    "U.S.S. Enterprise NCC-1701-C",
    "U.S.S. Enterprise-D Dedication Plaque",
    "U.S.S. Enterprise NCC-1701 (TOS) Dedication Plaque",
    "Voth City Ship",
    "Goroth's Klingon Transport Ship",
    "Borg Renegades' Ship",
    "Bajoran Raider",
    "Enterprise-E Captain's Yacht",
    "Baxial",
    "Romulan Shuttle",
    "Harry Mudd's Class-J Starship",
    "Xindi-Reptillian Warship",
    "Bajoran Assault Vessel",
    "Federation Holoship",
    "Gorn Starship",
    "Federation Timeship Aeon",
    "Vulcan Vahklas",
    "Medusan Ship",
    "Suliban Cell Ship",
    "Vidiian Warship",
    "Lokirrim Warship",
    "Tellarite Cruiser",
    "Hirogen Holoship",
    "S.S. Xhosa",
    "U.S.S. Yeager",
    "Romulan Science Vessel",
    "S.S. Emmette",
    "Alice",
    "Eymorg Starship",
    "Tholian Starship (2268)",
    "Borg Probe",
    "United Earth Arctic One",
    "Irina's Racing Ship",
    "Vulcan Survey Ship",
    "Xindi-Primate Starship",
    "U.S.S. Lantree",
    "Klingon Rebel Transport",
    "Suliban Cargo Ship",
    "Kes' Shuttle",
    "Terellian Ship",
    "Sheliak Colony Ship",
    "U.S.S. Voyager NCC-74656 Collector's Edition Starship",
    "U.S.S. Enterprise NCC-1701-E Collector's Edition Starship",
    "U.S.S. Enterprise NCC-1701-C Collector's Edition Starship",
    "Starfleet Runabout Collector's Edition Starship",
    "U.S.S. Enterprise NCC-1701-D Collector's Edition Starship",
    "U.S.S. Reliant NCC-1864 Collector's Edition Starship",
    "U.S.S. Thunderchild NCC-63549 Collector's Edition Starship",
    "U.S.S. Stargazer NCC-2893 Collector's Edition Starship",
    "Nebula Class Collector's Edition Starship",
    "Starfleet Delta Flyer Collector's Edition Starship",
    "Klingon K'T'Inga-Class Battlecruiser Collector's Edition Starship",
    "U.S.S. Enterprise NCC-1701-A Collector's Edition Starship",
    "U.S.S. Enterprise NCC-1701 Collector's Edition Starship",
    "U.S.S. Armstrong NCC-1769",
    "U.S.S. Prometheus NX-59650",
    "Federation Attack Fighter",
    "U.S.S. Appalachia NCC-52136",
    "S.S. Emmette",
    "U.S.S. Bozeman NCC-1941",
    "U.S.S. Equinox NCC-72381"
];

export const shipOptions = [{
    name: 'Ship',
    options : [
        ...twentyFiveDollarShips.sort(localeComapreSort),
        ...thirtyDollarShips.sort(localeComapreSort),
        ...fortyDollarShips.sort(localeComapreSort),
        ...fiftyDollarShips.sort(localeComapreSort),
        ...fiftyFiveDollarShips.sort(localeComapreSort),
        ...sixtyFiveDollarShips.sort(localeComapreSort),
        ...seventyFiveDollarShips.sort(localeComapreSort),
        ...eightyDollarShips.sort(localeComapreSort),
        ...hundredDollarShips.sort(localeComapreSort)
    ],
    upcharge : [
        ...makeUpchargeArray(twentyFiveDollarShips.length, 0),
        ...makeUpchargeArray(thirtyDollarShips.length, 5),
        ...makeUpchargeArray(fortyDollarShips.length, 15),
        ...makeUpchargeArray(fiftyDollarShips.length, 25),
        ...makeUpchargeArray(fiftyFiveDollarShips.length, 30),
        ...makeUpchargeArray(sixtyFiveDollarShips.length, 40),
        ...makeUpchargeArray(seventyFiveDollarShips.length, 50),
        ...makeUpchargeArray(eightyDollarShips.length, 55),
        ...makeUpchargeArray(hundredDollarShips.length, 75)
    ]
}];