export default Object.values({
    "140796": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575176508"
                },
                "2": {
                    "get_star_ts": "1575176943"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575982174"
                },
                "2": {
                    "get_star_ts": "1576039530"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576042731"
                },
                "2": {
                    "get_star_ts": "1576043470"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576130511"
                },
                "2": {
                    "get_star_ts": "1576132081"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576237857"
                },
                "2": {
                    "get_star_ts": "1576239912"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576432948"
                },
                "2": {
                    "get_star_ts": "1576442037"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576501883"
                },
                "2": {
                    "get_star_ts": "1576645164"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576726785"
                },
                "2": {
                    "get_star_ts": "1576728994"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1576649000"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576814517"
                },
                "2": {
                    "get_star_ts": "1577197220"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575263989"
                },
                "2": {
                    "get_star_ts": "1575264524"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576960347"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575379150"
                },
                "2": {
                    "get_star_ts": "1575406296"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575435973"
                },
                "2": {
                    "get_star_ts": "1575436556"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575562285"
                },
                "2": {
                    "get_star_ts": "1575602429"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575608921"
                },
                "2": {
                    "get_star_ts": "1575610345"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575742760"
                },
                "2": {
                    "get_star_ts": "1575762122"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575781943"
                },
                "2": {
                    "get_star_ts": "1575782746"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575945614"
                },
                "2": {
                    "get_star_ts": "1575945669"
                }
            }
        },
        "global_score": 0,
        "id": "140796",
        "last_star_ts": "1577197220",
        "local_score": 3650,
        "name": "donniemattingly",
        "stars": 36
    },
    "145716": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575303974"
                },
                "2": {
                    "get_star_ts": "1575304490"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575312320"
                },
                "2": {
                    "get_star_ts": "1575313868"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575406192"
                },
                "2": {
                    "get_star_ts": "1575409094"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575436877"
                },
                "2": {
                    "get_star_ts": "1575437281"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575524585"
                },
                "2": {
                    "get_star_ts": "1575526027"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575610160"
                },
                "2": {
                    "get_star_ts": "1575611155"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575697124"
                }
            }
        },
        "global_score": 0,
        "id": "145716",
        "last_star_ts": "1575697124",
        "local_score": 1209,
        "name": "Mike J",
        "stars": 13
    },
    "162458": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575212226"
                },
                "2": {
                    "get_star_ts": "1575213066"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575296711"
                },
                "2": {
                    "get_star_ts": "1575298083"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575395703"
                },
                "2": {
                    "get_star_ts": "1575396503"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575478197"
                },
                "2": {
                    "get_star_ts": "1575478747"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575569001"
                },
                "2": {
                    "get_star_ts": "1575569521"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575694933"
                }
            }
        },
        "global_score": 0,
        "id": "162458",
        "last_star_ts": "1575694933",
        "local_score": 983,
        "name": "Uy Nguyen",
        "stars": 11
    },
    "185850": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575210332"
                },
                "2": {
                    "get_star_ts": "1575210604"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576516470"
                },
                "2": {
                    "get_star_ts": "1576522527"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576692541"
                },
                "2": {
                    "get_star_ts": "1576693399"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576694796"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575385294"
                },
                "2": {
                    "get_star_ts": "1575385662"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575470643"
                },
                "2": {
                    "get_star_ts": "1575473968"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575558645"
                },
                "2": {
                    "get_star_ts": "1575560594"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575573913"
                },
                "2": {
                    "get_star_ts": "1575576359"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576509773"
                },
                "2": {
                    "get_star_ts": "1576511927"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576609024"
                },
                "2": {
                    "get_star_ts": "1576677703"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575841530"
                },
                "2": {
                    "get_star_ts": "1575845559"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576684279"
                },
                "2": {
                    "get_star_ts": "1576684536"
                }
            }
        },
        "global_score": 0,
        "id": "185850",
        "last_star_ts": "1576694796",
        "local_score": 1793,
        "name": "Jordan Rust",
        "stars": 23
    },
    "186556": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575200573"
                },
                "2": {
                    "get_star_ts": "1575201114"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575991383"
                },
                "2": {
                    "get_star_ts": "1575996092"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576070353"
                },
                "2": {
                    "get_star_ts": "1576071746"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576164602"
                },
                "2": {
                    "get_star_ts": "1576176110"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576241261"
                },
                "2": {
                    "get_star_ts": "1576242702"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576341154"
                },
                "2": {
                    "get_star_ts": "1576351791"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576427188"
                },
                "2": {
                    "get_star_ts": "1576428629"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576507704"
                },
                "2": {
                    "get_star_ts": "1576615681"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576675650"
                },
                "2": {
                    "get_star_ts": "1576705604"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1576710930"
                },
                "2": {
                    "get_star_ts": "1576765402"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576769615"
                },
                "2": {
                    "get_star_ts": "1576771873"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575284492"
                },
                "2": {
                    "get_star_ts": "1575285108"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576849699"
                },
                "2": {
                    "get_star_ts": "1576854521"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1576951202"
                },
                "2": {
                    "get_star_ts": "1576951821"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577032312"
                }
            },
            "23": {
                "1": {
                    "get_star_ts": "1577125205"
                },
                "2": {
                    "get_star_ts": "1577126451"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575371582"
                },
                "2": {
                    "get_star_ts": "1575371955"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575464909"
                },
                "2": {
                    "get_star_ts": "1575465668"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575557943"
                },
                "2": {
                    "get_star_ts": "1575558522"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575632280"
                },
                "2": {
                    "get_star_ts": "1575633996"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575734803"
                },
                "2": {
                    "get_star_ts": "1575747102"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575828009"
                },
                "2": {
                    "get_star_ts": "1575829366"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575899476"
                },
                "2": {
                    "get_star_ts": "1575899574"
                }
            }
        },
        "global_score": 0,
        "id": "186556",
        "last_star_ts": "1577126451",
        "local_score": 4572,
        "name": "Chad Orwig",
        "stars": 45
    },
    "188240": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576236516"
                },
                "2": {
                    "get_star_ts": "1576237071"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576771779"
                },
                "2": {
                    "get_star_ts": "1576780646"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1577732915"
                },
                "2": {
                    "get_star_ts": "1578056919"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1578083706"
                },
                "2": {
                    "get_star_ts": "1578089795"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1578149720"
                },
                "2": {
                    "get_star_ts": "1578150737"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1578236023"
                }
            }
        },
        "global_score": 0,
        "id": "188240",
        "last_star_ts": "1578236023",
        "local_score": 364,
        "name": "trevcor",
        "stars": 11
    },
    "188244": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575301981"
                },
                "2": {
                    "get_star_ts": "1575304223"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576202021"
                },
                "2": {
                    "get_star_ts": "1576207498"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576209881"
                },
                "2": {
                    "get_star_ts": "1576210789"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576268535"
                },
                "2": {
                    "get_star_ts": "1576449920"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576513774"
                },
                "2": {
                    "get_star_ts": "1576627889"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1577387379"
                },
                "2": {
                    "get_star_ts": "1577393957"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1577899963"
                },
                "2": {
                    "get_star_ts": "1577978257"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1577987736"
                },
                "2": {
                    "get_star_ts": "1577997402"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1578002629"
                },
                "2": {
                    "get_star_ts": "1578020265"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1578887622"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575313896"
                },
                "2": {
                    "get_star_ts": "1575315618"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575407897"
                },
                "2": {
                    "get_star_ts": "1575422147"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575558781"
                },
                "2": {
                    "get_star_ts": "1575564001"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575664747"
                },
                "2": {
                    "get_star_ts": "1575666235"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575667857"
                },
                "2": {
                    "get_star_ts": "1575736072"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575848292"
                },
                "2": {
                    "get_star_ts": "1575851245"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575853391"
                },
                "2": {
                    "get_star_ts": "1575855192"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575922896"
                },
                "2": {
                    "get_star_ts": "1575923036"
                }
            }
        },
        "global_score": 0,
        "id": "188244",
        "last_star_ts": "1578887622",
        "local_score": 3020,
        "name": "cnash6",
        "stars": 35
    },
    "188247": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575313053"
                },
                "2": {
                    "get_star_ts": "1575314060"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575317542"
                },
                "2": {
                    "get_star_ts": "1575321982"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575394002"
                },
                "2": {
                    "get_star_ts": "1575396003"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575439138"
                },
                "2": {
                    "get_star_ts": "1575439474"
                }
            }
        },
        "global_score": 0,
        "id": "188247",
        "last_star_ts": "1575439474",
        "local_score": 676,
        "name": "Scott Jackson",
        "stars": 8
    },
    "188249": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575176586"
                },
                "2": {
                    "get_star_ts": "1575177684"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576087560"
                },
                "2": {
                    "get_star_ts": "1578259337"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576998448"
                },
                "2": {
                    "get_star_ts": "1576999712"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576212800"
                },
                "2": {
                    "get_star_ts": "1576218508"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1577121172"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1577170383"
                },
                "2": {
                    "get_star_ts": "1577171312"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576814728"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576820768"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576769531"
                },
                "2": {
                    "get_star_ts": "1577079162"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575263889"
                },
                "2": {
                    "get_star_ts": "1575264728"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1578263668"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577640586"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577174810"
                },
                "2": {
                    "get_star_ts": "1577594747"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575465349"
                },
                "2": {
                    "get_star_ts": "1575465537"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575466026"
                },
                "2": {
                    "get_star_ts": "1575466209"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575557081"
                },
                "2": {
                    "get_star_ts": "1575607706"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575609995"
                },
                "2": {
                    "get_star_ts": "1575610885"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575728988"
                },
                "2": {
                    "get_star_ts": "1576597357"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575900881"
                },
                "2": {
                    "get_star_ts": "1575905311"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576641039"
                },
                "2": {
                    "get_star_ts": "1576641063"
                }
            }
        },
        "global_score": 0,
        "id": "188249",
        "last_star_ts": "1578263668",
        "local_score": 3219,
        "name": "Austin Assavavallop",
        "stars": 35
    },
    "188251": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575395432"
                },
                "2": {
                    "get_star_ts": "1575397217"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576246971"
                },
                "2": {
                    "get_star_ts": "1576292337"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576436288"
                },
                "2": {
                    "get_star_ts": "1576463768"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576610331"
                },
                "2": {
                    "get_star_ts": "1576713301"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576714434"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575400206"
                },
                "2": {
                    "get_star_ts": "1575402497"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575408646"
                },
                "2": {
                    "get_star_ts": "1575410110"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575668310"
                },
                "2": {
                    "get_star_ts": "1575848456"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575851608"
                },
                "2": {
                    "get_star_ts": "1575859231"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575951183"
                },
                "2": {
                    "get_star_ts": "1575952511"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576013102"
                },
                "2": {
                    "get_star_ts": "1576041788"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576088058"
                },
                "2": {
                    "get_star_ts": "1576089073"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576116715"
                },
                "2": {
                    "get_star_ts": "1576116788"
                }
            }
        },
        "global_score": 0,
        "id": "188251",
        "last_star_ts": "1576714434",
        "local_score": 1879,
        "name": "Basil Udoudoh",
        "stars": 25
    },
    "188256": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575207811"
                },
                "2": {
                    "get_star_ts": "1575208114"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575988470"
                },
                "2": {
                    "get_star_ts": "1576011400"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576066359"
                },
                "2": {
                    "get_star_ts": "1576067415"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576154767"
                },
                "2": {
                    "get_star_ts": "1576445533"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576450095"
                },
                "2": {
                    "get_star_ts": "1576497115"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576585007"
                },
                "2": {
                    "get_star_ts": "1576585888"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576668461"
                },
                "2": {
                    "get_star_ts": "1576669183"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576526522"
                },
                "2": {
                    "get_star_ts": "1576598955"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576671170"
                },
                "2": {
                    "get_star_ts": "1576873561"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1576847946"
                },
                "2": {
                    "get_star_ts": "1576957092"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576877183"
                },
                "2": {
                    "get_star_ts": "1576882425"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575289144"
                },
                "2": {
                    "get_star_ts": "1575289623"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576962460"
                },
                "2": {
                    "get_star_ts": "1577030934"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1577034009"
                },
                "2": {
                    "get_star_ts": "1577042018"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577120825"
                },
                "2": {
                    "get_star_ts": "1577237484"
                }
            },
            "23": {
                "1": {
                    "get_star_ts": "1577129948"
                },
                "2": {
                    "get_star_ts": "1577132119"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577219303"
                },
                "2": {
                    "get_star_ts": "1577224874"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1577253361"
                },
                "2": {
                    "get_star_ts": "1577253366"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575375036"
                },
                "2": {
                    "get_star_ts": "1575375547"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575548039"
                },
                "2": {
                    "get_star_ts": "1575549254"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575674321"
                },
                "2": {
                    "get_star_ts": "1575676350"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575750356"
                },
                "2": {
                    "get_star_ts": "1575751291"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575752609"
                },
                "2": {
                    "get_star_ts": "1575754306"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575826077"
                },
                "2": {
                    "get_star_ts": "1575826987"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575931012"
                },
                "2": {
                    "get_star_ts": "1575931075"
                }
            }
        },
        "global_score": 0,
        "id": "188256",
        "last_star_ts": "1577253366",
        "local_score": 4843,
        "name": "Chris Claggett",
        "stars": 50
    },
    "188257": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575299247"
                },
                "2": {
                    "get_star_ts": "1575299704"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576341899"
                },
                "2": {
                    "get_star_ts": "1576499997"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576517782"
                },
                "2": {
                    "get_star_ts": "1576518450"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576525910"
                },
                "2": {
                    "get_star_ts": "1576532268"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576545877"
                },
                "2": {
                    "get_star_ts": "1576612451"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576782729"
                },
                "2": {
                    "get_star_ts": "1576784611"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575302284"
                },
                "2": {
                    "get_star_ts": "1575303179"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575400690"
                },
                "2": {
                    "get_star_ts": "1575400959"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575467374"
                },
                "2": {
                    "get_star_ts": "1575469527"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575556856"
                },
                "2": {
                    "get_star_ts": "1575558105"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575638460"
                },
                "2": {
                    "get_star_ts": "1575641850"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575727107"
                },
                "2": {
                    "get_star_ts": "1576265289"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575900467"
                },
                "2": {
                    "get_star_ts": "1575902280"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576336829"
                },
                "2": {
                    "get_star_ts": "1576336847"
                }
            }
        },
        "global_score": 0,
        "id": "188257",
        "last_star_ts": "1576784611",
        "local_score": 2463,
        "name": "G. Hamilton Evans",
        "stars": 28
    },
    "188262": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575324416"
                },
                "2": {
                    "get_star_ts": "1575324895"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576154711"
                },
                "2": {
                    "get_star_ts": "1576242317"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576775290"
                },
                "2": {
                    "get_star_ts": "1576781559"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576789333"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576869923"
                },
                "2": {
                    "get_star_ts": "1577067491"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1577212855"
                },
                "2": {
                    "get_star_ts": "1577214766"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1577250644"
                },
                "2": {
                    "get_star_ts": "1577254059"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1577257294"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1577319521"
                },
                "2": {
                    "get_star_ts": "1577830952"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575328589"
                },
                "2": {
                    "get_star_ts": "1575329447"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575437651"
                },
                "2": {
                    "get_star_ts": "1575505165"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575507168"
                },
                "2": {
                    "get_star_ts": "1575511091"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575708001"
                },
                "2": {
                    "get_star_ts": "1575748306"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575753501"
                },
                "2": {
                    "get_star_ts": "1575756598"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575765902"
                },
                "2": {
                    "get_star_ts": "1575836101"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575839622"
                },
                "2": {
                    "get_star_ts": "1575841721"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575902773"
                },
                "2": {
                    "get_star_ts": "1575902814"
                }
            }
        },
        "global_score": 0,
        "id": "188262",
        "last_star_ts": "1577830952",
        "local_score": 2666,
        "name": "Zach Justice",
        "stars": 32
    },
    "188276": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575319049"
                },
                "2": {
                    "get_star_ts": "1575319853"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1577910720"
                },
                "2": {
                    "get_star_ts": "1577910744"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1577910853"
                },
                "2": {
                    "get_star_ts": "1577910861"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1577910964"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575340595"
                },
                "2": {
                    "get_star_ts": "1575342840"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575555552"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1577727175"
                },
                "2": {
                    "get_star_ts": "1577727639"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1577728668"
                },
                "2": {
                    "get_star_ts": "1577728707"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577729096"
                },
                "2": {
                    "get_star_ts": "1577729190"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1577729462"
                },
                "2": {
                    "get_star_ts": "1577729546"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1577729706"
                },
                "2": {
                    "get_star_ts": "1577729949"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1577910428"
                },
                "2": {
                    "get_star_ts": "1577910441"
                }
            }
        },
        "global_score": 0,
        "id": "188276",
        "last_star_ts": "1577910964",
        "local_score": 1391,
        "name": "Erica T",
        "stars": 22
    },
    "188345": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575292050"
                },
                "2": {
                    "get_star_ts": "1575293453"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575985165"
                },
                "2": {
                    "get_star_ts": "1575991490"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1577485641"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575312963"
                },
                "2": {
                    "get_star_ts": "1575321514"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575366783"
                },
                "2": {
                    "get_star_ts": "1575367177"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575467113"
                },
                "2": {
                    "get_star_ts": "1575504770"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575645752"
                },
                "2": {
                    "get_star_ts": "1575655461"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575672744"
                },
                "2": {
                    "get_star_ts": "1575673084"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575727366"
                },
                "2": {
                    "get_star_ts": "1575769435"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575803912"
                },
                "2": {
                    "get_star_ts": "1575805896"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575943404"
                },
                "2": {
                    "get_star_ts": "1575943441"
                }
            }
        },
        "global_score": 0,
        "id": "188345",
        "last_star_ts": "1577485641",
        "local_score": 1900,
        "name": "Patrick Hu",
        "stars": 21
    },
    "189285": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "189285",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "Steven Porter",
        "stars": 0
    },
    "190090": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575233997"
                },
                "2": {
                    "get_star_ts": "1575235085"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576028493"
                },
                "2": {
                    "get_star_ts": "1576042003"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576342294"
                },
                "2": {
                    "get_star_ts": "1576349928"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1577294598"
                },
                "2": {
                    "get_star_ts": "1577316844"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576351293"
                },
                "2": {
                    "get_star_ts": "1576417399"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575422251"
                },
                "2": {
                    "get_star_ts": "1575426802"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575606887"
                },
                "2": {
                    "get_star_ts": "1575607853"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575691280"
                },
                "2": {
                    "get_star_ts": "1575695011"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575736310"
                },
                "2": {
                    "get_star_ts": "1575738481"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575815215"
                },
                "2": {
                    "get_star_ts": "1575824628"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575842765"
                },
                "2": {
                    "get_star_ts": "1575847260"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575853585"
                },
                "2": {
                    "get_star_ts": "1575855152"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575953503"
                },
                "2": {
                    "get_star_ts": "1575953617"
                }
            }
        },
        "global_score": 0,
        "id": "190090",
        "last_star_ts": "1577316844",
        "local_score": 2071,
        "name": "Scott Abelmann",
        "stars": 26
    },
    "191395": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575302019"
                },
                "2": {
                    "get_star_ts": "1575303561"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575304774"
                },
                "2": {
                    "get_star_ts": "1575305581"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575386861"
                },
                "2": {
                    "get_star_ts": "1575390321"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575568262"
                },
                "2": {
                    "get_star_ts": "1575568661"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575989946"
                },
                "2": {
                    "get_star_ts": "1575991466"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575996289"
                },
                "2": {
                    "get_star_ts": "1575997770"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576640856"
                },
                "2": {
                    "get_star_ts": "1576773598"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1577718308"
                },
                "2": {
                    "get_star_ts": "1577729609"
                }
            }
        },
        "global_score": 0,
        "id": "191395",
        "last_star_ts": "1577729609",
        "local_score": 1172,
        "name": "Ted Hunter",
        "stars": 16
    },
    "192951": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575211561"
                },
                "2": {
                    "get_star_ts": "1575212258"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1577312981"
                },
                "2": {
                    "get_star_ts": "1577378965"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1577387327"
                },
                "2": {
                    "get_star_ts": "1577388745"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1577410508"
                },
                "2": {
                    "get_star_ts": "1577423388"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1577425060"
                },
                "2": {
                    "get_star_ts": "1577426714"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575331745"
                },
                "2": {
                    "get_star_ts": "1575332206"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575952453"
                },
                "2": {
                    "get_star_ts": "1575954461"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575957273"
                },
                "2": {
                    "get_star_ts": "1575958863"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576259453"
                },
                "2": {
                    "get_star_ts": "1576261062"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1577068850"
                },
                "2": {
                    "get_star_ts": "1577234909"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1577237816"
                },
                "2": {
                    "get_star_ts": "1577239642"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1577296389"
                },
                "2": {
                    "get_star_ts": "1577296439"
                }
            }
        },
        "global_score": 0,
        "id": "192951",
        "last_star_ts": "1577426714",
        "local_score": 1697,
        "name": "eddpurcell",
        "stars": 24
    },
    "195363": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575251354"
                },
                "2": {
                    "get_star_ts": "1575251531"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575331983"
                },
                "2": {
                    "get_star_ts": "1575335057"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575392283"
                },
                "2": {
                    "get_star_ts": "1575404290"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575520984"
                },
                "2": {
                    "get_star_ts": "1575521480"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575598310"
                },
                "2": {
                    "get_star_ts": "1575602419"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575850750"
                },
                "2": {
                    "get_star_ts": "1575852437"
                }
            }
        },
        "global_score": 0,
        "id": "195363",
        "last_star_ts": "1575852437",
        "local_score": 946,
        "name": "Darien Pourmehr",
        "stars": 12
    },
    "218042": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575572757"
                },
                "2": {
                    "get_star_ts": "1575573498"
                }
            }
        },
        "global_score": 0,
        "id": "218042",
        "last_star_ts": "1575573498",
        "local_score": 74,
        "name": "EduardoCFSlalom",
        "stars": 2
    },
    "330030": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575988597"
                },
                "2": {
                    "get_star_ts": "1575988827"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575990264"
                },
                "2": {
                    "get_star_ts": "1575990595"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576005640"
                },
                "2": {
                    "get_star_ts": "1576029598"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576030352"
                },
                "2": {
                    "get_star_ts": "1576244675"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576256208"
                },
                "2": {
                    "get_star_ts": "1576258275"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576261201"
                },
                "2": {
                    "get_star_ts": "1576263195"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576265591"
                },
                "2": {
                    "get_star_ts": "1576601224"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576602945"
                },
                "2": {
                    "get_star_ts": "1576788717"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576877223"
                },
                "2": {
                    "get_star_ts": "1576877298"
                }
            }
        },
        "global_score": 0,
        "id": "330030",
        "last_star_ts": "1576877298",
        "local_score": 991,
        "name": "Kevin Wright",
        "stars": 18
    },
    "372160": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575206072"
                },
                "2": {
                    "get_star_ts": "1575206634"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576781855"
                },
                "2": {
                    "get_star_ts": "1577986986"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575333558"
                },
                "2": {
                    "get_star_ts": "1575338592"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575425618"
                },
                "2": {
                    "get_star_ts": "1575554932"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575473723"
                },
                "2": {
                    "get_star_ts": "1575477077"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575664721"
                },
                "2": {
                    "get_star_ts": "1575683900"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575735202"
                },
                "2": {
                    "get_star_ts": "1575739520"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1578332736"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575917237"
                },
                "2": {
                    "get_star_ts": "1576007769"
                }
            }
        },
        "global_score": 0,
        "id": "372160",
        "last_star_ts": "1578332736",
        "local_score": 1346,
        "name": "Alex Consunji",
        "stars": 17
    },
    "372356": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575653810"
                },
                "2": {
                    "get_star_ts": "1576097488"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576129854"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576104210"
                },
                "2": {
                    "get_star_ts": "1576107648"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576122356"
                },
                "2": {
                    "get_star_ts": "1576179284"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576525280"
                },
                "2": {
                    "get_star_ts": "1576534928"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1577258809"
                }
            }
        },
        "global_score": 0,
        "id": "372356",
        "last_star_ts": "1577258809",
        "local_score": 442,
        "name": "Shah Zafrani",
        "stars": 10
    },
    "372360": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575233897"
                },
                "2": {
                    "get_star_ts": "1575235594"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575991600"
                },
                "2": {
                    "get_star_ts": "1575995237"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576183576"
                },
                "2": {
                    "get_star_ts": "1576183750"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576186395"
                },
                "2": {
                    "get_star_ts": "1576282259"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576295399"
                },
                "2": {
                    "get_star_ts": "1576296905"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576357950"
                },
                "2": {
                    "get_star_ts": "1576379123"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576462976"
                },
                "2": {
                    "get_star_ts": "1576517394"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576520339"
                },
                "2": {
                    "get_star_ts": "1576550657"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576592077"
                },
                "2": {
                    "get_star_ts": "1576608134"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576775610"
                },
                "2": {
                    "get_star_ts": "1576852651"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575293794"
                },
                "2": {
                    "get_star_ts": "1575295513"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576895254"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1577045721"
                },
                "2": {
                    "get_star_ts": "1577055034"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577059682"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575383060"
                },
                "2": {
                    "get_star_ts": "1575385664"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575436408"
                },
                "2": {
                    "get_star_ts": "1575436762"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575570005"
                },
                "2": {
                    "get_star_ts": "1575583542"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575645917"
                },
                "2": {
                    "get_star_ts": "1575649181"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575728541"
                },
                "2": {
                    "get_star_ts": "1575856547"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575832725"
                },
                "2": {
                    "get_star_ts": "1575834953"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575916105"
                },
                "2": {
                    "get_star_ts": "1575916189"
                }
            }
        },
        "global_score": 0,
        "id": "372360",
        "last_star_ts": "1577059682",
        "local_score": 3893,
        "name": "Corey Lamb",
        "stars": 40
    },
    "372372": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575905049"
                },
                "2": {
                    "get_star_ts": "1575905678"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575913104"
                },
                "2": {
                    "get_star_ts": "1575915046"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575920790"
                },
                "2": {
                    "get_star_ts": "1575921117"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575922202"
                },
                "2": {
                    "get_star_ts": "1575925175"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575929138"
                }
            }
        },
        "global_score": 0,
        "id": "372372",
        "last_star_ts": "1575929138",
        "local_score": 389,
        "name": "Griffin Jones",
        "stars": 9
    },
    "372403": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575406174"
                },
                "2": {
                    "get_star_ts": "1575406720"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576547672"
                },
                "2": {
                    "get_star_ts": "1577048613"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575408163"
                },
                "2": {
                    "get_star_ts": "1575408640"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575412483"
                },
                "2": {
                    "get_star_ts": "1575608340"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575945130"
                },
                "2": {
                    "get_star_ts": "1575945718"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575950498"
                },
                "2": {
                    "get_star_ts": "1575953349"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575610912"
                },
                "2": {
                    "get_star_ts": "1575612226"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576092950"
                },
                "2": {
                    "get_star_ts": "1576356769"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576358406"
                },
                "2": {
                    "get_star_ts": "1576359003"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576540237"
                },
                "2": {
                    "get_star_ts": "1576540713"
                }
            }
        },
        "global_score": 0,
        "id": "372403",
        "last_star_ts": "1577048613",
        "local_score": 1416,
        "name": "Andrew Marmon",
        "stars": 20
    },
    "372419": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575234189"
                },
                "2": {
                    "get_star_ts": "1575236371"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575293475"
                },
                "2": {
                    "get_star_ts": "1575298408"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575752440"
                },
                "2": {
                    "get_star_ts": "1575854895"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575490248"
                },
                "2": {
                    "get_star_ts": "1575546735"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576023085"
                },
                "2": {
                    "get_star_ts": "1576091086"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576116767"
                },
                "2": {
                    "get_star_ts": "1576187614"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576292110"
                },
                "2": {
                    "get_star_ts": "1577042186"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576966082"
                },
                "2": {
                    "get_star_ts": "1576986186"
                }
            }
        },
        "global_score": 0,
        "id": "372419",
        "last_star_ts": "1577042186",
        "local_score": 1140,
        "name": "Kevin Campbell",
        "stars": 16
    },
    "372694": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575180535"
                },
                "2": {
                    "get_star_ts": "1575215760"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576022649"
                },
                "2": {
                    "get_star_ts": "1576029149"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576100944"
                },
                "2": {
                    "get_star_ts": "1576102115"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576211640"
                },
                "2": {
                    "get_star_ts": "1576300224"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576275439"
                },
                "2": {
                    "get_star_ts": "1576281563"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576367578"
                },
                "2": {
                    "get_star_ts": "1576369608"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576443879"
                },
                "2": {
                    "get_star_ts": "1576445960"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576544485"
                },
                "2": {
                    "get_star_ts": "1576981148"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576612797"
                },
                "2": {
                    "get_star_ts": "1577076850"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1577671171"
                },
                "2": {
                    "get_star_ts": "1577683736"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1577727151"
                },
                "2": {
                    "get_star_ts": "1577748767"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575315238"
                },
                "2": {
                    "get_star_ts": "1575315973"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1577831195"
                },
                "2": {
                    "get_star_ts": "1577900447"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1578006349"
                },
                "2": {
                    "get_star_ts": "1578011149"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1578014010"
                },
                "2": {
                    "get_star_ts": "1578170199"
                }
            },
            "23": {
                "1": {
                    "get_star_ts": "1577909117"
                },
                "2": {
                    "get_star_ts": "1577915065"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577925143"
                },
                "2": {
                    "get_star_ts": "1578000158"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1578019919"
                },
                "2": {
                    "get_star_ts": "1578170220"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575415019"
                },
                "2": {
                    "get_star_ts": "1575424401"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575503927"
                },
                "2": {
                    "get_star_ts": "1575507071"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575589400"
                },
                "2": {
                    "get_star_ts": "1575591124"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575672571"
                },
                "2": {
                    "get_star_ts": "1575675451"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575754513"
                },
                "2": {
                    "get_star_ts": "1575764167"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575785751"
                },
                "2": {
                    "get_star_ts": "1575787247"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575939882"
                },
                "2": {
                    "get_star_ts": "1575940352"
                }
            }
        },
        "global_score": 0,
        "id": "372694",
        "last_star_ts": "1578170220",
        "local_score": 4770,
        "name": "John Tompkins",
        "stars": 50
    },
    "372913": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575658698"
                },
                "2": {
                    "get_star_ts": "1575659775"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575672413"
                },
                "2": {
                    "get_star_ts": "1575755706"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575859410"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575943873"
                },
                "2": {
                    "get_star_ts": "1575945636"
                }
            }
        },
        "global_score": 0,
        "id": "372913",
        "last_star_ts": "1575945636",
        "local_score": 293,
        "name": "Edvin Dizdarevic",
        "stars": 7
    },
    "373557": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575821765"
                },
                "2": {
                    "get_star_ts": "1575822784"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575833087"
                },
                "2": {
                    "get_star_ts": "1575833692"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576297692"
                },
                "2": {
                    "get_star_ts": "1576378795"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1578765335"
                },
                "2": {
                    "get_star_ts": "1578766152"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1578851850"
                },
                "2": {
                    "get_star_ts": "1578861784"
                }
            }
        },
        "global_score": 0,
        "id": "373557",
        "last_star_ts": "1578861784",
        "local_score": 354,
        "name": "RJ Hill",
        "stars": 10
    },
    "375289": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575256609"
                },
                "2": {
                    "get_star_ts": "1575261378"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575314662"
                },
                "2": {
                    "get_star_ts": "1575327921"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575471319"
                },
                "2": {
                    "get_star_ts": "1575481053"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575485097"
                },
                "2": {
                    "get_star_ts": "1575486836"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575823907"
                },
                "2": {
                    "get_star_ts": "1576114603"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576266977"
                },
                "2": {
                    "get_star_ts": "1576269558"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576689756"
                },
                "2": {
                    "get_star_ts": "1576701347"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576769645"
                },
                "2": {
                    "get_star_ts": "1576872891"
                }
            }
        },
        "global_score": 0,
        "id": "375289",
        "last_star_ts": "1576872891",
        "local_score": 1161,
        "name": "jhahn1",
        "stars": 16
    },
    "375527": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575255744"
                },
                "2": {
                    "get_star_ts": "1575329040"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1577416107"
                },
                "2": {
                    "get_star_ts": "1577729796"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1578127821"
                },
                "2": {
                    "get_star_ts": "1578128699"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1578315421"
                },
                "2": {
                    "get_star_ts": "1578444655"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1578316074"
                },
                "2": {
                    "get_star_ts": "1578319429"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1578357734"
                },
                "2": {
                    "get_star_ts": "1578360204"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1578535051"
                },
                "2": {
                    "get_star_ts": "1578543075"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1578360676"
                },
                "2": {
                    "get_star_ts": "1578577570"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1578404401"
                },
                "2": {
                    "get_star_ts": "1578449403"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1578405043"
                },
                "2": {
                    "get_star_ts": "1578452397"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575330779"
                },
                "2": {
                    "get_star_ts": "1575385334"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1578460924"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1578070527"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577729530"
                },
                "2": {
                    "get_star_ts": "1577756895"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575433010"
                },
                "2": {
                    "get_star_ts": "1575434830"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575470143"
                },
                "2": {
                    "get_star_ts": "1575470544"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575524307"
                },
                "2": {
                    "get_star_ts": "1575524869"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577415609"
                },
                "2": {
                    "get_star_ts": "1577729717"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1577415720"
                },
                "2": {
                    "get_star_ts": "1578109139"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1578069790"
                },
                "2": {
                    "get_star_ts": "1578070415"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1578106435"
                },
                "2": {
                    "get_star_ts": "1578110180"
                }
            }
        },
        "global_score": 0,
        "id": "375527",
        "last_star_ts": "1578577570",
        "local_score": 3223,
        "name": "yzheng347",
        "stars": 40
    },
    "388371": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575230137"
                },
                "2": {
                    "get_star_ts": "1575235073"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575433597"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575832927"
                }
            }
        },
        "global_score": 0,
        "id": "388371",
        "last_star_ts": "1575832927",
        "local_score": 276,
        "name": "Benjamin King",
        "stars": 4
    },
    "388769": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575178851"
                },
                "2": {
                    "get_star_ts": "1575178963"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575958285"
                },
                "2": {
                    "get_star_ts": "1575958561"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576042104"
                },
                "2": {
                    "get_star_ts": "1576042203"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576129175"
                },
                "2": {
                    "get_star_ts": "1576129600"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576214376"
                },
                "2": {
                    "get_star_ts": "1576214972"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576301108"
                },
                "2": {
                    "get_star_ts": "1576301347"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576389040"
                },
                "2": {
                    "get_star_ts": "1576389242"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576476805"
                },
                "2": {
                    "get_star_ts": "1576477237"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576561398"
                },
                "2": {
                    "get_star_ts": "1576572726"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1576653201"
                },
                "2": {
                    "get_star_ts": "1576671048"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576734255"
                },
                "2": {
                    "get_star_ts": "1576734634"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575264011"
                },
                "2": {
                    "get_star_ts": "1575264583"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576821096"
                },
                "2": {
                    "get_star_ts": "1576821185"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1576968417"
                },
                "2": {
                    "get_star_ts": "1576968562"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577032530"
                },
                "2": {
                    "get_star_ts": "1577033115"
                }
            },
            "23": {
                "1": {
                    "get_star_ts": "1577099070"
                },
                "2": {
                    "get_star_ts": "1577099491"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577166272"
                },
                "2": {
                    "get_star_ts": "1577166904"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1577264314"
                },
                "2": {
                    "get_star_ts": "1577264319"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575350568"
                },
                "2": {
                    "get_star_ts": "1575350960"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575436323"
                },
                "2": {
                    "get_star_ts": "1575436381"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575523482"
                },
                "2": {
                    "get_star_ts": "1575523561"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575609284"
                },
                "2": {
                    "get_star_ts": "1575609424"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575697997"
                },
                "2": {
                    "get_star_ts": "1575698221"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575782096"
                },
                "2": {
                    "get_star_ts": "1575782287"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575868426"
                },
                "2": {
                    "get_star_ts": "1575868442"
                }
            }
        },
        "global_score": 95,
        "id": "388769",
        "last_star_ts": "1577264319",
        "local_score": 5348,
        "name": "Michelle Yi",
        "stars": 50
    },
    "389033": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1578546459"
                },
                "2": {
                    "get_star_ts": "1578549634"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1578552673"
                },
                "2": {
                    "get_star_ts": "1578554800"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1578884958"
                },
                "2": {
                    "get_star_ts": "1578886688"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1578584039"
                },
                "2": {
                    "get_star_ts": "1578608305"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1578858035"
                },
                "2": {
                    "get_star_ts": "1578862152"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1578780725"
                },
                "2": {
                    "get_star_ts": "1578780804"
                }
            }
        },
        "global_score": 0,
        "id": "389033",
        "last_star_ts": "1578886688",
        "local_score": 356,
        "name": "Joshua Angeley",
        "stars": 12
    },
    "435773": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575591805"
                },
                "2": {
                    "get_star_ts": "1575592893"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576110643"
                },
                "2": {
                    "get_star_ts": "1576124577"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576370367"
                },
                "2": {
                    "get_star_ts": "1576372222"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576389745"
                },
                "2": {
                    "get_star_ts": "1576986324"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576994875"
                },
                "2": {
                    "get_star_ts": "1577058379"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575600099"
                },
                "2": {
                    "get_star_ts": "1575600695"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575640779"
                },
                "2": {
                    "get_star_ts": "1575641704"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575643012"
                },
                "2": {
                    "get_star_ts": "1575644516"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575650845"
                },
                "2": {
                    "get_star_ts": "1575655409"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575733260"
                },
                "2": {
                    "get_star_ts": "1575753666"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575852145"
                },
                "2": {
                    "get_star_ts": "1575901845"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575823143"
                },
                "2": {
                    "get_star_ts": "1575827739"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575913598"
                },
                "2": {
                    "get_star_ts": "1575913666"
                }
            }
        },
        "global_score": 0,
        "id": "435773",
        "last_star_ts": "1577058379",
        "local_score": 1990,
        "name": "Daniel Keeling",
        "stars": 26
    },
    "453980": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575748099"
                },
                "2": {
                    "get_star_ts": "1575750822"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576040105"
                },
                "2": {
                    "get_star_ts": "1576078157"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576043927"
                },
                "2": {
                    "get_star_ts": "1576044435"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576162174"
                },
                "2": {
                    "get_star_ts": "1576202465"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576250716"
                },
                "2": {
                    "get_star_ts": "1576255055"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576522814"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576554725"
                },
                "2": {
                    "get_star_ts": "1576556107"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576600847"
                },
                "2": {
                    "get_star_ts": "1576817893"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576635172"
                },
                "2": {
                    "get_star_ts": "1576640429"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575751804"
                },
                "2": {
                    "get_star_ts": "1575752677"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576963093"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575825283"
                },
                "2": {
                    "get_star_ts": "1575825963"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575826904"
                },
                "2": {
                    "get_star_ts": "1575827558"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575852663"
                },
                "2": {
                    "get_star_ts": "1575854274"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575857828"
                },
                "2": {
                    "get_star_ts": "1575858900"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575860763"
                },
                "2": {
                    "get_star_ts": "1575870293"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575913601"
                },
                "2": {
                    "get_star_ts": "1575914810"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575947954"
                },
                "2": {
                    "get_star_ts": "1575948000"
                }
            }
        },
        "global_score": 0,
        "id": "453980",
        "last_star_ts": "1576963093",
        "local_score": 2741,
        "name": "Scott Hankinson",
        "stars": 34
    },
    "456457": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575331320"
                },
                "2": {
                    "get_star_ts": "1575334266"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575923767"
                },
                "2": {
                    "get_star_ts": "1575941162"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576372995"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575944048"
                },
                "2": {
                    "get_star_ts": "1576362476"
                }
            }
        },
        "global_score": 0,
        "id": "456457",
        "last_star_ts": "1576372995",
        "local_score": 302,
        "name": "Matt Collins",
        "stars": 7
    },
    "479616": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576000825"
                },
                "2": {
                    "get_star_ts": "1576001816"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576962676"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576032702"
                },
                "2": {
                    "get_star_ts": "1576202724"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576252846"
                },
                "2": {
                    "get_star_ts": "1576254289"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576336311"
                },
                "2": {
                    "get_star_ts": "1576337456"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576429099"
                },
                "2": {
                    "get_star_ts": "1576442230"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576457709"
                },
                "2": {
                    "get_star_ts": "1576460114"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576545321"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576880149"
                },
                "2": {
                    "get_star_ts": "1576904724"
                }
            }
        },
        "global_score": 0,
        "id": "479616",
        "last_star_ts": "1576962676",
        "local_score": 793,
        "name": "luxingzhe",
        "stars": 16
    },
    "484824": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575215398"
                },
                "2": {
                    "get_star_ts": "1575216471"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576432855"
                },
                "2": {
                    "get_star_ts": "1576641685"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1577637582"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1578272640"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575311861"
                },
                "2": {
                    "get_star_ts": "1575338880"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575417609"
                },
                "2": {
                    "get_star_ts": "1575735937"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575750447"
                },
                "2": {
                    "get_star_ts": "1575824387"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576899825"
                },
                "2": {
                    "get_star_ts": "1577035650"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576100841"
                },
                "2": {
                    "get_star_ts": "1576107753"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1577294116"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576203386"
                },
                "2": {
                    "get_star_ts": "1576206966"
                }
            }
        },
        "global_score": 0,
        "id": "484824",
        "last_star_ts": "1578272640",
        "local_score": 1351,
        "name": "Donny Cheng",
        "stars": 19
    },
    "489391": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575418454"
                },
                "2": {
                    "get_star_ts": "1575423432"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575423767"
                },
                "2": {
                    "get_star_ts": "1575423815"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575425512"
                },
                "2": {
                    "get_star_ts": "1575425553"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575498897"
                },
                "2": {
                    "get_star_ts": "1575498908"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575576541"
                },
                "2": {
                    "get_star_ts": "1575576555"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575672009"
                },
                "2": {
                    "get_star_ts": "1575672168"
                }
            }
        },
        "global_score": 0,
        "id": "489391",
        "last_star_ts": "1575672168",
        "local_score": 875,
        "name": "Ben Williams",
        "stars": 12
    },
    "489593": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575424248"
                },
                "2": {
                    "get_star_ts": "1575430935"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575486957"
                },
                "2": {
                    "get_star_ts": "1575490866"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575676194"
                },
                "2": {
                    "get_star_ts": "1575695319"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576436437"
                }
            }
        },
        "global_score": 0,
        "id": "489593",
        "last_star_ts": "1576436437",
        "local_score": 337,
        "name": "Stephen Fretz",
        "stars": 7
    },
    "490424": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575305551"
                },
                "2": {
                    "get_star_ts": "1575319394"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575324995"
                },
                "2": {
                    "get_star_ts": "1575334051"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575393376"
                },
                "2": {
                    "get_star_ts": "1575401726"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575516881"
                },
                "2": {
                    "get_star_ts": "1575522225"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576768223"
                },
                "2": {
                    "get_star_ts": "1576782930"
                }
            }
        },
        "global_score": 0,
        "id": "490424",
        "last_star_ts": "1576782930",
        "local_score": 709,
        "name": "Issac Gebre-Michael",
        "stars": 10
    },
    "496775": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575292248"
                },
                "2": {
                    "get_star_ts": "1575347970"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575408954"
                }
            }
        },
        "global_score": 0,
        "id": "496775",
        "last_star_ts": "1575408954",
        "local_score": 184,
        "name": "Andrew B. McIntosh",
        "stars": 3
    },
    "498796": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575204938"
                },
                "2": {
                    "get_star_ts": "1575206256"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575292338"
                },
                "2": {
                    "get_star_ts": "1575476332"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575418535"
                },
                "2": {
                    "get_star_ts": "1575419841"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575464729"
                },
                "2": {
                    "get_star_ts": "1575466205"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576351463"
                },
                "2": {
                    "get_star_ts": "1576353502"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576369123"
                }
            }
        },
        "global_score": 0,
        "id": "498796",
        "last_star_ts": "1576369123",
        "local_score": 902,
        "name": "Chandler Anderson",
        "stars": 11
    },
    "502805": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575218847"
                },
                "2": {
                    "get_star_ts": "1575219993"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575959292"
                },
                "2": {
                    "get_star_ts": "1575966320"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576049474"
                },
                "2": {
                    "get_star_ts": "1576050986"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576152815"
                },
                "2": {
                    "get_star_ts": "1576156293"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576213531"
                },
                "2": {
                    "get_star_ts": "1576217171"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576349303"
                },
                "2": {
                    "get_star_ts": "1576350357"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576425527"
                },
                "2": {
                    "get_star_ts": "1576430714"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576501700"
                },
                "2": {
                    "get_star_ts": "1576526157"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576589578"
                },
                "2": {
                    "get_star_ts": "1576597645"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1576762032"
                },
                "2": {
                    "get_star_ts": "1576763159"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576764072"
                },
                "2": {
                    "get_star_ts": "1576771118"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575293981"
                },
                "2": {
                    "get_star_ts": "1575294957"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576868400"
                },
                "2": {
                    "get_star_ts": "1577113181"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1577117048"
                },
                "2": {
                    "get_star_ts": "1577122167"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577145626"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575384861"
                },
                "2": {
                    "get_star_ts": "1575385676"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575467655"
                },
                "2": {
                    "get_star_ts": "1575468412"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575561070"
                },
                "2": {
                    "get_star_ts": "1575567314"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575611820"
                },
                "2": {
                    "get_star_ts": "1575612782"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575697701"
                },
                "2": {
                    "get_star_ts": "1575702264"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575817051"
                },
                "2": {
                    "get_star_ts": "1575819983"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575943983"
                },
                "2": {
                    "get_star_ts": "1575944170"
                }
            }
        },
        "global_score": 0,
        "id": "502805",
        "last_star_ts": "1577145626",
        "local_score": 4358,
        "name": "Brenton Bazemore",
        "stars": 43
    },
    "542205": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576962600"
                },
                "2": {
                    "get_star_ts": "1576963926"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1577317321"
                },
                "2": {
                    "get_star_ts": "1577328376"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1577497939"
                },
                "2": {
                    "get_star_ts": "1578080361"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1578084367"
                }
            }
        },
        "global_score": 0,
        "id": "542205",
        "last_star_ts": "1578084367",
        "local_score": 162,
        "name": "Jeffrey Box",
        "stars": 7
    },
    "620130": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575214521"
                },
                "2": {
                    "get_star_ts": "1575214744"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575674417"
                },
                "2": {
                    "get_star_ts": "1575677892"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575732506"
                },
                "2": {
                    "get_star_ts": "1575733119"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575836199"
                },
                "2": {
                    "get_star_ts": "1575838376"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576262971"
                },
                "2": {
                    "get_star_ts": "1576266637"
                }
            }
        },
        "global_score": 0,
        "id": "620130",
        "last_star_ts": "1576266637",
        "local_score": 594,
        "name": "Zachary Guthrie",
        "stars": 10
    },
    "621192": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575334251"
                },
                "2": {
                    "get_star_ts": "1575334922"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576967572"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1577129236"
                },
                "2": {
                    "get_star_ts": "1577161857"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576256700"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1577165329"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575336710"
                },
                "2": {
                    "get_star_ts": "1575340108"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575406144"
                },
                "2": {
                    "get_star_ts": "1575419690"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575474666"
                },
                "2": {
                    "get_star_ts": "1575482875"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575743093"
                },
                "2": {
                    "get_star_ts": "1575743119"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575941039"
                },
                "2": {
                    "get_star_ts": "1575946620"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576358347"
                },
                "2": {
                    "get_star_ts": "1576362105"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576366272"
                },
                "2": {
                    "get_star_ts": "1576371437"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1577084988"
                },
                "2": {
                    "get_star_ts": "1577085050"
                }
            }
        },
        "global_score": 0,
        "id": "621192",
        "last_star_ts": "1577165329",
        "local_score": 1738,
        "name": "Malcolm Miller",
        "stars": 23
    },
    "621196": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575235429"
                },
                "2": {
                    "get_star_ts": "1575235795"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576004869"
                },
                "2": {
                    "get_star_ts": "1576015917"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576114926"
                },
                "2": {
                    "get_star_ts": "1576115575"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576174231"
                },
                "2": {
                    "get_star_ts": "1576194016"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576278155"
                },
                "2": {
                    "get_star_ts": "1576280595"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576366297"
                },
                "2": {
                    "get_star_ts": "1576369449"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576424981"
                },
                "2": {
                    "get_star_ts": "1576448100"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576544331"
                },
                "2": {
                    "get_star_ts": "1576620607"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576632442"
                },
                "2": {
                    "get_star_ts": "1576636569"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1577040797"
                },
                "2": {
                    "get_star_ts": "1577048074"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575280635"
                },
                "2": {
                    "get_star_ts": "1575281664"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1578018344"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1577295085"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577052488"
                },
                "2": {
                    "get_star_ts": "1577913371"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577223218"
                },
                "2": {
                    "get_star_ts": "1577237675"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575371403"
                },
                "2": {
                    "get_star_ts": "1575371931"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575456233"
                },
                "2": {
                    "get_star_ts": "1575466606"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575569003"
                },
                "2": {
                    "get_star_ts": "1575582402"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575627160"
                },
                "2": {
                    "get_star_ts": "1575628297"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575722454"
                },
                "2": {
                    "get_star_ts": "1575736520"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575808619"
                },
                "2": {
                    "get_star_ts": "1575809617"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575943138"
                },
                "2": {
                    "get_star_ts": "1575943410"
                }
            }
        },
        "global_score": 0,
        "id": "621196",
        "last_star_ts": "1578018344",
        "local_score": 4136,
        "name": "Margaret ONeill",
        "stars": 42
    },
    "621633": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "621633",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "Carrie Flaspohler",
        "stars": 0
    },
    "621636": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575177736"
                },
                "2": {
                    "get_star_ts": "1575235304"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575350744"
                },
                "2": {
                    "get_star_ts": "1575393814"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575430884"
                },
                "2": {
                    "get_star_ts": "1576000375"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575439647"
                },
                "2": {
                    "get_star_ts": "1575559507"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576028292"
                },
                "2": {
                    "get_star_ts": "1576460633"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576543788"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1577144679"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576989566"
                },
                "2": {
                    "get_star_ts": "1578537941"
                }
            }
        },
        "global_score": 0,
        "id": "621636",
        "last_star_ts": "1578537941",
        "local_score": 970,
        "name": "Jordan Ivy",
        "stars": 14
    },
    "621637": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575176857"
                },
                "2": {
                    "get_star_ts": "1575177158"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576597617"
                },
                "2": {
                    "get_star_ts": "1576605516"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576615311"
                },
                "2": {
                    "get_star_ts": "1576756880"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576239641"
                },
                "2": {
                    "get_star_ts": "1576240260"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576785016"
                },
                "2": {
                    "get_star_ts": "1576797727"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576842698"
                },
                "2": {
                    "get_star_ts": "1576854073"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1577102841"
                },
                "2": {
                    "get_star_ts": "1577131099"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575292397"
                },
                "2": {
                    "get_star_ts": "1575293500"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575377388"
                },
                "2": {
                    "get_star_ts": "1575377873"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575509951"
                },
                "2": {
                    "get_star_ts": "1575510691"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575550639"
                },
                "2": {
                    "get_star_ts": "1575654256"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575637314"
                },
                "2": {
                    "get_star_ts": "1575653625"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575698188"
                },
                "2": {
                    "get_star_ts": "1576152455"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576164234"
                },
                "2": {
                    "get_star_ts": "1576170870"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576174434"
                },
                "2": {
                    "get_star_ts": "1576176602"
                }
            }
        },
        "global_score": 0,
        "id": "621637",
        "last_star_ts": "1577131099",
        "local_score": 2682,
        "name": "Steve Adamson",
        "stars": 30
    },
    "621638": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575316160"
                },
                "2": {
                    "get_star_ts": "1575316514"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575319891"
                },
                "2": {
                    "get_star_ts": "1575319945"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575380667"
                },
                "2": {
                    "get_star_ts": "1575380689"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575468544"
                },
                "2": {
                    "get_star_ts": "1575468579"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575568958"
                },
                "2": {
                    "get_star_ts": "1575568975"
                }
            }
        },
        "global_score": 0,
        "id": "621638",
        "last_star_ts": "1575568975",
        "local_score": 847,
        "name": "Tim Cailloux",
        "stars": 10
    },
    "621652": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576526087"
                },
                "2": {
                    "get_star_ts": "1576526672"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576811427"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576533168"
                },
                "2": {
                    "get_star_ts": "1576599367"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576645542"
                },
                "2": {
                    "get_star_ts": "1576668245"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576669678"
                },
                "2": {
                    "get_star_ts": "1576683582"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576716790"
                },
                "2": {
                    "get_star_ts": "1576719834"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576726504"
                },
                "2": {
                    "get_star_ts": "1576727816"
                }
            }
        },
        "global_score": 0,
        "id": "621652",
        "last_star_ts": "1576811427",
        "local_score": 560,
        "name": "Karen Jones",
        "stars": 13
    },
    "621657": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575315256"
                },
                "2": {
                    "get_star_ts": "1575571565"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575641702"
                },
                "2": {
                    "get_star_ts": "1575653479"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575822648"
                },
                "2": {
                    "get_star_ts": "1575852048"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575858666"
                },
                "2": {
                    "get_star_ts": "1575988692"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576108768"
                },
                "2": {
                    "get_star_ts": "1576122898"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576418558"
                },
                "2": {
                    "get_star_ts": "1576462556"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576530543"
                },
                "2": {
                    "get_star_ts": "1577040335"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1577049336"
                },
                "2": {
                    "get_star_ts": "1577053314"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1577223446"
                }
            }
        },
        "global_score": 0,
        "id": "621657",
        "last_star_ts": "1577223446",
        "local_score": 985,
        "name": "Walt Austin",
        "stars": 17
    },
    "621663": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575295257"
                },
                "2": {
                    "get_star_ts": "1575305084"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575431816"
                }
            }
        },
        "global_score": 0,
        "id": "621663",
        "last_star_ts": "1575431816",
        "local_score": 194,
        "name": "Rachel Lieberman",
        "stars": 3
    },
    "621711": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575343264"
                },
                "2": {
                    "get_star_ts": "1575347906"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1578595861"
                },
                "2": {
                    "get_star_ts": "1578685355"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1578665981"
                },
                "2": {
                    "get_star_ts": "1578689354"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1578801352"
                },
                "2": {
                    "get_star_ts": "1578801399"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1578878012"
                },
                "2": {
                    "get_star_ts": "1578878095"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1578878221"
                },
                "2": {
                    "get_star_ts": "1578878505"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1578878617"
                },
                "2": {
                    "get_star_ts": "1578878708"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1578878778"
                },
                "2": {
                    "get_star_ts": "1578878863"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1578879120"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575482898"
                },
                "2": {
                    "get_star_ts": "1575487479"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1578879484"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575560581"
                },
                "2": {
                    "get_star_ts": "1575565445"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575574331"
                },
                "2": {
                    "get_star_ts": "1575580837"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1577308069"
                },
                "2": {
                    "get_star_ts": "1577318721"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577476299"
                },
                "2": {
                    "get_star_ts": "1577483704"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1578341142"
                },
                "2": {
                    "get_star_ts": "1578427028"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1578430613"
                },
                "2": {
                    "get_star_ts": "1578435396"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1578501231"
                },
                "2": {
                    "get_star_ts": "1578515434"
                }
            }
        },
        "global_score": 0,
        "id": "621711",
        "last_star_ts": "1578879484",
        "local_score": 2343,
        "name": "Stephen Jang",
        "stars": 34
    },
    "621726": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575200640"
                },
                "2": {
                    "get_star_ts": "1575201547"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576001708"
                },
                "2": {
                    "get_star_ts": "1576024158"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576087165"
                },
                "2": {
                    "get_star_ts": "1576089833"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576171633"
                },
                "2": {
                    "get_star_ts": "1576179745"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576252490"
                },
                "2": {
                    "get_star_ts": "1576256921"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576431837"
                },
                "2": {
                    "get_star_ts": "1576433824"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576513365"
                },
                "2": {
                    "get_star_ts": "1576513537"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576516715"
                },
                "2": {
                    "get_star_ts": "1576538057"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576563728"
                },
                "2": {
                    "get_star_ts": "1576601641"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576783489"
                },
                "2": {
                    "get_star_ts": "1576797856"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575265220"
                },
                "2": {
                    "get_star_ts": "1575266085"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576888510"
                },
                "2": {
                    "get_star_ts": "1576988283"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1577816055"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577121622"
                }
            },
            "23": {
                "1": {
                    "get_star_ts": "1577380858"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577304700"
                },
                "2": {
                    "get_star_ts": "1577335553"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1577737548"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575355340"
                },
                "2": {
                    "get_star_ts": "1575356753"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575436509"
                },
                "2": {
                    "get_star_ts": "1575437644"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575527367"
                },
                "2": {
                    "get_star_ts": "1575530475"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575616557"
                },
                "2": {
                    "get_star_ts": "1575620025"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575696560"
                },
                "2": {
                    "get_star_ts": "1575766367"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575782192"
                },
                "2": {
                    "get_star_ts": "1575786165"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575931300"
                },
                "2": {
                    "get_star_ts": "1575931407"
                }
            }
        },
        "global_score": 0,
        "id": "621726",
        "last_star_ts": "1577816055",
        "local_score": 4467,
        "name": "Coin Graham",
        "stars": 44
    },
    "621733": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575328607"
                },
                "2": {
                    "get_star_ts": "1575332389"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575343332"
                },
                "2": {
                    "get_star_ts": "1575345914"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575435620"
                },
                "2": {
                    "get_star_ts": "1575439173"
                }
            }
        },
        "global_score": 0,
        "id": "621733",
        "last_star_ts": "1575439173",
        "local_score": 392,
        "name": "Andy Harrison",
        "stars": 6
    },
    "621765": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575247823"
                },
                "2": {
                    "get_star_ts": "1575250445"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575355116"
                },
                "2": {
                    "get_star_ts": "1575688037"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1577055026"
                },
                "2": {
                    "get_star_ts": "1577577123"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1578015530"
                },
                "2": {
                    "get_star_ts": "1578022941"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1578106221"
                },
                "2": {
                    "get_star_ts": "1578205932"
                }
            }
        },
        "global_score": 0,
        "id": "621765",
        "last_star_ts": "1578205932",
        "local_score": 488,
        "name": "brandon-myers-009",
        "stars": 10
    },
    "622049": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575291834"
                },
                "2": {
                    "get_star_ts": "1575378363"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575561839"
                }
            }
        },
        "global_score": 0,
        "id": "622049",
        "last_star_ts": "1575561839",
        "local_score": 170,
        "name": "Jean Altidor",
        "stars": 3
    },
    "622289": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575292264"
                },
                "2": {
                    "get_star_ts": "1575386874"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575390353"
                },
                "2": {
                    "get_star_ts": "1575395607"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1578752457"
                },
                "2": {
                    "get_star_ts": "1578752486"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1578753029"
                },
                "2": {
                    "get_star_ts": "1578753046"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1578753316"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1578754214"
                },
                "2": {
                    "get_star_ts": "1578754241"
                }
            }
        },
        "global_score": 0,
        "id": "622289",
        "last_star_ts": "1578754241",
        "local_score": 658,
        "name": "KenStillings",
        "stars": 11
    },
    "622385": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575176539"
                },
                "2": {
                    "get_star_ts": "1575177032"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576008690"
                },
                "2": {
                    "get_star_ts": "1576306255"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576331583"
                },
                "2": {
                    "get_star_ts": "1576332012"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576333528"
                },
                "2": {
                    "get_star_ts": "1576341785"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576342213"
                },
                "2": {
                    "get_star_ts": "1576342553"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576456521"
                },
                "2": {
                    "get_star_ts": "1576508544"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576613641"
                },
                "2": {
                    "get_star_ts": "1576613936"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576883045"
                },
                "2": {
                    "get_star_ts": "1576884624"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576885673"
                },
                "2": {
                    "get_star_ts": "1576889639"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1577073602"
                },
                "2": {
                    "get_star_ts": "1577076562"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1577079416"
                },
                "2": {
                    "get_star_ts": "1577082349"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575263235"
                },
                "2": {
                    "get_star_ts": "1575263404"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1577135464"
                },
                "2": {
                    "get_star_ts": "1577208124"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1577167296"
                },
                "2": {
                    "get_star_ts": "1577167559"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577168280"
                },
                "2": {
                    "get_star_ts": "1577209421"
                }
            },
            "23": {
                "1": {
                    "get_star_ts": "1577077967"
                },
                "2": {
                    "get_star_ts": "1577078166"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577164164"
                },
                "2": {
                    "get_star_ts": "1577241405"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1577253683"
                },
                "2": {
                    "get_star_ts": "1577253686"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575351742"
                },
                "2": {
                    "get_star_ts": "1575352311"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575435818"
                },
                "2": {
                    "get_star_ts": "1575436427"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575523138"
                },
                "2": {
                    "get_star_ts": "1575523895"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575608810"
                },
                "2": {
                    "get_star_ts": "1575609299"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575756942"
                },
                "2": {
                    "get_star_ts": "1575759112"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575841469"
                },
                "2": {
                    "get_star_ts": "1575841902"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576004103"
                },
                "2": {
                    "get_star_ts": "1576004130"
                }
            }
        },
        "global_score": 120,
        "id": "622385",
        "last_star_ts": "1577253686",
        "local_score": 4980,
        "name": "Kyle Mayes",
        "stars": 50
    },
    "622741": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575929685"
                },
                "2": {
                    "get_star_ts": "1575929696"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1578705213"
                },
                "2": {
                    "get_star_ts": "1578705259"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1578706962"
                },
                "2": {
                    "get_star_ts": "1578707114"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1578719701"
                },
                "2": {
                    "get_star_ts": "1578719732"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1578722034"
                },
                "2": {
                    "get_star_ts": "1578722059"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1578727192"
                },
                "2": {
                    "get_star_ts": "1578727453"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1578722501"
                },
                "2": {
                    "get_star_ts": "1578725003"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1578728055"
                },
                "2": {
                    "get_star_ts": "1578728140"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576035943"
                },
                "2": {
                    "get_star_ts": "1576078603"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1578729178"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1578770438"
                },
                "2": {
                    "get_star_ts": "1578772674"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1578624115"
                },
                "2": {
                    "get_star_ts": "1578635188"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1578635632"
                },
                "2": {
                    "get_star_ts": "1578636035"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1578671703"
                },
                "2": {
                    "get_star_ts": "1578672671"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1578690384"
                },
                "2": {
                    "get_star_ts": "1578690779"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1578698960"
                },
                "2": {
                    "get_star_ts": "1578698991"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1578700282"
                },
                "2": {
                    "get_star_ts": "1578701651"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1578704164"
                },
                "2": {
                    "get_star_ts": "1578704181"
                }
            }
        },
        "global_score": 0,
        "id": "622741",
        "last_star_ts": "1578772674",
        "local_score": 2192,
        "name": "Charli X",
        "stars": 35
    },
    "622753": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575247302"
                },
                "2": {
                    "get_star_ts": "1575247936"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576290596"
                },
                "2": {
                    "get_star_ts": "1576367044"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576372993"
                },
                "2": {
                    "get_star_ts": "1576376542"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576524643"
                },
                "2": {
                    "get_star_ts": "1576609836"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576613526"
                },
                "2": {
                    "get_star_ts": "1576695184"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576870723"
                },
                "2": {
                    "get_star_ts": "1576875167"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1577731861"
                },
                "2": {
                    "get_star_ts": "1577740546"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1577748063"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575263562"
                },
                "2": {
                    "get_star_ts": "1575265241"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575486372"
                },
                "2": {
                    "get_star_ts": "1575487548"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575489627"
                },
                "2": {
                    "get_star_ts": "1575489869"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575557095"
                },
                "2": {
                    "get_star_ts": "1575577887"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575729240"
                },
                "2": {
                    "get_star_ts": "1576010506"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576015838"
                },
                "2": {
                    "get_star_ts": "1576085211"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576088973"
                },
                "2": {
                    "get_star_ts": "1576090053"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576095455"
                },
                "2": {
                    "get_star_ts": "1576095521"
                }
            }
        },
        "global_score": 0,
        "id": "622753",
        "last_star_ts": "1577748063",
        "local_score": 2646,
        "name": "Larkin Blake",
        "stars": 31
    },
    "622783": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575462435"
                },
                "2": {
                    "get_star_ts": "1575462505"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575981992"
                },
                "2": {
                    "get_star_ts": "1575983089"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576066890"
                },
                "2": {
                    "get_star_ts": "1576068741"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576163718"
                },
                "2": {
                    "get_star_ts": "1576164654"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576242175"
                },
                "2": {
                    "get_star_ts": "1576243468"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576374405"
                },
                "2": {
                    "get_star_ts": "1576375311"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576416821"
                },
                "2": {
                    "get_star_ts": "1576418442"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576514266"
                },
                "2": {
                    "get_star_ts": "1576514692"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576601937"
                },
                "2": {
                    "get_star_ts": "1576671989"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1576766672"
                },
                "2": {
                    "get_star_ts": "1576767028"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576757974"
                },
                "2": {
                    "get_star_ts": "1576758447"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575462371"
                },
                "2": {
                    "get_star_ts": "1575462487"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1576871986"
                },
                "2": {
                    "get_star_ts": "1576873158"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1576934138"
                },
                "2": {
                    "get_star_ts": "1576935378"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577024615"
                },
                "2": {
                    "get_star_ts": "1577027335"
                }
            },
            "23": {
                "1": {
                    "get_star_ts": "1577111128"
                },
                "2": {
                    "get_star_ts": "1577114091"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1577195385"
                },
                "2": {
                    "get_star_ts": "1577198230"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1577313892"
                },
                "2": {
                    "get_star_ts": "1577313900"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575462232"
                },
                "2": {
                    "get_star_ts": "1575463009"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575461758"
                },
                "2": {
                    "get_star_ts": "1575461979"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575556291"
                },
                "2": {
                    "get_star_ts": "1575556808"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575636064"
                },
                "2": {
                    "get_star_ts": "1575636556"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575723091"
                },
                "2": {
                    "get_star_ts": "1575724199"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575811122"
                },
                "2": {
                    "get_star_ts": "1575812407"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575888883"
                },
                "2": {
                    "get_star_ts": "1575889875"
                }
            }
        },
        "global_score": 0,
        "id": "622783",
        "last_star_ts": "1577313900",
        "local_score": 4899,
        "name": "Alexandra Chastain",
        "stars": 50
    },
    "622802": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575211702"
                },
                "2": {
                    "get_star_ts": "1575212317"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575296064"
                },
                "2": {
                    "get_star_ts": "1575296484"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575352221"
                },
                "2": {
                    "get_star_ts": "1575353373"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575485693"
                },
                "2": {
                    "get_star_ts": "1575490110"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575565113"
                },
                "2": {
                    "get_star_ts": "1578769387"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1578333665"
                },
                "2": {
                    "get_star_ts": "1578344533"
                }
            }
        },
        "global_score": 0,
        "id": "622802",
        "last_star_ts": "1578769387",
        "local_score": 995,
        "name": "Preethi G",
        "stars": 12
    },
    "622803": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "622803",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "DBiles",
        "stars": 0
    },
    "623312": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575497147"
                }
            }
        },
        "global_score": 0,
        "id": "623312",
        "last_star_ts": "1575497147",
        "local_score": 38,
        "name": "dickiesanders",
        "stars": 1
    },
    "623353": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575211895"
                },
                "2": {
                    "get_star_ts": "1575215534"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575383998"
                },
                "2": {
                    "get_star_ts": "1575385572"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575397935"
                },
                "2": {
                    "get_star_ts": "1575398653"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575470360"
                },
                "2": {
                    "get_star_ts": "1575473034"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575634420"
                },
                "2": {
                    "get_star_ts": "1575637437"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575673696"
                },
                "2": {
                    "get_star_ts": "1575733453"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576096200"
                },
                "2": {
                    "get_star_ts": "1576160336"
                }
            }
        },
        "global_score": 0,
        "id": "623353",
        "last_star_ts": "1576160336",
        "local_score": 1156,
        "name": "Chad McKee",
        "stars": 14
    },
    "623392": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575297441"
                },
                "2": {
                    "get_star_ts": "1575490941"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575500884"
                },
                "2": {
                    "get_star_ts": "1575505814"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575564043"
                },
                "2": {
                    "get_star_ts": "1575751113"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575917015"
                },
                "2": {
                    "get_star_ts": "1575931732"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576120169"
                },
                "2": {
                    "get_star_ts": "1577057407"
                }
            }
        },
        "global_score": 0,
        "id": "623392",
        "last_star_ts": "1577057407",
        "local_score": 530,
        "name": "Bryan Swegman",
        "stars": 10
    },
    "623477": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575392551"
                },
                "2": {
                    "get_star_ts": "1575402498"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576381672"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576448110"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575485022"
                },
                "2": {
                    "get_star_ts": "1575490489"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575572443"
                },
                "2": {
                    "get_star_ts": "1575579304"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575582802"
                },
                "2": {
                    "get_star_ts": "1575583075"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575689111"
                },
                "2": {
                    "get_star_ts": "1575690288"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575674045"
                },
                "2": {
                    "get_star_ts": "1575686871"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576544367"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575857345"
                },
                "2": {
                    "get_star_ts": "1575861104"
                }
            }
        },
        "global_score": 0,
        "id": "623477",
        "last_star_ts": "1576544367",
        "local_score": 1177,
        "name": "Paul Medeiros",
        "stars": 17
    },
    "624602": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575499343"
                },
                "2": {
                    "get_star_ts": "1575501466"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575515544"
                },
                "2": {
                    "get_star_ts": "1575554711"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575665439"
                },
                "2": {
                    "get_star_ts": "1575667718"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575728966"
                },
                "2": {
                    "get_star_ts": "1575730836"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577228856"
                },
                "2": {
                    "get_star_ts": "1577244876"
                }
            }
        },
        "global_score": 0,
        "id": "624602",
        "last_star_ts": "1577244876",
        "local_score": 506,
        "name": "brennaman",
        "stars": 10
    },
    "624636": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "624636",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "Taylor Sostarecz",
        "stars": 0
    },
    "624706": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575223091"
                },
                "2": {
                    "get_star_ts": "1575224691"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576019618"
                },
                "2": {
                    "get_star_ts": "1576025443"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576167704"
                },
                "2": {
                    "get_star_ts": "1578534315"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1578532213"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575319694"
                },
                "2": {
                    "get_star_ts": "1575321126"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575377130"
                },
                "2": {
                    "get_star_ts": "1575404735"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575475142"
                },
                "2": {
                    "get_star_ts": "1575503708"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576265402"
                },
                "2": {
                    "get_star_ts": "1578531797"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575667721"
                },
                "2": {
                    "get_star_ts": "1575668086"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1578532613"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575819810"
                },
                "2": {
                    "get_star_ts": "1575824528"
                }
            }
        },
        "global_score": 0,
        "id": "624706",
        "last_star_ts": "1578534315",
        "local_score": 1675,
        "name": "byronk14",
        "stars": 20
    },
    "625478": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575331511"
                },
                "2": {
                    "get_star_ts": "1575331796"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576113627"
                },
                "2": {
                    "get_star_ts": "1578888944"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576110409"
                },
                "2": {
                    "get_star_ts": "1576111193"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576183908"
                },
                "2": {
                    "get_star_ts": "1576247343"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576248275"
                },
                "2": {
                    "get_star_ts": "1576298077"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576335777"
                },
                "2": {
                    "get_star_ts": "1578508372"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576547934"
                },
                "2": {
                    "get_star_ts": "1576552137"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576943893"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576603601"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576767344"
                },
                "2": {
                    "get_star_ts": "1576932078"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575334230"
                },
                "2": {
                    "get_star_ts": "1575335169"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1578082596"
                },
                "2": {
                    "get_star_ts": "1578087081"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1577118169"
                },
                "2": {
                    "get_star_ts": "1578857568"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1578323675"
                },
                "2": {
                    "get_star_ts": "1578328752"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1578846698"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575432342"
                },
                "2": {
                    "get_star_ts": "1575433118"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575513297"
                },
                "2": {
                    "get_star_ts": "1575513705"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575561471"
                },
                "2": {
                    "get_star_ts": "1575575238"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575634351"
                },
                "2": {
                    "get_star_ts": "1575641858"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575723640"
                },
                "2": {
                    "get_star_ts": "1575741213"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575825672"
                },
                "2": {
                    "get_star_ts": "1575827345"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575951197"
                },
                "2": {
                    "get_star_ts": "1575951248"
                }
            }
        },
        "global_score": 0,
        "id": "625478",
        "last_star_ts": "1578888944",
        "local_score": 3730,
        "name": "Alexander Lyons",
        "stars": 41
    },
    "625479": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "625479",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "Samia Khan",
        "stars": 0
    },
    "625481": {
        "completion_day_level": {
            "22": {
                "1": {
                    "get_star_ts": "1577028256"
                }
            }
        },
        "global_score": 0,
        "id": "625481",
        "last_star_ts": "1577028256",
        "local_score": 107,
        "name": "Annie Kong",
        "stars": 1
    },
    "625584": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1578259152"
                }
            }
        },
        "global_score": 0,
        "id": "625584",
        "last_star_ts": "1578259152",
        "local_score": 11,
        "name": "AJ John",
        "stars": 1
    },
    "625603": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576274725"
                },
                "2": {
                    "get_star_ts": "1576275391"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576297874"
                },
                "2": {
                    "get_star_ts": "1576365386"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576445796"
                },
                "2": {
                    "get_star_ts": "1576447671"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576455627"
                },
                "2": {
                    "get_star_ts": "1576457666"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576534809"
                },
                "2": {
                    "get_star_ts": "1576812866"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577817799"
                },
                "2": {
                    "get_star_ts": "1577824382"
                }
            }
        },
        "global_score": 0,
        "id": "625603",
        "last_star_ts": "1577824382",
        "local_score": 446,
        "name": "Sierra Wulfson",
        "stars": 12
    },
    "626790": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576948919"
                },
                "2": {
                    "get_star_ts": "1576949723"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576956654"
                },
                "2": {
                    "get_star_ts": "1576957633"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576960424"
                },
                "2": {
                    "get_star_ts": "1576976942"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576980607"
                },
                "2": {
                    "get_star_ts": "1576982719"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1577037114"
                },
                "2": {
                    "get_star_ts": "1577051703"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577071195"
                },
                "2": {
                    "get_star_ts": "1577072424"
                }
            }
        },
        "global_score": 0,
        "id": "626790",
        "last_star_ts": "1577072424",
        "local_score": 423,
        "name": "devininslo",
        "stars": 12
    },
    "627414": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575176732"
                },
                "2": {
                    "get_star_ts": "1575177895"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576018268"
                },
                "2": {
                    "get_star_ts": "1576019278"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1578691380"
                },
                "2": {
                    "get_star_ts": "1578692171"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1578694170"
                },
                "2": {
                    "get_star_ts": "1578694557"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1578697009"
                },
                "2": {
                    "get_star_ts": "1578697592"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1578699605"
                },
                "2": {
                    "get_star_ts": "1578700127"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575266389"
                },
                "2": {
                    "get_star_ts": "1575267337"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575356545"
                },
                "2": {
                    "get_star_ts": "1575356606"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575466371"
                },
                "2": {
                    "get_star_ts": "1575467324"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575530223"
                },
                "2": {
                    "get_star_ts": "1575555142"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575611330"
                },
                "2": {
                    "get_star_ts": "1575613323"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575779389"
                },
                "2": {
                    "get_star_ts": "1575781216"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575782747"
                },
                "2": {
                    "get_star_ts": "1575786045"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576540300"
                },
                "2": {
                    "get_star_ts": "1576540926"
                }
            }
        },
        "global_score": 0,
        "id": "627414",
        "last_star_ts": "1578700127",
        "local_score": 2595,
        "name": "jocago",
        "stars": 28
    },
    "630259": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575217815"
                },
                "2": {
                    "get_star_ts": "1575218314"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575337184"
                },
                "2": {
                    "get_star_ts": "1575338661"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575512685"
                },
                "2": {
                    "get_star_ts": "1576117454"
                }
            }
        },
        "global_score": 0,
        "id": "630259",
        "last_star_ts": "1576117454",
        "local_score": 435,
        "name": "Paul Shepherd",
        "stars": 6
    },
    "630264": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1577253819"
                },
                "2": {
                    "get_star_ts": "1577254791"
                }
            }
        },
        "global_score": 0,
        "id": "630264",
        "last_star_ts": "1577254791",
        "local_score": 27,
        "name": "Derek Johnson",
        "stars": 2
    },
    "633025": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576097902"
                }
            }
        },
        "global_score": 0,
        "id": "633025",
        "last_star_ts": "1576097902",
        "local_score": 21,
        "name": "Jason Sato",
        "stars": 1
    },
    "700795": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "700795",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "Tre Torbert",
        "stars": 0
    },
    "701446": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575351159"
                },
                "2": {
                    "get_star_ts": "1575473644"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575556401"
                },
                "2": {
                    "get_star_ts": "1575904727"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575987406"
                }
            }
        },
        "global_score": 0,
        "id": "701446",
        "last_star_ts": "1575987406",
        "local_score": 221,
        "name": "mattwith2tees",
        "stars": 5
    },
    "705133": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575298690"
                },
                "2": {
                    "get_star_ts": "1575299246"
                }
            }
        },
        "global_score": 0,
        "id": "705133",
        "last_star_ts": "1575299246",
        "local_score": 142,
        "name": "Kathleen Hoehn",
        "stars": 2
    },
    "712223": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575310402"
                },
                "2": {
                    "get_star_ts": "1575311177"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575317954"
                },
                "2": {
                    "get_star_ts": "1575322863"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575401440"
                },
                "2": {
                    "get_star_ts": "1575405027"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575467813"
                },
                "2": {
                    "get_star_ts": "1575473029"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575638227"
                },
                "2": {
                    "get_star_ts": "1577047605"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577727293"
                },
                "2": {
                    "get_star_ts": "1577731053"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1577735966"
                },
                "2": {
                    "get_star_ts": "1577809364"
                }
            }
        },
        "global_score": 0,
        "id": "712223",
        "last_star_ts": "1577809364",
        "local_score": 997,
        "name": "Jackson Powell",
        "stars": 14
    },
    "712873": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575309914"
                },
                "2": {
                    "get_star_ts": "1575311333"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575315714"
                },
                "2": {
                    "get_star_ts": "1575325978"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575428172"
                },
                "2": {
                    "get_star_ts": "1575431450"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575553651"
                },
                "2": {
                    "get_star_ts": "1575554345"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1577062785"
                }
            }
        },
        "global_score": 0,
        "id": "712873",
        "last_star_ts": "1577062785",
        "local_score": 641,
        "name": "dr-sly",
        "stars": 9
    },
    "746494": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575496338"
                }
            }
        },
        "global_score": 0,
        "id": "746494",
        "last_star_ts": "1575496338",
        "local_score": 39,
        "name": "Caitlin M.",
        "stars": 1
    },
    "756902": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575474149"
                },
                "2": {
                    "get_star_ts": "1575475953"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1577722647"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1577722761"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576607651"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575479591"
                },
                "2": {
                    "get_star_ts": "1575482200"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575576065"
                },
                "2": {
                    "get_star_ts": "1575649454"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575509794"
                },
                "2": {
                    "get_star_ts": "1575511372"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575903504"
                },
                "2": {
                    "get_star_ts": "1575919618"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575692840"
                },
                "2": {
                    "get_star_ts": "1575694217"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575998533"
                },
                "2": {
                    "get_star_ts": "1577128169"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575917180"
                },
                "2": {
                    "get_star_ts": "1575918987"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1577722129"
                },
                "2": {
                    "get_star_ts": "1577722167"
                }
            }
        },
        "global_score": 0,
        "id": "756902",
        "last_star_ts": "1577722761",
        "local_score": 1459,
        "name": "Jessica Jestings",
        "stars": 21
    },
    "757038": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575469315"
                },
                "2": {
                    "get_star_ts": "1575473746"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575474952"
                },
                "2": {
                    "get_star_ts": "1575479909"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575570972"
                },
                "2": {
                    "get_star_ts": "1575574988"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575642679"
                },
                "2": {
                    "get_star_ts": "1575642719"
                }
            }
        },
        "global_score": 0,
        "id": "757038",
        "last_star_ts": "1575642719",
        "local_score": 440,
        "name": "Ryan Gumpper",
        "stars": 8
    },
    "758656": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576120474"
                },
                "2": {
                    "get_star_ts": "1576120943"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576156111"
                },
                "2": {
                    "get_star_ts": "1576191218"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576262706"
                },
                "2": {
                    "get_star_ts": "1576265023"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576270412"
                },
                "2": {
                    "get_star_ts": "1576276476"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576305957"
                },
                "2": {
                    "get_star_ts": "1576477182"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576501221"
                },
                "2": {
                    "get_star_ts": "1576558699"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576683324"
                }
            }
        },
        "global_score": 0,
        "id": "758656",
        "last_star_ts": "1576683324",
        "local_score": 565,
        "name": "Dominique Bashizi",
        "stars": 13
    },
    "761762": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575486266"
                },
                "2": {
                    "get_star_ts": "1575486281"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1575993957"
                },
                "2": {
                    "get_star_ts": "1576006895"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576073429"
                },
                "2": {
                    "get_star_ts": "1576074524"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576161463"
                },
                "2": {
                    "get_star_ts": "1576176270"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576239822"
                },
                "2": {
                    "get_star_ts": "1576242420"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576524579"
                },
                "2": {
                    "get_star_ts": "1576525565"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576677280"
                },
                "2": {
                    "get_star_ts": "1576715828"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576764185"
                },
                "2": {
                    "get_star_ts": "1576787996"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576849900"
                },
                "2": {
                    "get_star_ts": "1576856517"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1578061716"
                },
                "2": {
                    "get_star_ts": "1578067037"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575486333"
                },
                "2": {
                    "get_star_ts": "1575486343"
                }
            },
            "21": {
                "1": {
                    "get_star_ts": "1578322178"
                },
                "2": {
                    "get_star_ts": "1578323142"
                }
            },
            "22": {
                "1": {
                    "get_star_ts": "1578070632"
                }
            },
            "24": {
                "1": {
                    "get_star_ts": "1578328964"
                }
            },
            "25": {
                "1": {
                    "get_star_ts": "1578326459"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575486691"
                },
                "2": {
                    "get_star_ts": "1575486696"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575486749"
                },
                "2": {
                    "get_star_ts": "1575486759"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575555738"
                },
                "2": {
                    "get_star_ts": "1575556101"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575641174"
                },
                "2": {
                    "get_star_ts": "1575641674"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575815457"
                },
                "2": {
                    "get_star_ts": "1575901050"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575821443"
                },
                "2": {
                    "get_star_ts": "1575821909"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1575987872"
                },
                "2": {
                    "get_star_ts": "1575987973"
                }
            }
        },
        "global_score": 0,
        "id": "761762",
        "last_star_ts": "1578328964",
        "local_score": 3674,
        "name": "Lance Grady",
        "stars": 41
    },
    "779224": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575602753"
                },
                "2": {
                    "get_star_ts": "1575603257"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576113539"
                },
                "2": {
                    "get_star_ts": "1576121661"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576130461"
                },
                "2": {
                    "get_star_ts": "1576131681"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1576189055"
                },
                "2": {
                    "get_star_ts": "1576213677"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1576214728"
                },
                "2": {
                    "get_star_ts": "1576225801"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1576356233"
                },
                "2": {
                    "get_star_ts": "1576377102"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1576458903"
                },
                "2": {
                    "get_star_ts": "1576466697"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1576521734"
                },
                "2": {
                    "get_star_ts": "1576562418"
                }
            },
            "17": {
                "1": {
                    "get_star_ts": "1576622226"
                },
                "2": {
                    "get_star_ts": "1576645148"
                }
            },
            "18": {
                "1": {
                    "get_star_ts": "1576986022"
                },
                "2": {
                    "get_star_ts": "1577064188"
                }
            },
            "19": {
                "1": {
                    "get_star_ts": "1576814850"
                },
                "2": {
                    "get_star_ts": "1576855790"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575605112"
                },
                "2": {
                    "get_star_ts": "1575605599"
                }
            },
            "20": {
                "1": {
                    "get_star_ts": "1577076239"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575649472"
                },
                "2": {
                    "get_star_ts": "1575664165"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1575666846"
                },
                "2": {
                    "get_star_ts": "1575678836"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1575743219"
                },
                "2": {
                    "get_star_ts": "1575744592"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1575862596"
                },
                "2": {
                    "get_star_ts": "1575864660"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1575870909"
                },
                "2": {
                    "get_star_ts": "1575932865"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1575996075"
                },
                "2": {
                    "get_star_ts": "1575997769"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576033113"
                },
                "2": {
                    "get_star_ts": "1576033610"
                }
            }
        },
        "global_score": 0,
        "id": "779224",
        "last_star_ts": "1577076239",
        "local_score": 3276,
        "name": "G V",
        "stars": 39
    },
    "786859": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575663755"
                },
                "2": {
                    "get_star_ts": "1575664805"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575909070"
                },
                "2": {
                    "get_star_ts": "1575926690"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576695088"
                },
                "2": {
                    "get_star_ts": "1576695815"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576699500"
                },
                "2": {
                    "get_star_ts": "1576705837"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576780825"
                },
                "2": {
                    "get_star_ts": "1576782797"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576809746"
                },
                "2": {
                    "get_star_ts": "1576811972"
                }
            }
        },
        "global_score": 0,
        "id": "786859",
        "last_star_ts": "1576811972",
        "local_score": 499,
        "name": "Keyla Herrera Ruiz",
        "stars": 12
    },
    "793969": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575734019"
                },
                "2": {
                    "get_star_ts": "1575736096"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575746751"
                },
                "2": {
                    "get_star_ts": "1575750913"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1576443317"
                },
                "2": {
                    "get_star_ts": "1576447107"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576519306"
                },
                "2": {
                    "get_star_ts": "1576519571"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576771552"
                },
                "2": {
                    "get_star_ts": "1576846355"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1577188869"
                }
            }
        },
        "global_score": 0,
        "id": "793969",
        "last_star_ts": "1577188869",
        "local_score": 456,
        "name": "Clayseph",
        "stars": 11
    },
    "802113": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575840190"
                },
                "2": {
                    "get_star_ts": "1575860290"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576010850"
                },
                "2": {
                    "get_star_ts": "1576344147"
                }
            }
        },
        "global_score": 0,
        "id": "802113",
        "last_star_ts": "1576344147",
        "local_score": 114,
        "name": "penultimateman",
        "stars": 4
    },
    "808345": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1575910980"
                },
                "2": {
                    "get_star_ts": "1575916007"
                }
            },
            "10": {
                "1": {
                    "get_star_ts": "1576359525"
                },
                "2": {
                    "get_star_ts": "1576596457"
                }
            },
            "11": {
                "1": {
                    "get_star_ts": "1576679621"
                },
                "2": {
                    "get_star_ts": "1577369014"
                }
            },
            "12": {
                "1": {
                    "get_star_ts": "1577417376"
                },
                "2": {
                    "get_star_ts": "1577467424"
                }
            },
            "13": {
                "1": {
                    "get_star_ts": "1577472439"
                },
                "2": {
                    "get_star_ts": "1577636826"
                }
            },
            "14": {
                "1": {
                    "get_star_ts": "1578341688"
                },
                "2": {
                    "get_star_ts": "1578343579"
                }
            },
            "15": {
                "1": {
                    "get_star_ts": "1578759845"
                },
                "2": {
                    "get_star_ts": "1578764755"
                }
            },
            "16": {
                "1": {
                    "get_star_ts": "1578850971"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1575922622"
                },
                "2": {
                    "get_star_ts": "1575924265"
                }
            },
            "3": {
                "1": {
                    "get_star_ts": "1575994534"
                },
                "2": {
                    "get_star_ts": "1575995371"
                }
            },
            "4": {
                "1": {
                    "get_star_ts": "1576001329"
                },
                "2": {
                    "get_star_ts": "1576002959"
                }
            },
            "5": {
                "1": {
                    "get_star_ts": "1576007537"
                },
                "2": {
                    "get_star_ts": "1576011120"
                }
            },
            "6": {
                "1": {
                    "get_star_ts": "1576076877"
                },
                "2": {
                    "get_star_ts": "1576077748"
                }
            },
            "7": {
                "1": {
                    "get_star_ts": "1576087265"
                },
                "2": {
                    "get_star_ts": "1576181762"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576183926"
                },
                "2": {
                    "get_star_ts": "1576202700"
                }
            },
            "9": {
                "1": {
                    "get_star_ts": "1576267148"
                },
                "2": {
                    "get_star_ts": "1576267219"
                }
            }
        },
        "global_score": 0,
        "id": "808345",
        "last_star_ts": "1578850971",
        "local_score": 2134,
        "name": "Kendra Billings",
        "stars": 31
    },
    "828289": {
        "completion_day_level": {
            "1": {
                "1": {
                    "get_star_ts": "1576249877"
                },
                "2": {
                    "get_star_ts": "1576250263"
                }
            },
            "2": {
                "1": {
                    "get_star_ts": "1576252702"
                },
                "2": {
                    "get_star_ts": "1576513814"
                }
            },
            "8": {
                "1": {
                    "get_star_ts": "1576185526"
                },
                "2": {
                    "get_star_ts": "1576512368"
                }
            }
        },
        "global_score": 0,
        "id": "828289",
        "last_star_ts": "1576513814",
        "local_score": 231,
        "name": "Matt Bush",
        "stars": 6
    },
    "828926": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "828926",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "Gautam Krishna",
        "stars": 0
    },
    "846089": {
        "completion_day_level": {},
        "global_score": 0,
        "id": "846089",
        "last_star_ts": 0,
        "local_score": 0,
        "name": "Lane Lake",
        "stars": 0
    }
}).filter(({stars}) => stars);