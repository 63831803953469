const raw = {
  "members": {
    "140796": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638375914
          },
          "2": {
            "get_star_ts": 1638376386
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638450804
          },
          "2": {
            "get_star_ts": 1638451331
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508416
          },
          "2": {
            "get_star_ts": 1638509488
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638596705
          },
          "2": {
            "get_star_ts": 1638597420
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638715489
          },
          "2": {
            "get_star_ts": 1638715813
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638802170
          },
          "2": {
            "get_star_ts": 1638813209
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638881399
          },
          "2": {
            "get_star_ts": 1638881799
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638940413
          },
          "2": {
            "get_star_ts": 1638946332
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639029974
          },
          "2": {
            "get_star_ts": 1639034951
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639115131
          },
          "2": {
            "get_star_ts": 1639116423
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639244178
          },
          "2": {
            "get_star_ts": 1639245122
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639286685
          },
          "2": {
            "get_star_ts": 1639289879
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639446116
          },
          "2": {
            "get_star_ts": 1639446466
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639459069
          },
          "2": {
            "get_star_ts": 1639493553
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639575919
          },
          "2": {
            "get_star_ts": 1639630280
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639683407
          },
          "2": {
            "get_star_ts": 1639695448
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639722543
          },
          "2": {
            "get_star_ts": 1639723145
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639889919
          },
          "2": {
            "get_star_ts": 1639890567
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1639979815
          },
          "2": {
            "get_star_ts": 1639979861
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640099568
          },
          "2": {
            "get_star_ts": 1640102530
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640150839
          }
        }
      },
      "last_star_ts": 1640150839,
      "stars": 41,
      "local_score": 5130,
      "id": "140796",
      "name": "Donnie Mattingly",
      "global_score": 0
    },
    "186556": {
      "id": "186556",
      "stars": 50,
      "local_score": 6238,
      "name": "Chad Orwig",
      "global_score": 0,
      "last_star_ts": 1641166811,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638361529
          },
          "2": {
            "get_star_ts": 1638361747
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638456080
          },
          "2": {
            "get_star_ts": 1638456345
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638537609
          },
          "2": {
            "get_star_ts": 1638550547
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638615465
          },
          "2": {
            "get_star_ts": 1638616060
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638757428
          },
          "2": {
            "get_star_ts": 1638758075
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638795884
          },
          "2": {
            "get_star_ts": 1638796823
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638880044
          },
          "2": {
            "get_star_ts": 1638880956
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638964954
          },
          "2": {
            "get_star_ts": 1638968449
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639053331
          },
          "2": {
            "get_star_ts": 1639074323
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639139326
          },
          "2": {
            "get_star_ts": 1639140773
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639226685
          },
          "2": {
            "get_star_ts": 1639226971
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639311620
          },
          "2": {
            "get_star_ts": 1639312487
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639401968
          },
          "2": {
            "get_star_ts": 1639405218
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639483825
          },
          "2": {
            "get_star_ts": 1639505838
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639583622
          },
          "2": {
            "get_star_ts": 1639585257
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639670492
          },
          "2": {
            "get_star_ts": 1639673028
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639744827
          },
          "2": {
            "get_star_ts": 1639749220
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639856967
          },
          "2": {
            "get_star_ts": 1639857482
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1639933107
          },
          "2": {
            "get_star_ts": 1639935276
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640006828
          },
          "2": {
            "get_star_ts": 1640007021
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640088934
          },
          "2": {
            "get_star_ts": 1640095283
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640171223
          },
          "2": {
            "get_star_ts": 1640320743
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640355519
          },
          "2": {
            "get_star_ts": 1640708156
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1641158941
          },
          "2": {
            "get_star_ts": 1641159247
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1641166807
          },
          "2": {
            "get_star_ts": 1641166811
          }
        }
      }
    },
    "188240": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638414016
          },
          "2": {
            "get_star_ts": 1638414714
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638498625
          },
          "2": {
            "get_star_ts": 1638499273
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1640657343
          },
          "2": {
            "get_star_ts": 1640660382
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1640906093
          },
          "2": {
            "get_star_ts": 1640910818
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641263206
          },
          "2": {
            "get_star_ts": 1641265574
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641267122
          },
          "2": {
            "get_star_ts": 1642078784
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641350211
          },
          "2": {
            "get_star_ts": 1641351377
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1642075390
          }
        }
      },
      "last_star_ts": 1642078784,
      "local_score": 982,
      "stars": 15,
      "name": "trevcor",
      "id": "188240",
      "global_score": 0
    },
    "188248": {
      "last_star_ts": 1640726367,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638391505
          },
          "2": {
            "get_star_ts": 1638392140
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638456129
          },
          "2": {
            "get_star_ts": 1638456378
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638541862
          },
          "2": {
            "get_star_ts": 1638545171
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638631726
          },
          "2": {
            "get_star_ts": 1638632523
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638717357
          },
          "2": {
            "get_star_ts": 1638720675
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638802596
          },
          "2": {
            "get_star_ts": 1638829484
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638882463
          },
          "2": {
            "get_star_ts": 1638882743
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638978760
          },
          "2": {
            "get_star_ts": 1639001454
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639059150
          },
          "2": {
            "get_star_ts": 1639087384
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639146645
          },
          "2": {
            "get_star_ts": 1639147923
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639236646
          },
          "2": {
            "get_star_ts": 1639237140
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639326857
          },
          "2": {
            "get_star_ts": 1639327592
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639411372
          },
          "2": {
            "get_star_ts": 1639411485
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639459491
          },
          "2": {
            "get_star_ts": 1639465575
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639584505
          },
          "2": {
            "get_star_ts": 1639588586
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639671471
          },
          "2": {
            "get_star_ts": 1639778776
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639759063
          },
          "2": {
            "get_star_ts": 1639759608
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639856456
          },
          "2": {
            "get_star_ts": 1639856896
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1639943464
          },
          "2": {
            "get_star_ts": 1639944033
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640019855
          },
          "2": {
            "get_star_ts": 1640020118
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640098376
          },
          "2": {
            "get_star_ts": 1640114563
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640152822
          },
          "2": {
            "get_star_ts": 1640225460
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640726367
          }
        }
      },
      "global_score": 0,
      "stars": 45,
      "local_score": 5362,
      "name": "Matthew Keelan",
      "id": "188248"
    },
    "188249": {
      "local_score": 3962,
      "stars": 40,
      "id": "188249",
      "name": "Austin Assavavallop",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638454800
          },
          "2": {
            "get_star_ts": 1638457020
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638860568
          },
          "2": {
            "get_star_ts": 1638894891
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639056508
          },
          "2": {
            "get_star_ts": 1639094480
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639011689
          },
          "2": {
            "get_star_ts": 1639022081
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638936194
          },
          "2": {
            "get_star_ts": 1638936826
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638930888
          },
          "2": {
            "get_star_ts": 1638934516
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638912038
          },
          "2": {
            "get_star_ts": 1638912493
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639090012
          },
          "2": {
            "get_star_ts": 1639093893
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639100398
          },
          "2": {
            "get_star_ts": 1639107233
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639114497
          },
          "2": {
            "get_star_ts": 1639115688
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1641524587
          },
          "2": {
            "get_star_ts": 1641525039
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1641441379
          },
          "2": {
            "get_star_ts": 1641441866
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639449768
          },
          "2": {
            "get_star_ts": 1639449961
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639612793
          },
          "2": {
            "get_star_ts": 1639703447
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1641532831
          },
          "2": {
            "get_star_ts": 1641535870
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1641692296
          },
          "2": {
            "get_star_ts": 1641703590
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1640747747
          },
          "2": {
            "get_star_ts": 1640847734
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640408324
          },
          "2": {
            "get_star_ts": 1640408404
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640106416
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640571925
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1641422117
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640563902
          }
        }
      },
      "last_star_ts": 1641703590
    },
    "188251": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638416310
          },
          "2": {
            "get_star_ts": 1638417908
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638422457
          },
          "2": {
            "get_star_ts": 1638422628
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508462
          },
          "2": {
            "get_star_ts": 1638510084
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638634371
          },
          "2": {
            "get_star_ts": 1638636177
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638818854
          },
          "2": {
            "get_star_ts": 1638819827
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638820635
          },
          "2": {
            "get_star_ts": 1638835367
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638856708
          },
          "2": {
            "get_star_ts": 1638857615
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638941323
          },
          "2": {
            "get_star_ts": 1638947518
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639027382
          },
          "2": {
            "get_star_ts": 1639030248
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639115634
          },
          "2": {
            "get_star_ts": 1639116359
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639203067
          },
          "2": {
            "get_star_ts": 1639204023
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639292220
          },
          "2": {
            "get_star_ts": 1639293043
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639374022
          },
          "2": {
            "get_star_ts": 1639375113
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639461552
          },
          "2": {
            "get_star_ts": 1639501517
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639554435
          },
          "2": {
            "get_star_ts": 1639623798
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639709051
          },
          "2": {
            "get_star_ts": 1639714412
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639720682
          },
          "2": {
            "get_star_ts": 1639723219
          }
        }
      },
      "last_star_ts": 1639723219,
      "stars": 34,
      "local_score": 4144,
      "id": "188251",
      "name": "Basil Udoudoh",
      "global_score": 0
    },
    "188257": {
      "stars": 32,
      "name": "G. Hamilton Evans",
      "local_score": 3756,
      "id": "188257",
      "global_score": 0,
      "last_star_ts": 1639749076,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638362504
          },
          "2": {
            "get_star_ts": 1638363926
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638448809
          },
          "2": {
            "get_star_ts": 1638448975
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638535088
          },
          "2": {
            "get_star_ts": 1638536617
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638635865
          },
          "2": {
            "get_star_ts": 1638638834
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638739817
          },
          "2": {
            "get_star_ts": 1638742180
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638789860
          },
          "2": {
            "get_star_ts": 1638791950
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638877730
          },
          "2": {
            "get_star_ts": 1638878087
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639065831
          },
          "2": {
            "get_star_ts": 1639078615
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639139067
          },
          "2": {
            "get_star_ts": 1639144024
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639145478
          },
          "2": {
            "get_star_ts": 1639146459
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639233450
          },
          "2": {
            "get_star_ts": 1639233700
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639420000
          },
          "2": {
            "get_star_ts": 1639422438
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639434740
          },
          "2": {
            "get_star_ts": 1639435005
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639487180
          },
          "2": {
            "get_star_ts": 1639501449
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639572779
          },
          "2": {
            "get_star_ts": 1639575997
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639748604
          },
          "2": {
            "get_star_ts": 1639749076
          }
        }
      }
    },
    "190090": {
      "last_star_ts": 1640028150,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638377293
          },
          "2": {
            "get_star_ts": 1638378482
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638454316
          },
          "2": {
            "get_star_ts": 1638454866
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638552370
          },
          "2": {
            "get_star_ts": 1638556159
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638633860
          },
          "2": {
            "get_star_ts": 1638636627
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638717798
          },
          "2": {
            "get_star_ts": 1638718107
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638806006
          },
          "2": {
            "get_star_ts": 1638816078
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638886886
          },
          "2": {
            "get_star_ts": 1638887223
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639068679
          },
          "2": {
            "get_star_ts": 1639091941
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1640017217
          },
          "2": {
            "get_star_ts": 1640021277
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1640026006
          },
          "2": {
            "get_star_ts": 1640028150
          }
        }
      },
      "local_score": 2022,
      "stars": 20,
      "name": "Scott Abelmann",
      "id": "190090",
      "global_score": 0
    },
    "191395": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "global_score": 0,
      "stars": 0,
      "name": "Ted Hunter",
      "local_score": 0,
      "id": "191395"
    },
    "225384": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638374321
          },
          "2": {
            "get_star_ts": 1638374698
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638421606
          },
          "2": {
            "get_star_ts": 1638421726
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508167
          }
        }
      },
      "last_star_ts": 1638508167,
      "global_score": 0,
      "local_score": 583,
      "stars": 5,
      "id": "225384",
      "name": "Shayan Pirani"
    },
    "250536": {
      "name": "Jeremy Lizza",
      "stars": 36,
      "local_score": 4767,
      "id": "250536",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335004
          },
          "2": {
            "get_star_ts": 1638335197
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638421428
          },
          "2": {
            "get_star_ts": 1638421591
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508386
          },
          "2": {
            "get_star_ts": 1638510115
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638597151
          },
          "2": {
            "get_star_ts": 1638597372
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638682468
          },
          "2": {
            "get_star_ts": 1638683295
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638767544
          },
          "2": {
            "get_star_ts": 1638768061
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638854472
          },
          "2": {
            "get_star_ts": 1638855033
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638940635
          },
          "2": {
            "get_star_ts": 1638946827
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639028310
          },
          "2": {
            "get_star_ts": 1639058716
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639142118
          },
          "2": {
            "get_star_ts": 1639142772
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639201761
          },
          "2": {
            "get_star_ts": 1639201923
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639313520
          },
          "2": {
            "get_star_ts": 1639316226
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639374253
          },
          "2": {
            "get_star_ts": 1639374365
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639498257
          },
          "2": {
            "get_star_ts": 1639498288
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639547188
          },
          "2": {
            "get_star_ts": 1639577363
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639637298
          },
          "2": {
            "get_star_ts": 1639638060
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639721096
          },
          "2": {
            "get_star_ts": 1639724328
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639892729
          },
          "2": {
            "get_star_ts": 1639893173
          }
        }
      },
      "last_star_ts": 1639893173
    },
    "254702": {
      "last_star_ts": 1640412741,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638366460
          },
          "2": {
            "get_star_ts": 1638366965
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638421502
          },
          "2": {
            "get_star_ts": 1638421673
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638543374
          },
          "2": {
            "get_star_ts": 1638545580
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638844452
          },
          "2": {
            "get_star_ts": 1638844488
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638849147
          },
          "2": {
            "get_star_ts": 1638849768
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638850674
          },
          "2": {
            "get_star_ts": 1638852647
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638853588
          },
          "2": {
            "get_star_ts": 1638853779
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638943368
          },
          "2": {
            "get_star_ts": 1638945363
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639028766
          },
          "2": {
            "get_star_ts": 1639033000
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639113570
          },
          "2": {
            "get_star_ts": 1639114229
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639200093
          },
          "2": {
            "get_star_ts": 1639200262
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639286448
          },
          "2": {
            "get_star_ts": 1639287902
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639506304
          },
          "2": {
            "get_star_ts": 1639506317
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639512026
          },
          "2": {
            "get_star_ts": 1639519202
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639587026
          },
          "2": {
            "get_star_ts": 1639587895
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639642254
          },
          "2": {
            "get_star_ts": 1639642824
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639725818
          },
          "2": {
            "get_star_ts": 1639729628
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640022568
          },
          "2": {
            "get_star_ts": 1640022806
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1640115399
          },
          "2": {
            "get_star_ts": 1640115672
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640009935
          },
          "2": {
            "get_star_ts": 1640010575
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640095778
          },
          "2": {
            "get_star_ts": 1640104053
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640179842
          },
          "2": {
            "get_star_ts": 1640215022
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640268002
          },
          "2": {
            "get_star_ts": 1640398305
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1640338515
          },
          "2": {
            "get_star_ts": 1640338629
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640412696
          },
          "2": {
            "get_star_ts": 1640412741
          }
        }
      },
      "global_score": 0,
      "name": "Brian O'Connor",
      "stars": 50,
      "local_score": 6200,
      "id": "254702"
    },
    "330030": {
      "global_score": 0,
      "stars": 38,
      "name": "Kevin Wright",
      "local_score": 4323,
      "id": "330030",
      "last_star_ts": 1641869001,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638386571
          },
          "2": {
            "get_star_ts": 1638387842
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638453704
          },
          "2": {
            "get_star_ts": 1638454179
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638542658
          },
          "2": {
            "get_star_ts": 1638560129
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638631267
          },
          "2": {
            "get_star_ts": 1638635564
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638753828
          },
          "2": {
            "get_star_ts": 1638754481
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638799585
          },
          "2": {
            "get_star_ts": 1638817974
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638883081
          },
          "2": {
            "get_star_ts": 1638883761
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638978379
          },
          "2": {
            "get_star_ts": 1639007979
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639060992
          },
          "2": {
            "get_star_ts": 1639062550
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639170166
          },
          "2": {
            "get_star_ts": 1639172445
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639251685
          },
          "2": {
            "get_star_ts": 1639252174
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639325031
          },
          "2": {
            "get_star_ts": 1639329040
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639406399
          },
          "2": {
            "get_star_ts": 1639406500
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639493082
          },
          "2": {
            "get_star_ts": 1639516343
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639683023
          },
          "2": {
            "get_star_ts": 1639795830
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639779777
          },
          "2": {
            "get_star_ts": 1639779953
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640022051
          },
          "2": {
            "get_star_ts": 1640022459
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640100916
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640201706
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1641606415
          },
          "2": {
            "get_star_ts": 1641869001
          }
        }
      }
    },
    "372160": {
      "local_score": 2037,
      "stars": 25,
      "name": "Alex Consunji",
      "id": "372160",
      "global_score": 0,
      "last_star_ts": 1640729389,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638366509
          },
          "2": {
            "get_star_ts": 1638368011
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1640024553
          },
          "2": {
            "get_star_ts": 1640025642
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1640034560
          },
          "2": {
            "get_star_ts": 1640100521
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1640270695
          },
          "2": {
            "get_star_ts": 1640271862
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1640280100
          },
          "2": {
            "get_star_ts": 1640285455
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639363509
          },
          "2": {
            "get_star_ts": 1639363541
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639366628
          },
          "2": {
            "get_star_ts": 1639400334
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639500506
          },
          "2": {
            "get_star_ts": 1639756682
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639840946
          },
          "2": {
            "get_star_ts": 1639861997
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639881604
          },
          "2": {
            "get_star_ts": 1639885312
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1640354911
          },
          "2": {
            "get_star_ts": 1640355865
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640714449
          },
          "2": {
            "get_star_ts": 1640722928
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640729389
          }
        }
      }
    },
    "372356": {
      "stars": 30,
      "local_score": 3803,
      "id": "372356",
      "name": "Shah Zafrani",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638339961
          },
          "2": {
            "get_star_ts": 1638366691
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638423132
          },
          "2": {
            "get_star_ts": 1638425826
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638511944
          },
          "2": {
            "get_star_ts": 1638514946
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638606027
          },
          "2": {
            "get_star_ts": 1638607398
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638686969
          },
          "2": {
            "get_star_ts": 1638770247
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638768255
          },
          "2": {
            "get_star_ts": 1638768282
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638853822
          },
          "2": {
            "get_star_ts": 1638853990
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638940785
          },
          "2": {
            "get_star_ts": 1638946589
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639030326
          },
          "2": {
            "get_star_ts": 1639076653
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639114473
          },
          "2": {
            "get_star_ts": 1639115463
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639201749
          },
          "2": {
            "get_star_ts": 1639202291
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639289003
          },
          "2": {
            "get_star_ts": 1639291586
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639374513
          },
          "2": {
            "get_star_ts": 1639374685
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639460173
          },
          "2": {
            "get_star_ts": 1639595392
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639629717
          },
          "2": {
            "get_star_ts": 1639631748
          }
        }
      },
      "last_star_ts": 1639631748
    },
    "372360": {
      "global_score": 0,
      "stars": 50,
      "local_score": 6208,
      "id": "372360",
      "name": "Corey Lamb",
      "last_star_ts": 1640630546,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638367217
          },
          "2": {
            "get_star_ts": 1638367598
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638454774
          },
          "2": {
            "get_star_ts": 1638454936
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638540806
          },
          "2": {
            "get_star_ts": 1638544333
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638624166
          },
          "2": {
            "get_star_ts": 1638624761
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638716161
          },
          "2": {
            "get_star_ts": 1638719072
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638796326
          },
          "2": {
            "get_star_ts": 1638797211
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638853594
          },
          "2": {
            "get_star_ts": 1638853895
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638973544
          },
          "2": {
            "get_star_ts": 1638982150
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639058968
          },
          "2": {
            "get_star_ts": 1639061306
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639145199
          },
          "2": {
            "get_star_ts": 1639147730
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639233946
          },
          "2": {
            "get_star_ts": 1639234233
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639316347
          },
          "2": {
            "get_star_ts": 1639318263
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639401856
          },
          "2": {
            "get_star_ts": 1639402119
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639496507
          },
          "2": {
            "get_star_ts": 1639502683
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639581120
          },
          "2": {
            "get_star_ts": 1639602200
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639683687
          },
          "2": {
            "get_star_ts": 1639707925
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639751878
          },
          "2": {
            "get_star_ts": 1639752298
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639971149
          },
          "2": {
            "get_star_ts": 1639972142
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1640117035
          },
          "2": {
            "get_star_ts": 1640117918
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640027943
          },
          "2": {
            "get_star_ts": 1640028032
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640089938
          },
          "2": {
            "get_star_ts": 1640102790
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640180494
          },
          "2": {
            "get_star_ts": 1640291007
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640294830
          },
          "2": {
            "get_star_ts": 1640302186
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1640389251
          },
          "2": {
            "get_star_ts": 1640389410
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640630542
          },
          "2": {
            "get_star_ts": 1640630546
          }
        }
      }
    },
    "372372": {
      "stars": 0,
      "name": "Griffin Jones",
      "local_score": 0,
      "id": "372372",
      "global_score": 0,
      "last_star_ts": "0",
      "completion_day_level": {}
    },
    "372419": {
      "global_score": 0,
      "local_score": 1386,
      "stars": 15,
      "name": "Kevin Campbell",
      "id": "372419",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638362986
          },
          "2": {
            "get_star_ts": 1638364929
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638449677
          },
          "2": {
            "get_star_ts": 1638450158
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638538505
          },
          "2": {
            "get_star_ts": 1638573306
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638678591
          },
          "2": {
            "get_star_ts": 1639021284
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639193427
          },
          "2": {
            "get_star_ts": 1639361218
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639401773
          },
          "2": {
            "get_star_ts": 1640291441
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1640234223
          },
          "2": {
            "get_star_ts": 1640270466
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1640319006
          }
        }
      },
      "last_star_ts": 1640319006
    },
    "372430": {
      "last_star_ts": 1638567962,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638565042
          },
          "2": {
            "get_star_ts": 1638565566
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638567962
          }
        }
      },
      "global_score": 0,
      "id": "372430",
      "stars": 3,
      "local_score": 171,
      "name": "Carrie Flaspohler"
    },
    "375289": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638376507
          },
          "2": {
            "get_star_ts": 1638379485
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638461786
          },
          "2": {
            "get_star_ts": 1638466248
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638552924
          },
          "2": {
            "get_star_ts": 1638634004
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638732397
          },
          "2": {
            "get_star_ts": 1638820393
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638915113
          },
          "2": {
            "get_star_ts": 1638979113
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638982229
          },
          "2": {
            "get_star_ts": 1639090963
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639158426
          },
          "2": {
            "get_star_ts": 1639159614
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639176131
          },
          "2": {
            "get_star_ts": 1639206132
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639234889
          },
          "2": {
            "get_star_ts": 1639339191
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639373050
          },
          "2": {
            "get_star_ts": 1639412144
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639504351
          },
          "2": {
            "get_star_ts": 1639507561
          }
        }
      },
      "last_star_ts": 1639507561,
      "global_score": 0,
      "name": "jhahn1",
      "stars": 22,
      "local_score": 2006,
      "id": "375289"
    },
    "453980": {
      "global_score": 0,
      "stars": 36,
      "local_score": 3338,
      "name": "Scott Hankinson",
      "id": "453980",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638455609
          },
          "2": {
            "get_star_ts": 1638455790
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638471305
          },
          "2": {
            "get_star_ts": 1638471470
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638895892
          },
          "2": {
            "get_star_ts": 1638897513
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638902021
          },
          "2": {
            "get_star_ts": 1638904503
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1640012703
          },
          "2": {
            "get_star_ts": 1640013902
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1640014938
          },
          "2": {
            "get_star_ts": 1640015658
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1640016842
          },
          "2": {
            "get_star_ts": 1640017075
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1640019330
          },
          "2": {
            "get_star_ts": 1640024047
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1640026431
          },
          "2": {
            "get_star_ts": 1640027569
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1640096813
          },
          "2": {
            "get_star_ts": 1640097784
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1640099681
          },
          "2": {
            "get_star_ts": 1640099850
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1640105465
          },
          "2": {
            "get_star_ts": 1640106628
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640122330
          },
          "2": {
            "get_star_ts": 1640122734
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640124198
          },
          "2": {
            "get_star_ts": 1640153189
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1640199049
          },
          "2": {
            "get_star_ts": 1640201201
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1640208849
          },
          "2": {
            "get_star_ts": 1640277699
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1640286579
          },
          "2": {
            "get_star_ts": 1640286802
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640357991
          },
          "2": {
            "get_star_ts": 1640358290
          }
        }
      },
      "last_star_ts": 1640358290
    },
    "456457": {
      "global_score": 0,
      "stars": 34,
      "name": "Matt Collins",
      "local_score": 3747,
      "id": "456457",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638364348
          },
          "2": {
            "get_star_ts": 1638364873
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638447420
          },
          "2": {
            "get_star_ts": 1638448548
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638540562
          },
          "2": {
            "get_star_ts": 1638561656
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638634944
          },
          "2": {
            "get_star_ts": 1638643345
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638716893
          },
          "2": {
            "get_star_ts": 1638729351
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638798912
          },
          "2": {
            "get_star_ts": 1638841853
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638885286
          },
          "2": {
            "get_star_ts": 1638885941
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638975746
          },
          "2": {
            "get_star_ts": 1638997924
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639266218
          },
          "2": {
            "get_star_ts": 1639272739
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639275060
          },
          "2": {
            "get_star_ts": 1639276331
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639670275
          },
          "2": {
            "get_star_ts": 1639671501
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1642181341
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639447123
          },
          "2": {
            "get_star_ts": 1639447997
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639872861
          },
          "2": {
            "get_star_ts": 1639873103
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639842270
          },
          "2": {
            "get_star_ts": 1639850795
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639882119
          },
          "2": {
            "get_star_ts": 1639882402
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1641420992
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1641584661
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1642188633
          }
        }
      },
      "last_star_ts": 1642188633
    },
    "484824": {
      "global_score": 0,
      "stars": 26,
      "local_score": 2219,
      "id": "484824",
      "name": "Donny Cheng",
      "last_star_ts": 1640896431,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638366963
          },
          "2": {
            "get_star_ts": 1638481685
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638483817
          },
          "2": {
            "get_star_ts": 1638567338
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638571089
          },
          "2": {
            "get_star_ts": 1638740777
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639023811
          },
          "2": {
            "get_star_ts": 1639194785
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639259513
          },
          "2": {
            "get_star_ts": 1639270048
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639627827
          },
          "2": {
            "get_star_ts": 1639772980
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639447878
          },
          "2": {
            "get_star_ts": 1639449304
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639861175
          },
          "2": {
            "get_star_ts": 1640118323
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1640203522
          },
          "2": {
            "get_star_ts": 1640277050
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1640291859
          },
          "2": {
            "get_star_ts": 1640380660
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1640463373
          },
          "2": {
            "get_star_ts": 1640464237
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1640807562
          },
          "2": {
            "get_star_ts": 1640896431
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640632792
          },
          "2": {
            "get_star_ts": 1640640089
          }
        }
      }
    },
    "496775": {
      "global_score": 0,
      "stars": 8,
      "local_score": 530,
      "id": "496775",
      "name": "Andrew B. McIntosh",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638639585
          },
          "2": {
            "get_star_ts": 1638640833
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638642396
          },
          "2": {
            "get_star_ts": 1638642702
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638645249
          },
          "2": {
            "get_star_ts": 1638831145
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638852690
          },
          "2": {
            "get_star_ts": 1638854473
          }
        }
      },
      "last_star_ts": 1638854473
    },
    "502805": {
      "global_score": 0,
      "stars": 50,
      "name": "Brenton Bazemore",
      "local_score": 6675,
      "id": "502805",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335108
          },
          "2": {
            "get_star_ts": 1638335375
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638421528
          },
          "2": {
            "get_star_ts": 1638421666
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508309
          },
          "2": {
            "get_star_ts": 1638510049
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638595819
          },
          "2": {
            "get_star_ts": 1638596458
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638731725
          },
          "2": {
            "get_star_ts": 1638732588
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638767538
          },
          "2": {
            "get_star_ts": 1638768405
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638854036
          },
          "2": {
            "get_star_ts": 1638854365
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638940574
          },
          "2": {
            "get_star_ts": 1638943790
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639026975
          },
          "2": {
            "get_star_ts": 1639028935
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639113361
          },
          "2": {
            "get_star_ts": 1639114037
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639200933
          },
          "2": {
            "get_star_ts": 1639201104
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639286734
          },
          "2": {
            "get_star_ts": 1639287708
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639373695
          },
          "2": {
            "get_star_ts": 1639373816
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639459079
          },
          "2": {
            "get_star_ts": 1639469744
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639549175
          },
          "2": {
            "get_star_ts": 1639553903
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639638504
          },
          "2": {
            "get_star_ts": 1639639840
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639719340
          },
          "2": {
            "get_star_ts": 1639720482
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639833425
          },
          "2": {
            "get_star_ts": 1639834016
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1639982869
          },
          "2": {
            "get_star_ts": 1639983477
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640016038
          },
          "2": {
            "get_star_ts": 1640016229
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640117191
          },
          "2": {
            "get_star_ts": 1640138806
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640150320
          },
          "2": {
            "get_star_ts": 1640249728
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640370050
          },
          "2": {
            "get_star_ts": 1641936101
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1641777677
          },
          "2": {
            "get_star_ts": 1641778833
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1641923730
          },
          "2": {
            "get_star_ts": 1641936109
          }
        }
      },
      "last_star_ts": 1641936109
    },
    "506833": {
      "last_star_ts": 1642035581,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639123757
          },
          "2": {
            "get_star_ts": 1639124699
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639126372
          },
          "2": {
            "get_star_ts": 1639128754
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639208468
          },
          "2": {
            "get_star_ts": 1639210253
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639678416
          },
          "2": {
            "get_star_ts": 1639680451
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639946509
          },
          "2": {
            "get_star_ts": 1639946621
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639916473
          },
          "2": {
            "get_star_ts": 1639919691
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639921522
          },
          "2": {
            "get_star_ts": 1639923527
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639942863
          },
          "2": {
            "get_star_ts": 1639946156
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639968471
          },
          "2": {
            "get_star_ts": 1639970342
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1640023211
          },
          "2": {
            "get_star_ts": 1640029922
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1640206091
          },
          "2": {
            "get_star_ts": 1640213344
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1640218571
          },
          "2": {
            "get_star_ts": 1640218655
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640614643
          },
          "2": {
            "get_star_ts": 1640617110
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640625639
          },
          "2": {
            "get_star_ts": 1640629078
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1640632444
          },
          "2": {
            "get_star_ts": 1640635097
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1640643456
          },
          "2": {
            "get_star_ts": 1640643718
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1640646894
          },
          "2": {
            "get_star_ts": 1640782383
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640647712
          },
          "2": {
            "get_star_ts": 1640782540
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1641986622
          },
          "2": {
            "get_star_ts": 1641986632
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640782503
          },
          "2": {
            "get_star_ts": 1641088576
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640647994
          },
          "2": {
            "get_star_ts": 1641917339
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1641088816
          },
          "2": {
            "get_star_ts": 1641244379
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1641247615
          },
          "2": {
            "get_star_ts": 1641908373
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1642007994
          },
          "2": {
            "get_star_ts": 1642035555
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1641090140
          },
          "2": {
            "get_star_ts": 1642035581
          }
        }
      },
      "global_score": 0,
      "stars": 50,
      "id": "506833",
      "local_score": 4945,
      "name": "Carl Newton"
    },
    "542205": {
      "last_star_ts": 1639771476,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638381496
          },
          "2": {
            "get_star_ts": 1638382196
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639764068
          },
          "2": {
            "get_star_ts": 1639764578
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639771476
          }
        }
      },
      "stars": 5,
      "id": "542205",
      "local_score": 285,
      "name": "Jeffrey Box",
      "global_score": 0
    },
    "619828": {
      "stars": 27,
      "local_score": 2936,
      "name": "Raz-SB",
      "id": "619828",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638371912
          },
          "2": {
            "get_star_ts": 1638374757
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638453369
          },
          "2": {
            "get_star_ts": 1638455432
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638513282
          },
          "2": {
            "get_star_ts": 1638524569
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638604821
          },
          "2": {
            "get_star_ts": 1638607031
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638773896
          },
          "2": {
            "get_star_ts": 1638776437
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638819937
          },
          "2": {
            "get_star_ts": 1638868690
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638868785
          },
          "2": {
            "get_star_ts": 1638880733
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638943358
          },
          "2": {
            "get_star_ts": 1639025269
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639045508
          },
          "2": {
            "get_star_ts": 1639201676
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639348918
          },
          "2": {
            "get_star_ts": 1639370303
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639377675
          },
          "2": {
            "get_star_ts": 1639378162
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639696651
          },
          "2": {
            "get_star_ts": 1639749854
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639839159
          },
          "2": {
            "get_star_ts": 1639839677
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639850120
          }
        }
      },
      "last_star_ts": 1639850120
    },
    "621636": {
      "global_score": 0,
      "stars": 11,
      "local_score": 790,
      "name": "Jordan Ivy",
      "id": "621636",
      "last_star_ts": 1642115822,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638347480
          },
          "2": {
            "get_star_ts": 1638350935
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638849419
          },
          "2": {
            "get_star_ts": 1638849898
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639008321
          },
          "2": {
            "get_star_ts": 1639443936
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1640137704
          },
          "2": {
            "get_star_ts": 1640202517
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641530729
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641929487
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1642115822
          }
        }
      }
    },
    "621657": {
      "id": "621657",
      "stars": 0,
      "local_score": 0,
      "name": "Walt Austin",
      "global_score": 0,
      "completion_day_level": {},
      "last_star_ts": "0"
    },
    "621765": {
      "global_score": 0,
      "local_score": 522,
      "stars": 10,
      "id": "621765",
      "name": "brandon-myers-009",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638941070
          },
          "2": {
            "get_star_ts": 1638943849
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639024212
          },
          "2": {
            "get_star_ts": 1639025250
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639458365
          },
          "2": {
            "get_star_ts": 1640390513
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1641266861
          },
          "2": {
            "get_star_ts": 1641277156
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641573931
          },
          "2": {
            "get_star_ts": 1642119958
          }
        }
      },
      "last_star_ts": 1642119958
    },
    "622753": {
      "completion_day_level": {},
      "last_star_ts": "0",
      "local_score": 0,
      "stars": 0,
      "name": "Larkin Blake",
      "id": "622753",
      "global_score": 0
    },
    "623353": {
      "last_star_ts": 1639228233,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638365229
          },
          "2": {
            "get_star_ts": 1638376127
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638449877
          },
          "2": {
            "get_star_ts": 1638452085
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638534419
          },
          "2": {
            "get_star_ts": 1638545416
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638708620
          },
          "2": {
            "get_star_ts": 1638711868
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638721235
          },
          "2": {
            "get_star_ts": 1638731466
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638797349
          },
          "2": {
            "get_star_ts": 1638803090
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639053529
          },
          "2": {
            "get_star_ts": 1639054114
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639080245
          },
          "2": {
            "get_star_ts": 1639091611
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639056925
          },
          "2": {
            "get_star_ts": 1639068127
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639159841
          },
          "2": {
            "get_star_ts": 1639228233
          }
        }
      },
      "global_score": 0,
      "stars": 20,
      "local_score": 2143,
      "name": "Chad McKee",
      "id": "623353"
    },
    "625478": {
      "global_score": 0,
      "local_score": 6220,
      "stars": 50,
      "name": "Alexander Lyons",
      "id": "625478",
      "last_star_ts": 1641098555,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638360914
          },
          "2": {
            "get_star_ts": 1638361416
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638449679
          },
          "2": {
            "get_star_ts": 1638449842
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638538992
          },
          "2": {
            "get_star_ts": 1638540203
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638625407
          },
          "2": {
            "get_star_ts": 1638625581
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638709844
          },
          "2": {
            "get_star_ts": 1638710546
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638794297
          },
          "2": {
            "get_star_ts": 1638794321
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638880922
          },
          "2": {
            "get_star_ts": 1638881849
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638968165
          },
          "2": {
            "get_star_ts": 1638974407
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639053380
          },
          "2": {
            "get_star_ts": 1639055437
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639139679
          },
          "2": {
            "get_star_ts": 1639140505
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639227186
          },
          "2": {
            "get_star_ts": 1639227438
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639315061
          },
          "2": {
            "get_star_ts": 1639328914
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639401677
          },
          "2": {
            "get_star_ts": 1639402393
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639485899
          },
          "2": {
            "get_star_ts": 1639495871
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639577910
          },
          "2": {
            "get_star_ts": 1639581931
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639666385
          },
          "2": {
            "get_star_ts": 1639668473
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639788542
          },
          "2": {
            "get_star_ts": 1639791466
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640989946
          },
          "2": {
            "get_star_ts": 1640990434
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1641008145
          },
          "2": {
            "get_star_ts": 1641009246
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640982867
          },
          "2": {
            "get_star_ts": 1640982891
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640358480
          },
          "2": {
            "get_star_ts": 1640981064
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640805599
          },
          "2": {
            "get_star_ts": 1640805687
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1641065230
          },
          "2": {
            "get_star_ts": 1641098546
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1640913492
          },
          "2": {
            "get_star_ts": 1640916945
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640955589
          },
          "2": {
            "get_star_ts": 1641098555
          }
        }
      }
    },
    "625481": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639249487
          },
          "2": {
            "get_star_ts": 1639265385
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639250450
          },
          "2": {
            "get_star_ts": 1639252287
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639251706
          },
          "2": {
            "get_star_ts": 1641262179
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1641265254
          },
          "2": {
            "get_star_ts": 1641776644
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639264519
          },
          "2": {
            "get_star_ts": 1639264881
          }
        }
      },
      "last_star_ts": 1641776644,
      "id": "625481",
      "stars": 10,
      "local_score": 599,
      "name": "Annie Kong",
      "global_score": 0
    },
    "625603": {
      "stars": 12,
      "name": "Sierra Wulfson",
      "local_score": 749,
      "id": "625603",
      "global_score": 0,
      "last_star_ts": 1641577974,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638914748
          },
          "2": {
            "get_star_ts": 1638915734
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638916850
          },
          "2": {
            "get_star_ts": 1638917557
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638999991
          },
          "2": {
            "get_star_ts": 1639006391
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1641426762
          },
          "2": {
            "get_star_ts": 1641431138
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641577062
          },
          "2": {
            "get_star_ts": 1641577974
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1641573941
          },
          "2": {
            "get_star_ts": 1641574631
          }
        }
      }
    },
    "627414": {
      "last_star_ts": 1641829263,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335379
          },
          "2": {
            "get_star_ts": 1638335851
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638422483
          },
          "2": {
            "get_star_ts": 1638422955
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638511219
          },
          "2": {
            "get_star_ts": 1638514179
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638676557
          },
          "2": {
            "get_star_ts": 1638763580
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638769887
          },
          "2": {
            "get_star_ts": 1639177210
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639180773
          },
          "2": {
            "get_star_ts": 1639191029
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639193451
          },
          "2": {
            "get_star_ts": 1639331394
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639345068
          },
          "2": {
            "get_star_ts": 1639383854
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639718032
          },
          "2": {
            "get_star_ts": 1639846357
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639865588
          },
          "2": {
            "get_star_ts": 1639866928
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639945664
          },
          "2": {
            "get_star_ts": 1639947554
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1640057585
          },
          "2": {
            "get_star_ts": 1640285442
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640551440
          },
          "2": {
            "get_star_ts": 1640675042
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640721155
          },
          "2": {
            "get_star_ts": 1640805555
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1641261663
          },
          "2": {
            "get_star_ts": 1641330885
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1641436561
          },
          "2": {
            "get_star_ts": 1641588561
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1641685275
          },
          "2": {
            "get_star_ts": 1641706688
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1641766066
          },
          "2": {
            "get_star_ts": 1641829263
          }
        }
      },
      "local_score": 3775,
      "stars": 36,
      "id": "627414",
      "name": "jocago",
      "global_score": 0
    },
    "712223": {
      "completion_day_level": {},
      "last_star_ts": "0",
      "local_score": 0,
      "stars": 0,
      "name": "Jackson Powell",
      "id": "712223",
      "global_score": 0
    },
    "712873": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638337435
          },
          "2": {
            "get_star_ts": 1638338077
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638422817
          },
          "2": {
            "get_star_ts": 1638424869
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638512838
          },
          "2": {
            "get_star_ts": 1638565161
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638598097
          },
          "2": {
            "get_star_ts": 1638675166
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638686141
          },
          "2": {
            "get_star_ts": 1638763028
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638770116
          },
          "2": {
            "get_star_ts": 1638774009
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638914160
          },
          "2": {
            "get_star_ts": 1638914634
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638944271
          },
          "2": {
            "get_star_ts": 1640059978
          }
        }
      },
      "last_star_ts": 1640059978,
      "global_score": 0,
      "local_score": 1854,
      "stars": 16,
      "id": "712873",
      "name": "dr-sly"
    },
    "761762": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638337294
          },
          "2": {
            "get_star_ts": 1638337438
          }
        }
      },
      "last_star_ts": 1638337438,
      "global_score": 0,
      "stars": 2,
      "local_score": 251,
      "id": "761762",
      "name": "Lance Grady"
    },
    "779224": {
      "last_star_ts": 1640202886,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638416170
          },
          "2": {
            "get_star_ts": 1638423942
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638497448
          },
          "2": {
            "get_star_ts": 1638497763
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638545884
          },
          "2": {
            "get_star_ts": 1638548232
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638647561
          },
          "2": {
            "get_star_ts": 1638648134
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638725703
          },
          "2": {
            "get_star_ts": 1638727478
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638801196
          },
          "2": {
            "get_star_ts": 1638804992
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638886327
          },
          "2": {
            "get_star_ts": 1638886463
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638973830
          },
          "2": {
            "get_star_ts": 1638982413
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639055734
          },
          "2": {
            "get_star_ts": 1639060225
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639114102
          },
          "2": {
            "get_star_ts": 1639114950
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639230634
          },
          "2": {
            "get_star_ts": 1639231092
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639291942
          },
          "2": {
            "get_star_ts": 1639293064
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639374685
          },
          "2": {
            "get_star_ts": 1639374792
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639459332
          },
          "2": {
            "get_star_ts": 1639492159
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639630820
          },
          "2": {
            "get_star_ts": 1639634007
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639806152
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1640052003
          },
          "2": {
            "get_star_ts": 1640055445
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640201748
          },
          "2": {
            "get_star_ts": 1640202886
          }
        }
      },
      "global_score": 0,
      "name": "G V",
      "stars": 35,
      "local_score": 3965,
      "id": "779224"
    },
    "786302": {
      "global_score": 0,
      "name": "Keyla Herrera Ruiz",
      "stars": 14,
      "local_score": 1256,
      "id": "786302",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638394575
          },
          "2": {
            "get_star_ts": 1638395315
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638455750
          },
          "2": {
            "get_star_ts": 1638456293
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638542168
          },
          "2": {
            "get_star_ts": 1638545680
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638835577
          },
          "2": {
            "get_star_ts": 1638835778
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638919142
          },
          "2": {
            "get_star_ts": 1638920361
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638921362
          },
          "2": {
            "get_star_ts": 1639062464
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639064028
          },
          "2": {
            "get_star_ts": 1639065837
          }
        }
      },
      "last_star_ts": 1639065837
    },
    "793969": {
      "global_score": 0,
      "stars": 17,
      "local_score": 1952,
      "name": "Clayseph",
      "id": "793969",
      "last_star_ts": 1639068187,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638363784
          },
          "2": {
            "get_star_ts": 1638364953
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638448523
          },
          "2": {
            "get_star_ts": 1638449210
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638536597
          },
          "2": {
            "get_star_ts": 1638538497
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638677910
          },
          "2": {
            "get_star_ts": 1638679885
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638711771
          },
          "2": {
            "get_star_ts": 1638713058
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638803199
          },
          "2": {
            "get_star_ts": 1638803225
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638882091
          },
          "2": {
            "get_star_ts": 1638883174
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638969394
          },
          "2": {
            "get_star_ts": 1638981821
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639068187
          }
        }
      }
    },
    "846455": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638411238
          },
          "2": {
            "get_star_ts": 1638416666
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638500295
          },
          "2": {
            "get_star_ts": 1638501512
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638554058
          }
        }
      },
      "last_star_ts": 1638554058,
      "local_score": 342,
      "stars": 5,
      "name": "awetstone56",
      "id": "846455",
      "global_score": 0
    },
    "850561": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638566423
          },
          "2": {
            "get_star_ts": 1638566824
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638567719
          },
          "2": {
            "get_star_ts": 1638567993
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638582616
          },
          "2": {
            "get_star_ts": 1638809997
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638877239
          },
          "2": {
            "get_star_ts": 1638877255
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641659268
          },
          "2": {
            "get_star_ts": 1641659484
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641660949
          },
          "2": {
            "get_star_ts": 1641661010
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641661668
          },
          "2": {
            "get_star_ts": 1641662485
          }
        }
      },
      "last_star_ts": 1641662485,
      "global_score": 0,
      "local_score": 958,
      "stars": 14,
      "name": "Michael Gross",
      "id": "850561"
    },
    "856597": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335307
          },
          "2": {
            "get_star_ts": 1638335988
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638421758
          },
          "2": {
            "get_star_ts": 1638421974
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638542734
          },
          "2": {
            "get_star_ts": 1638544719
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638804415
          },
          "2": {
            "get_star_ts": 1638805860
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638820204
          },
          "2": {
            "get_star_ts": 1638827505
          }
        }
      },
      "last_star_ts": 1638827505,
      "global_score": 0,
      "stars": 10,
      "name": "Zach Guthrie",
      "local_score": 1111,
      "id": "856597"
    },
    "876817": {
      "last_star_ts": 1639347679,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638365269
          },
          "2": {
            "get_star_ts": 1638365950
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638483798
          },
          "2": {
            "get_star_ts": 1638484156
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638541603
          },
          "2": {
            "get_star_ts": 1638796663
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638923076
          },
          "2": {
            "get_star_ts": 1638923140
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638925762
          },
          "2": {
            "get_star_ts": 1639008484
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638927997
          },
          "2": {
            "get_star_ts": 1638930135
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638914617
          },
          "2": {
            "get_star_ts": 1638915267
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638972667
          },
          "2": {
            "get_star_ts": 1639166585
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639347679
          }
        }
      },
      "local_score": 1557,
      "stars": 17,
      "id": "876817",
      "name": "Jessica Jestings",
      "global_score": 0
    },
    "953447": {
      "last_star_ts": 1640409371,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335590
          },
          "2": {
            "get_star_ts": 1638336339
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638421835
          },
          "2": {
            "get_star_ts": 1638422179
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508136
          },
          "2": {
            "get_star_ts": 1638509201
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638596211
          },
          "2": {
            "get_star_ts": 1638597738
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638762474
          },
          "2": {
            "get_star_ts": 1638763262
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638767462
          },
          "2": {
            "get_star_ts": 1638768176
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638854098
          },
          "2": {
            "get_star_ts": 1638854349
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638941547
          },
          "2": {
            "get_star_ts": 1638942849
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639026518
          },
          "2": {
            "get_star_ts": 1639032097
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639113557
          },
          "2": {
            "get_star_ts": 1639114645
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639202530
          },
          "2": {
            "get_star_ts": 1639202809
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639286909
          },
          "2": {
            "get_star_ts": 1639287564
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639374776
          },
          "2": {
            "get_star_ts": 1639376816
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639460756
          },
          "2": {
            "get_star_ts": 1639460896
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639548139
          },
          "2": {
            "get_star_ts": 1639550896
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639635842
          },
          "2": {
            "get_star_ts": 1639639526
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639719297
          },
          "2": {
            "get_star_ts": 1639719904
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639815118
          },
          "2": {
            "get_star_ts": 1639815377
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1640130088
          },
          "2": {
            "get_star_ts": 1640131786
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1639980193
          },
          "2": {
            "get_star_ts": 1639981183
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640063924
          },
          "2": {
            "get_star_ts": 1640138875
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640150873
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640240333
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1640330264
          },
          "2": {
            "get_star_ts": 1640333934
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640409371
          }
        }
      },
      "global_score": 0,
      "stars": 47,
      "local_score": 6252,
      "id": "953447",
      "name": "Austin Leal"
    },
    "953450": {
      "last_star_ts": 1640406908,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638336086
          },
          "2": {
            "get_star_ts": 1638336412
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638424424
          },
          "2": {
            "get_star_ts": 1638424966
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638544559
          },
          "2": {
            "get_star_ts": 1638550434
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638618464
          },
          "2": {
            "get_star_ts": 1638622808
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638722694
          },
          "2": {
            "get_star_ts": 1638733749
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638768546
          },
          "2": {
            "get_star_ts": 1638768587
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638853796
          },
          "2": {
            "get_star_ts": 1638854258
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638940515
          },
          "2": {
            "get_star_ts": 1638976389
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639065250
          },
          "2": {
            "get_star_ts": 1639067087
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639145990
          },
          "2": {
            "get_star_ts": 1639146396
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639280042
          },
          "2": {
            "get_star_ts": 1639280287
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639323757
          },
          "2": {
            "get_star_ts": 1639332202
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639414023
          },
          "2": {
            "get_star_ts": 1639415075
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639484631
          },
          "2": {
            "get_star_ts": 1639501755
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639550608
          },
          "2": {
            "get_star_ts": 1639619527
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639670251
          },
          "2": {
            "get_star_ts": 1639672334
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639759850
          },
          "2": {
            "get_star_ts": 1639765026
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639884791
          },
          "2": {
            "get_star_ts": 1639886109
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1640052568
          },
          "2": {
            "get_star_ts": 1640054057
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640060549
          },
          "2": {
            "get_star_ts": 1640125350
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640094920
          },
          "2": {
            "get_star_ts": 1640123629
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640270620
          }
        },
        "24": {
          "1": {
            "get_star_ts": 1640406294
          },
          "2": {
            "get_star_ts": 1640406908
          }
        }
      },
      "global_score": 0,
      "stars": 45,
      "local_score": 5580,
      "id": "953450",
      "name": "Edd Purcell"
    },
    "953492": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638813000
          },
          "2": {
            "get_star_ts": 1642094480
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1642095398
          },
          "2": {
            "get_star_ts": 1642095643
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1642097153
          },
          "2": {
            "get_star_ts": 1642098490
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1642099881
          }
        }
      },
      "last_star_ts": 1642099881,
      "name": "AJ John",
      "stars": 7,
      "local_score": 285,
      "id": "953492",
      "global_score": 0
    },
    "953508": {
      "last_star_ts": 1642213152,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638382962
          },
          "2": {
            "get_star_ts": 1638383507
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638457061
          },
          "2": {
            "get_star_ts": 1638458758
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638542682
          },
          "2": {
            "get_star_ts": 1642197948
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1642201535
          },
          "2": {
            "get_star_ts": 1642210194
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1642212025
          },
          "2": {
            "get_star_ts": 1642213152
          }
        }
      },
      "global_score": 0,
      "stars": 10,
      "local_score": 692,
      "id": "953508",
      "name": "Kendra B"
    },
    "953591": {
      "stars": 5,
      "name": "Diane Nguyen",
      "local_score": 270,
      "id": "953591",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638844683
          },
          "2": {
            "get_star_ts": 1638845501
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638845898
          },
          "2": {
            "get_star_ts": 1638846453
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638847553
          }
        }
      },
      "last_star_ts": 1638847553
    },
    "953633": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1640191354
          },
          "2": {
            "get_star_ts": 1640191643
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1640193083
          },
          "2": {
            "get_star_ts": 1640205019
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1640202284
          },
          "2": {
            "get_star_ts": 1640205301
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1641186947
          },
          "2": {
            "get_star_ts": 1641187167
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641187050
          },
          "2": {
            "get_star_ts": 1641187180
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641187091
          },
          "2": {
            "get_star_ts": 1641187205
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641187145
          },
          "2": {
            "get_star_ts": 1641187221
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1641187362
          },
          "2": {
            "get_star_ts": 1641567728
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1641187412
          },
          "2": {
            "get_star_ts": 1641567743
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1641187451
          },
          "2": {
            "get_star_ts": 1641567767
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1641568324
          },
          "2": {
            "get_star_ts": 1641568417
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1641568293
          },
          "2": {
            "get_star_ts": 1641568462
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1641568216
          },
          "2": {
            "get_star_ts": 1641568525
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1641568090
          },
          "2": {
            "get_star_ts": 1641568542
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1641568049
          },
          "2": {
            "get_star_ts": 1641568554
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1641569259
          },
          "2": {
            "get_star_ts": 1641569929
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1641569338
          },
          "2": {
            "get_star_ts": 1641569968
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1641569878
          },
          "2": {
            "get_star_ts": 1641570057
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1641569771
          },
          "2": {
            "get_star_ts": 1641569848
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1641569547
          }
        }
      },
      "last_star_ts": 1641570057,
      "stars": 39,
      "name": "Samia Khan",
      "local_score": 3474,
      "id": "953633",
      "global_score": 0
    },
    "955279": {
      "last_star_ts": 1642134220,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638364739
          },
          "2": {
            "get_star_ts": 1638365298
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638424902
          },
          "2": {
            "get_star_ts": 1638425389
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638555122
          },
          "2": {
            "get_star_ts": 1638556449
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638655427
          },
          "2": {
            "get_star_ts": 1638656624
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638682772
          },
          "2": {
            "get_star_ts": 1638683671
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638798809
          },
          "2": {
            "get_star_ts": 1638815885
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638853589
          },
          "2": {
            "get_star_ts": 1638854034
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638988043
          },
          "2": {
            "get_star_ts": 1639012094
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639029513
          },
          "2": {
            "get_star_ts": 1639115356
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639199724
          },
          "2": {
            "get_star_ts": 1639236047
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639362187
          },
          "2": {
            "get_star_ts": 1639362451
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639455119
          },
          "2": {
            "get_star_ts": 1639460080
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639486926
          },
          "2": {
            "get_star_ts": 1639519430
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639583844
          },
          "2": {
            "get_star_ts": 1639630338
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639971330
          },
          "2": {
            "get_star_ts": 1639972732
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1640058773
          },
          "2": {
            "get_star_ts": 1640091215
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1640093240
          },
          "2": {
            "get_star_ts": 1640093531
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1641785521
          },
          "2": {
            "get_star_ts": 1641822315
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1641873720
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1642132379
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1642134143
          },
          "2": {
            "get_star_ts": 1642134220
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1642133331
          }
        }
      },
      "global_score": 0,
      "id": "955279",
      "stars": 41,
      "local_score": 4684,
      "name": "Neo Zhou"
    },
    "958502": {
      "global_score": 0,
      "stars": 6,
      "id": "958502",
      "local_score": 713,
      "name": "Eric Pass",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335835
          },
          "2": {
            "get_star_ts": 1638337304
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638423231
          },
          "2": {
            "get_star_ts": 1638423740
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638518512
          },
          "2": {
            "get_star_ts": 1638563128
          }
        }
      },
      "last_star_ts": 1638563128
    },
    "964573": {
      "stars": 11,
      "local_score": 1101,
      "id": "964573",
      "name": "Christian Curry",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638389406
          },
          "2": {
            "get_star_ts": 1638396678
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638458123
          },
          "2": {
            "get_star_ts": 1638458621
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638555441
          },
          "2": {
            "get_star_ts": 1638564761
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638643976
          },
          "2": {
            "get_star_ts": 1638645528
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638682790
          },
          "2": {
            "get_star_ts": 1638686819
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638768724
          }
        }
      },
      "last_star_ts": 1638768724
    },
    "982870": {
      "stars": 42,
      "local_score": 4647,
      "name": "Anthony Luaders",
      "id": "982870",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638378613
          },
          "2": {
            "get_star_ts": 1638383576
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638456853
          },
          "2": {
            "get_star_ts": 1638457554
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638550381
          },
          "2": {
            "get_star_ts": 1638553038
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638816653
          },
          "2": {
            "get_star_ts": 1638817456
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638825304
          },
          "2": {
            "get_star_ts": 1638828008
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638831729
          },
          "2": {
            "get_star_ts": 1638837561
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638900210
          },
          "2": {
            "get_star_ts": 1638900693
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638984914
          },
          "2": {
            "get_star_ts": 1639003655
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639063509
          },
          "2": {
            "get_star_ts": 1639067304
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639148126
          },
          "2": {
            "get_star_ts": 1639149328
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639417259
          },
          "2": {
            "get_star_ts": 1639417512
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639419810
          },
          "2": {
            "get_star_ts": 1639421303
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639408969
          },
          "2": {
            "get_star_ts": 1639412164
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639497579
          },
          "2": {
            "get_star_ts": 1639506697
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639775673
          },
          "2": {
            "get_star_ts": 1639778093
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639698547
          },
          "2": {
            "get_star_ts": 1639699526
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639754830
          },
          "2": {
            "get_star_ts": 1639761845
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640890458
          },
          "2": {
            "get_star_ts": 1640890977
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1640054158
          },
          "2": {
            "get_star_ts": 1640055193
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640034685
          },
          "2": {
            "get_star_ts": 1640034896
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640100134
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640369385
          }
        }
      },
      "last_star_ts": 1640890977
    },
    "983081": {
      "last_star_ts": 1639714957,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639712798
          },
          "2": {
            "get_star_ts": 1639714957
          }
        }
      },
      "local_score": 61,
      "stars": 2,
      "id": "983081",
      "name": "Joshua Angeley",
      "global_score": 0
    },
    "992409": {
      "local_score": 3523,
      "stars": 28,
      "id": "992409",
      "name": "Dominique Bashizi",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335517
          },
          "2": {
            "get_star_ts": 1638336872
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638421733
          },
          "2": {
            "get_star_ts": 1638421954
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508738
          },
          "2": {
            "get_star_ts": 1638511154
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638603580
          },
          "2": {
            "get_star_ts": 1638608117
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638688894
          },
          "2": {
            "get_star_ts": 1638694311
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638768441
          },
          "2": {
            "get_star_ts": 1638775837
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638854453
          },
          "2": {
            "get_star_ts": 1638856464
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638941488
          },
          "2": {
            "get_star_ts": 1638948035
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639028599
          },
          "2": {
            "get_star_ts": 1639036867
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639114013
          },
          "2": {
            "get_star_ts": 1639114990
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639205201
          },
          "2": {
            "get_star_ts": 1639205992
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639306338
          },
          "2": {
            "get_star_ts": 1640074052
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640189620
          },
          "2": {
            "get_star_ts": 1640191392
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640236755
          },
          "2": {
            "get_star_ts": 1640402814
          }
        }
      },
      "last_star_ts": 1640402814
    },
    "1066686": {
      "local_score": 2536,
      "stars": 24,
      "name": "Nick Genthner",
      "id": "1066686",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638371631
          },
          "2": {
            "get_star_ts": 1638372521
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638473972
          },
          "2": {
            "get_star_ts": 1638474400
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638541350
          },
          "2": {
            "get_star_ts": 1638543246
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638651144
          },
          "2": {
            "get_star_ts": 1638651386
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638720521
          },
          "2": {
            "get_star_ts": 1638722068
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638804484
          },
          "2": {
            "get_star_ts": 1638805314
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638893109
          },
          "2": {
            "get_star_ts": 1638893596
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639015808
          },
          "2": {
            "get_star_ts": 1639018906
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639069159
          },
          "2": {
            "get_star_ts": 1639085360
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639150004
          },
          "2": {
            "get_star_ts": 1639155660
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639255548
          },
          "2": {
            "get_star_ts": 1639255994
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639430732
          },
          "2": {
            "get_star_ts": 1639430915
          }
        }
      },
      "last_star_ts": 1639430915
    },
    "1075669": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638626678
          },
          "2": {
            "get_star_ts": 1638638709
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638643374
          },
          "2": {
            "get_star_ts": 1638644308
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638665424
          },
          "2": {
            "get_star_ts": 1638746720
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639307575
          },
          "2": {
            "get_star_ts": 1639307594
          }
        }
      },
      "last_star_ts": 1639307594,
      "global_score": 0,
      "stars": 8,
      "id": "1075669",
      "local_score": 516,
      "name": "Cliff Free"
    },
    "1128251": {
      "last_star_ts": 1638387704,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638387704
          }
        }
      },
      "global_score": 0,
      "name": "Jelena Fordella",
      "stars": 1,
      "local_score": 75,
      "id": "1128251"
    },
    "1330736": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335379
          },
          "2": {
            "get_star_ts": 1638335603
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638422884
          },
          "2": {
            "get_star_ts": 1638425841
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638535257
          },
          "2": {
            "get_star_ts": 1638759356
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638796118
          },
          "2": {
            "get_star_ts": 1638899828
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638907958
          },
          "2": {
            "get_star_ts": 1638934235
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638935145
          },
          "2": {
            "get_star_ts": 1638937635
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638938885
          },
          "2": {
            "get_star_ts": 1638939826
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638941624
          },
          "2": {
            "get_star_ts": 1638992354
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639103624
          },
          "2": {
            "get_star_ts": 1639142940
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639172501
          },
          "2": {
            "get_star_ts": 1639175487
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639213510
          },
          "2": {
            "get_star_ts": 1639213805
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1640125495
          },
          "2": {
            "get_star_ts": 1640264129
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640319015
          },
          "2": {
            "get_star_ts": 1640319040
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640490615
          },
          "2": {
            "get_star_ts": 1640895581
          }
        }
      },
      "last_star_ts": 1640895581,
      "local_score": 2953,
      "stars": 28,
      "id": "1330736",
      "name": "Douglas Yoon",
      "global_score": 0
    },
    "1330824": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638403287
          },
          "2": {
            "get_star_ts": 1638460121
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638474003
          },
          "2": {
            "get_star_ts": 1638474393
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638558631
          }
        }
      },
      "last_star_ts": 1638558631,
      "stars": 5,
      "name": "Hmaal",
      "local_score": 352,
      "id": "1330824",
      "global_score": 0
    },
    "1331139": {
      "last_star_ts": 1639358207,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638425753
          },
          "2": {
            "get_star_ts": 1638428072
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638504793
          },
          "2": {
            "get_star_ts": 1638506129
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638513357
          },
          "2": {
            "get_star_ts": 1638517245
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638644001
          },
          "2": {
            "get_star_ts": 1638645865
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638689203
          },
          "2": {
            "get_star_ts": 1638734667
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638771944
          },
          "2": {
            "get_star_ts": 1638851092
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639031298
          },
          "2": {
            "get_star_ts": 1639100713
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639257517
          },
          "2": {
            "get_star_ts": 1639345297
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639358207
          }
        }
      },
      "global_score": 0,
      "local_score": 1647,
      "stars": 17,
      "id": "1331139",
      "name": "knomenot"
    },
    "1336133": {
      "global_score": 0,
      "local_score": 890,
      "stars": 12,
      "id": "1336133",
      "name": "Mahdi Albori",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638403117
          },
          "2": {
            "get_star_ts": 1638404045
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638495780
          },
          "2": {
            "get_star_ts": 1638496868
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638571274
          },
          "2": {
            "get_star_ts": 1638584425
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639358892
          },
          "2": {
            "get_star_ts": 1639358903
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1640017107
          },
          "2": {
            "get_star_ts": 1640017123
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1640196681
          },
          "2": {
            "get_star_ts": 1640197073
          }
        }
      },
      "last_star_ts": 1640197073
    },
    "1488031": {
      "last_star_ts": 1640728652,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638371274
          },
          "2": {
            "get_star_ts": 1638374358
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638453497
          },
          "2": {
            "get_star_ts": 1638454621
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638509770
          },
          "2": {
            "get_star_ts": 1638512688
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638640259
          },
          "2": {
            "get_star_ts": 1638643880
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638750544
          },
          "2": {
            "get_star_ts": 1638756422
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638803222
          },
          "2": {
            "get_star_ts": 1638833170
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638871847
          },
          "2": {
            "get_star_ts": 1638872186
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638985066
          },
          "2": {
            "get_star_ts": 1638987307
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639031449
          },
          "2": {
            "get_star_ts": 1639040562
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639152720
          },
          "2": {
            "get_star_ts": 1639156303
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639205966
          },
          "2": {
            "get_star_ts": 1639206108
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639541058
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639433750
          },
          "2": {
            "get_star_ts": 1639434091
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639511071
          },
          "2": {
            "get_star_ts": 1639537172
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639771946
          },
          "2": {
            "get_star_ts": 1639773765
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639760934
          },
          "2": {
            "get_star_ts": 1639763744
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640027860
          },
          "2": {
            "get_star_ts": 1640047912
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640093478
          },
          "2": {
            "get_star_ts": 1640112419
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640195531
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640725982
          },
          "2": {
            "get_star_ts": 1640728652
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640725195
          }
        }
      },
      "global_score": 0,
      "name": "Curtis Balusek",
      "stars": 39,
      "local_score": 4591,
      "id": "1488031"
    },
    "1488603": {
      "global_score": 0,
      "stars": 0,
      "id": "1488603",
      "local_score": 0,
      "name": "Steven Stewart",
      "last_star_ts": "0",
      "completion_day_level": {}
    },
    "1491389": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638362702
          },
          "2": {
            "get_star_ts": 1638363646
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638448949
          },
          "2": {
            "get_star_ts": 1638449829
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638536434
          },
          "2": {
            "get_star_ts": 1638538198
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639486020
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639690971
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638882863
          },
          "2": {
            "get_star_ts": 1639943149
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639141335
          },
          "2": {
            "get_star_ts": 1639143003
          }
        }
      },
      "last_star_ts": 1639943149,
      "stars": 12,
      "local_score": 1190,
      "id": "1491389",
      "name": "tomloizzo1",
      "global_score": 0
    },
    "1492817": {
      "last_star_ts": 1639620971,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638365797
          },
          "2": {
            "get_star_ts": 1638366459
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638448029
          },
          "2": {
            "get_star_ts": 1638448168
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638532282
          },
          "2": {
            "get_star_ts": 1638534339
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638616003
          },
          "2": {
            "get_star_ts": 1638618169
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638713255
          },
          "2": {
            "get_star_ts": 1638713373
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638813181
          },
          "2": {
            "get_star_ts": 1638818198
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638906152
          },
          "2": {
            "get_star_ts": 1638907627
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638995544
          },
          "2": {
            "get_star_ts": 1639022546
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639058996
          },
          "2": {
            "get_star_ts": 1639078655
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639597037
          },
          "2": {
            "get_star_ts": 1639599225
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639603225
          },
          "2": {
            "get_star_ts": 1639603735
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639605739
          },
          "2": {
            "get_star_ts": 1639606844
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639610370
          },
          "2": {
            "get_star_ts": 1639610558
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639620105
          },
          "2": {
            "get_star_ts": 1639620971
          }
        }
      },
      "stars": 28,
      "local_score": 3099,
      "name": "Kelly Tabler",
      "id": "1492817",
      "global_score": 0
    },
    "1494672": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638369557
          },
          "2": {
            "get_star_ts": 1638370234
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638455569
          },
          "2": {
            "get_star_ts": 1638456207
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638543032
          },
          "2": {
            "get_star_ts": 1638545963
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638638511
          },
          "2": {
            "get_star_ts": 1638641739
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638722395
          },
          "2": {
            "get_star_ts": 1638723548
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638798019
          },
          "2": {
            "get_star_ts": 1638815556
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638892986
          },
          "2": {
            "get_star_ts": 1638893268
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638973274
          },
          "2": {
            "get_star_ts": 1638984223
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639063360
          },
          "2": {
            "get_star_ts": 1639067522
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639148724
          },
          "2": {
            "get_star_ts": 1639149620
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639243230
          },
          "2": {
            "get_star_ts": 1639243712
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639340489
          },
          "2": {
            "get_star_ts": 1639398258
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639402752
          },
          "2": {
            "get_star_ts": 1639403331
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639489547
          },
          "2": {
            "get_star_ts": 1639495190
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639597433
          },
          "2": {
            "get_star_ts": 1639600033
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639941635
          },
          "2": {
            "get_star_ts": 1639941778
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640027129
          },
          "2": {
            "get_star_ts": 1640027300
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640114691
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640732639
          }
        }
      },
      "last_star_ts": 1640732639,
      "local_score": 4139,
      "stars": 36,
      "id": "1494672",
      "name": "RyWerth",
      "global_score": 0
    },
    "1505796": {
      "id": "1505796",
      "stars": 11,
      "local_score": 961,
      "name": "Ken Stillings",
      "global_score": 0,
      "last_star_ts": 1641738737,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638367423
          },
          "2": {
            "get_star_ts": 1638400430
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638451552
          },
          "2": {
            "get_star_ts": 1638451957
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638539692
          },
          "2": {
            "get_star_ts": 1638565668
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638643572
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641738726
          },
          "2": {
            "get_star_ts": 1641738737
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641648872
          },
          "2": {
            "get_star_ts": 1641652810
          }
        }
      }
    },
    "1505924": {
      "local_score": 771,
      "stars": 11,
      "id": "1505924",
      "name": "Benjamin Mokotoff",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638819987
          },
          "2": {
            "get_star_ts": 1638821092
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638823446
          },
          "2": {
            "get_star_ts": 1638831929
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638832647
          },
          "2": {
            "get_star_ts": 1638846784
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638848862
          },
          "2": {
            "get_star_ts": 1639232109
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1640212864
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639239374
          },
          "2": {
            "get_star_ts": 1639841951
          }
        }
      },
      "last_star_ts": 1640212864
    },
    "1505962": {
      "last_star_ts": 1641791004,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638494390
          },
          "2": {
            "get_star_ts": 1638494538
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638495092
          },
          "2": {
            "get_star_ts": 1638495098
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508790
          },
          "2": {
            "get_star_ts": 1638509410
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1640375569
          },
          "2": {
            "get_star_ts": 1640375705
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1640376114
          },
          "2": {
            "get_star_ts": 1640376123
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641481248
          },
          "2": {
            "get_star_ts": 1641481253
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641493591
          },
          "2": {
            "get_star_ts": 1641493597
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1641790999
          },
          "2": {
            "get_star_ts": 1641791004
          }
        }
      },
      "stars": 16,
      "name": "Phil",
      "local_score": 1243,
      "id": "1505962",
      "global_score": 0
    },
    "1506478": {
      "last_star_ts": 1638645740,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335138
          },
          "2": {
            "get_star_ts": 1638335551
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638450359
          },
          "2": {
            "get_star_ts": 1638451234
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638541730
          },
          "2": {
            "get_star_ts": 1638645740
          }
        }
      },
      "local_score": 661,
      "stars": 6,
      "id": "1506478",
      "name": "Venky Bharadwaj",
      "global_score": 0
    },
    "1506620": {
      "stars": 15,
      "local_score": 1207,
      "id": "1506620",
      "name": "Ozzy Campos",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638403784
          },
          "2": {
            "get_star_ts": 1638405786
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638451912
          },
          "2": {
            "get_star_ts": 1638457767
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638532278
          },
          "2": {
            "get_star_ts": 1638658950
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638886727
          },
          "2": {
            "get_star_ts": 1640097874
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1640312658
          },
          "2": {
            "get_star_ts": 1640356005
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1640372032
          },
          "2": {
            "get_star_ts": 1640394010
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1640440600
          },
          "2": {
            "get_star_ts": 1640441574
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1640657817
          }
        }
      },
      "last_star_ts": 1640657817
    },
    "1512095": {
      "stars": 43,
      "local_score": 5420,
      "id": "1512095",
      "name": "Mike Eilbacher",
      "global_score": 0,
      "last_star_ts": 1640619433,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638359635
          },
          "2": {
            "get_star_ts": 1638360093
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638446005
          },
          "2": {
            "get_star_ts": 1638446180
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638532374
          },
          "2": {
            "get_star_ts": 1638536720
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638625850
          },
          "2": {
            "get_star_ts": 1638627493
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638711376
          },
          "2": {
            "get_star_ts": 1638714086
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638792227
          },
          "2": {
            "get_star_ts": 1638794404
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638878378
          },
          "2": {
            "get_star_ts": 1638878968
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638965193
          },
          "2": {
            "get_star_ts": 1638967802
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639053368
          },
          "2": {
            "get_star_ts": 1639056011
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639137241
          },
          "2": {
            "get_star_ts": 1639138467
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639229677
          },
          "2": {
            "get_star_ts": 1639230204
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639314757
          },
          "2": {
            "get_star_ts": 1639316318
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639397512
          },
          "2": {
            "get_star_ts": 1639398505
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639484076
          },
          "2": {
            "get_star_ts": 1639498596
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639589807
          },
          "2": {
            "get_star_ts": 1639591287
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639679027
          },
          "2": {
            "get_star_ts": 1639710262
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639743324
          },
          "2": {
            "get_star_ts": 1639743829
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639965713
          },
          "2": {
            "get_star_ts": 1639966284
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640008910
          },
          "2": {
            "get_star_ts": 1640010201
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640088862
          },
          "2": {
            "get_star_ts": 1640138703
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640187310
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640267932
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640619433
          }
        }
      }
    },
    "1513819": {
      "global_score": 0,
      "stars": 44,
      "name": "pclements12",
      "local_score": 5066,
      "id": "1513819",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638370758
          },
          "2": {
            "get_star_ts": 1638376063
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638459637
          },
          "2": {
            "get_star_ts": 1638460223
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638542024
          },
          "2": {
            "get_star_ts": 1638544226
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638596984
          },
          "2": {
            "get_star_ts": 1638597677
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638756218
          },
          "2": {
            "get_star_ts": 1638761542
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638809885
          },
          "2": {
            "get_star_ts": 1638817240
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638890314
          },
          "2": {
            "get_star_ts": 1638891159
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638979689
          },
          "2": {
            "get_star_ts": 1639071335
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639076032
          },
          "2": {
            "get_star_ts": 1639078773
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639166623
          },
          "2": {
            "get_star_ts": 1639168425
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639235339
          },
          "2": {
            "get_star_ts": 1639235517
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639324267
          },
          "2": {
            "get_star_ts": 1639333338
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639418071
          },
          "2": {
            "get_star_ts": 1639418686
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639502327
          },
          "2": {
            "get_star_ts": 1639522779
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639591777
          },
          "2": {
            "get_star_ts": 1639602164
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639777036
          },
          "2": {
            "get_star_ts": 1639777619
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639837098
          },
          "2": {
            "get_star_ts": 1639837969
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639927260
          },
          "2": {
            "get_star_ts": 1639929304
          }
        },
        "19": {
          "1": {
            "get_star_ts": 1640102269
          },
          "2": {
            "get_star_ts": 1640102929
          }
        },
        "20": {
          "1": {
            "get_star_ts": 1640025400
          },
          "2": {
            "get_star_ts": 1640025474
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640118450
          },
          "2": {
            "get_star_ts": 1640150029
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640195939
          }
        },
        "23": {
          "1": {
            "get_star_ts": 1640299738
          }
        }
      },
      "last_star_ts": 1640299738
    },
    "1517289": {
      "global_score": 0,
      "stars": 0,
      "local_score": 0,
      "name": "Annie Kong",
      "id": "1517289",
      "last_star_ts": "0",
      "completion_day_level": {}
    },
    "1520575": {
      "stars": 4,
      "name": "Anna Park",
      "local_score": 376,
      "id": "1520575",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638368148
          },
          "2": {
            "get_star_ts": 1638368510
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638457286
          },
          "2": {
            "get_star_ts": 1638457916
          }
        }
      },
      "last_star_ts": 1638457916
    },
    "1539126": {
      "global_score": 0,
      "id": "1539126",
      "stars": 30,
      "local_score": 3277,
      "name": "Zach S",
      "last_star_ts": 1639628255,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638367434
          },
          "2": {
            "get_star_ts": 1638367788
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638452122
          },
          "2": {
            "get_star_ts": 1638452380
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638541181
          },
          "2": {
            "get_star_ts": 1638545150
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638640354
          },
          "2": {
            "get_star_ts": 1638656402
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638834853
          },
          "2": {
            "get_star_ts": 1638835597
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638837196
          },
          "2": {
            "get_star_ts": 1638837379
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638883352
          },
          "2": {
            "get_star_ts": 1638883946
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639012159
          },
          "2": {
            "get_star_ts": 1639101304
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639102737
          },
          "2": {
            "get_star_ts": 1639104633
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639173393
          },
          "2": {
            "get_star_ts": 1639175508
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639240086
          },
          "2": {
            "get_star_ts": 1639240905
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639321010
          },
          "2": {
            "get_star_ts": 1639323377
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639416565
          },
          "2": {
            "get_star_ts": 1639417202
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639490663
          },
          "2": {
            "get_star_ts": 1639492637
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639623128
          },
          "2": {
            "get_star_ts": 1639628255
          }
        }
      }
    },
    "1542459": {
      "stars": 0,
      "name": "Patrick Green",
      "local_score": 0,
      "id": "1542459",
      "global_score": 0,
      "completion_day_level": {},
      "last_star_ts": "0"
    },
    "1543143": {
      "global_score": 0,
      "stars": 18,
      "local_score": 1724,
      "id": "1543143",
      "name": "Dave Barrett",
      "last_star_ts": 1640281262,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638382160
          },
          "2": {
            "get_star_ts": 1638387080
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638473255
          },
          "2": {
            "get_star_ts": 1638474051
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638544235
          },
          "2": {
            "get_star_ts": 1638570756
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638660151
          },
          "2": {
            "get_star_ts": 1638742915
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638751151
          },
          "2": {
            "get_star_ts": 1638751908
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638809506
          },
          "2": {
            "get_star_ts": 1638822092
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638889588
          },
          "2": {
            "get_star_ts": 1638906392
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638994319
          },
          "2": {
            "get_star_ts": 1639090093
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639096439
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1640281262
          }
        }
      }
    },
    "1549336": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638899756
          },
          "2": {
            "get_star_ts": 1639322447
          }
        }
      },
      "last_star_ts": 1639322447,
      "global_score": 0,
      "stars": 2,
      "local_score": 81,
      "id": "1549336",
      "name": "Brian G. Shacklett"
    },
    "1549949": {
      "id": "1549949",
      "stars": 10,
      "local_score": 1035,
      "name": "Patrick Hade",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638406158
          },
          "2": {
            "get_star_ts": 1638407454
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638422003
          },
          "2": {
            "get_star_ts": 1638423452
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638509164
          },
          "2": {
            "get_star_ts": 1638548841
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638645515
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638717071
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638966896
          },
          "2": {
            "get_star_ts": 1638967114
          }
        }
      },
      "last_star_ts": 1638967114
    },
    "1550261": {
      "global_score": 0,
      "stars": 11,
      "local_score": 622,
      "name": "Karen A. Loscocco",
      "id": "1550261",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639345725
          },
          "2": {
            "get_star_ts": 1639346173
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639347266
          },
          "2": {
            "get_star_ts": 1639348956
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639351924
          },
          "2": {
            "get_star_ts": 1639358120
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639362009
          },
          "2": {
            "get_star_ts": 1639364528
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1640824543
          },
          "2": {
            "get_star_ts": 1640827368
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641145030
          }
        }
      },
      "last_star_ts": 1641145030
    },
    "1550444": {
      "last_star_ts": 1640107623,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638467703
          },
          "2": {
            "get_star_ts": 1638470522
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638471649
          },
          "2": {
            "get_star_ts": 1638474140
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638554184
          },
          "2": {
            "get_star_ts": 1638566820
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638822098
          },
          "2": {
            "get_star_ts": 1638847627
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638989806
          },
          "2": {
            "get_star_ts": 1639342521
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639357725
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639360177
          },
          "2": {
            "get_star_ts": 1639425056
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639448575
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639935340
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640107623
          }
        }
      },
      "stars": 16,
      "local_score": 1317,
      "name": "James Majors",
      "id": "1550444",
      "global_score": 0
    },
    "1550722": {
      "global_score": 0,
      "id": "1550722",
      "stars": 35,
      "local_score": 4024,
      "name": "Anfer",
      "last_star_ts": 1640142098,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638370513
          },
          "2": {
            "get_star_ts": 1638372173
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638453300
          },
          "2": {
            "get_star_ts": 1638455510
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638541667
          },
          "2": {
            "get_star_ts": 1638552588
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638601580
          },
          "2": {
            "get_star_ts": 1638627317
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638722497
          },
          "2": {
            "get_star_ts": 1638725847
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638803671
          },
          "2": {
            "get_star_ts": 1638814363
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638885611
          },
          "2": {
            "get_star_ts": 1638885917
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638972704
          },
          "2": {
            "get_star_ts": 1638993955
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639060360
          },
          "2": {
            "get_star_ts": 1639071940
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639149522
          },
          "2": {
            "get_star_ts": 1639150836
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639251987
          },
          "2": {
            "get_star_ts": 1639253277
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639406832
          },
          "2": {
            "get_star_ts": 1639406929
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639420433
          },
          "2": {
            "get_star_ts": 1639424026
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639600125
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639579851
          },
          "2": {
            "get_star_ts": 1639580475
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639748280
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639755239
          },
          "2": {
            "get_star_ts": 1639758176
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1639937572
          },
          "2": {
            "get_star_ts": 1639937713
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640142098
          }
        }
      }
    },
    "1551098": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638371300
          },
          "2": {
            "get_star_ts": 1638372275
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638458635
          },
          "2": {
            "get_star_ts": 1638459428
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638544895
          },
          "2": {
            "get_star_ts": 1638549825
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638723364
          },
          "2": {
            "get_star_ts": 1638726979
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638810052
          },
          "2": {
            "get_star_ts": 1638811413
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638800103
          },
          "2": {
            "get_star_ts": 1638806508
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638885776
          },
          "2": {
            "get_star_ts": 1638886777
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639004279
          },
          "2": {
            "get_star_ts": 1639089917
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639065575
          },
          "2": {
            "get_star_ts": 1639096091
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639156276
          },
          "2": {
            "get_star_ts": 1639157900
          }
        }
      },
      "last_star_ts": 1639157900,
      "global_score": 0,
      "stars": 20,
      "id": "1551098",
      "local_score": 2029,
      "name": "Tom D"
    },
    "1551281": {
      "completion_day_level": {},
      "last_star_ts": "0",
      "global_score": 0,
      "stars": 0,
      "id": "1551281",
      "local_score": 0,
      "name": "Adriana Gutierrez-Sureza"
    },
    "1551392": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638363012
          },
          "2": {
            "get_star_ts": 1638363728
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638445640
          },
          "2": {
            "get_star_ts": 1638446207
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638511284
          },
          "2": {
            "get_star_ts": 1638511353
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638594991
          },
          "2": {
            "get_star_ts": 1638595006
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638751459
          },
          "2": {
            "get_star_ts": 1638751471
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638802179
          },
          "2": {
            "get_star_ts": 1638932467
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638932691
          },
          "2": {
            "get_star_ts": 1638933473
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639103604
          },
          "2": {
            "get_star_ts": 1639403916
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639100599
          },
          "2": {
            "get_star_ts": 1639103590
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639403574
          },
          "2": {
            "get_star_ts": 1640094336
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639964877
          },
          "2": {
            "get_star_ts": 1640084223
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639965241
          },
          "2": {
            "get_star_ts": 1640084267
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640084098
          },
          "2": {
            "get_star_ts": 1640142610
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640142549
          }
        }
      },
      "last_star_ts": 1640142610,
      "name": "Christopher Maltusch",
      "stars": 27,
      "local_score": 2924,
      "id": "1551392",
      "global_score": 0
    },
    "1551553": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335538
          },
          "2": {
            "get_star_ts": 1638336877
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638423476
          },
          "2": {
            "get_star_ts": 1638423775
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508942
          },
          "2": {
            "get_star_ts": 1638512245
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638633027
          },
          "2": {
            "get_star_ts": 1638634313
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638815685
          },
          "2": {
            "get_star_ts": 1638827458
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638801479
          },
          "2": {
            "get_star_ts": 1638802682
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638853819
          },
          "2": {
            "get_star_ts": 1638855277
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638973554
          },
          "2": {
            "get_star_ts": 1638979172
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639027284
          },
          "2": {
            "get_star_ts": 1639032386
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639152005
          },
          "2": {
            "get_star_ts": 1639153054
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639203308
          },
          "2": {
            "get_star_ts": 1639203725
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639499066
          },
          "2": {
            "get_star_ts": 1639508398
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639430020
          },
          "2": {
            "get_star_ts": 1639430466
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639460273
          },
          "2": {
            "get_star_ts": 1639513772
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639584080
          },
          "2": {
            "get_star_ts": 1639617086
          }
        }
      },
      "last_star_ts": 1639617086,
      "id": "1551553",
      "stars": 30,
      "local_score": 3636,
      "name": "Drew Teachout",
      "global_score": 0
    },
    "1551612": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1640015139
          },
          "2": {
            "get_star_ts": 1640015376
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1640015979
          },
          "2": {
            "get_star_ts": 1640026634
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1640029395
          },
          "2": {
            "get_star_ts": 1640188584
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1640618422
          },
          "2": {
            "get_star_ts": 1640624047
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641825584
          },
          "2": {
            "get_star_ts": 1641825605
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641825924
          },
          "2": {
            "get_star_ts": 1641825938
          }
        }
      },
      "last_star_ts": 1641825938,
      "global_score": 0,
      "id": "1551612",
      "stars": 12,
      "local_score": 617,
      "name": "Aaron Atwell"
    },
    "1551684": {
      "global_score": 0,
      "stars": 30,
      "name": "tfossum-slalom",
      "local_score": 3785,
      "id": "1551684",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638335916
          },
          "2": {
            "get_star_ts": 1638339099
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638423107
          },
          "2": {
            "get_star_ts": 1638423688
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638509112
          },
          "2": {
            "get_star_ts": 1638514945
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638633431
          },
          "2": {
            "get_star_ts": 1638635113
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638737084
          },
          "2": {
            "get_star_ts": 1638745825
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638773413
          },
          "2": {
            "get_star_ts": 1638773460
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638855574
          },
          "2": {
            "get_star_ts": 1638858395
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638943420
          },
          "2": {
            "get_star_ts": 1638949820
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639028722
          },
          "2": {
            "get_star_ts": 1639033153
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639113316
          },
          "2": {
            "get_star_ts": 1639115814
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639202373
          },
          "2": {
            "get_star_ts": 1639203356
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639318730
          },
          "2": {
            "get_star_ts": 1639327645
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639381168
          },
          "2": {
            "get_star_ts": 1639381536
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639459982
          },
          "2": {
            "get_star_ts": 1639472758
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639629174
          },
          "2": {
            "get_star_ts": 1639670672
          }
        }
      },
      "last_star_ts": 1639670672
    },
    "1551779": {
      "last_star_ts": 1639580264,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638368164
          },
          "2": {
            "get_star_ts": 1638368943
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638457312
          },
          "2": {
            "get_star_ts": 1638458546
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638544905
          },
          "2": {
            "get_star_ts": 1638549379
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638747346
          },
          "2": {
            "get_star_ts": 1638748022
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638740239
          },
          "2": {
            "get_star_ts": 1638744028
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638802282
          },
          "2": {
            "get_star_ts": 1638808703
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638888623
          },
          "2": {
            "get_star_ts": 1638891778
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638974996
          },
          "2": {
            "get_star_ts": 1638982274
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639060820
          },
          "2": {
            "get_star_ts": 1639066199
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639147995
          },
          "2": {
            "get_star_ts": 1639149586
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639410033
          },
          "2": {
            "get_star_ts": 1639411321
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639495172
          },
          "2": {
            "get_star_ts": 1639501886
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639580264
          }
        }
      },
      "global_score": 0,
      "stars": 25,
      "local_score": 2727,
      "id": "1551779",
      "name": "Graham Guthrie"
    },
    "1552420": {
      "global_score": 0,
      "stars": 10,
      "name": "geraldinaz",
      "local_score": 624,
      "id": "1552420",
      "last_star_ts": 1639603246,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638889885
          },
          "2": {
            "get_star_ts": 1638899185
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638900435
          },
          "2": {
            "get_star_ts": 1638902928
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638906303
          },
          "2": {
            "get_star_ts": 1639170290
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639597251
          },
          "2": {
            "get_star_ts": 1639598054
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639601586
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1639603246
          }
        }
      }
    },
    "1552740": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "stars": 0,
      "id": "1552740",
      "local_score": 0,
      "name": "Radhika Subramanian",
      "global_score": 0
    },
    "1552821": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639247991
          },
          "2": {
            "get_star_ts": 1639249131
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639250044
          },
          "2": {
            "get_star_ts": 1639250185
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639255256
          },
          "2": {
            "get_star_ts": 1639805250
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1640043286
          },
          "2": {
            "get_star_ts": 1640052299
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1640071530
          },
          "2": {
            "get_star_ts": 1640115838
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639810556
          },
          "2": {
            "get_star_ts": 1640034515
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1640035531
          },
          "2": {
            "get_star_ts": 1640035938
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1640126578
          },
          "2": {
            "get_star_ts": 1640212943
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1640126179
          },
          "2": {
            "get_star_ts": 1640150859
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1640243180
          },
          "2": {
            "get_star_ts": 1640244602
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1640583672
          },
          "2": {
            "get_star_ts": 1640584551
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640587933
          },
          "2": {
            "get_star_ts": 1640589402
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1640413623
          },
          "2": {
            "get_star_ts": 1640564766
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1640803881
          },
          "2": {
            "get_star_ts": 1640807384
          }
        }
      },
      "last_star_ts": 1640807384,
      "global_score": 0,
      "id": "1552821",
      "stars": 28,
      "local_score": 2230,
      "name": "Lily Li"
    },
    "1552913": {
      "global_score": 0,
      "name": "Jules Scheil",
      "stars": 10,
      "local_score": 684,
      "id": "1552913",
      "last_star_ts": 1642012219,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638383914
          },
          "2": {
            "get_star_ts": 1638384556
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638547441
          },
          "2": {
            "get_star_ts": 1638550503
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638810070
          },
          "2": {
            "get_star_ts": 1638978159
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639586280
          },
          "2": {
            "get_star_ts": 1639586616
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1642012165
          },
          "2": {
            "get_star_ts": 1642012219
          }
        }
      }
    },
    "1554163": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "stars": 0,
      "local_score": 0,
      "id": "1554163",
      "name": "dtewell19",
      "global_score": 0
    },
    "1554509": {
      "last_star_ts": 1640743044,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638397580
          },
          "2": {
            "get_star_ts": 1638398315
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638450090
          },
          "2": {
            "get_star_ts": 1638458231
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638533876
          },
          "2": {
            "get_star_ts": 1638544350
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638617167
          },
          "2": {
            "get_star_ts": 1638633598
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638708731
          },
          "2": {
            "get_star_ts": 1638716554
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638790904
          },
          "2": {
            "get_star_ts": 1638796755
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638874564
          },
          "2": {
            "get_star_ts": 1638875258
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638960533
          },
          "2": {
            "get_star_ts": 1638973444
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639046183
          },
          "2": {
            "get_star_ts": 1639051888
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1639133079
          },
          "2": {
            "get_star_ts": 1639134258
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639223894
          },
          "2": {
            "get_star_ts": 1639224244
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639320634
          },
          "2": {
            "get_star_ts": 1639321511
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639391784
          },
          "2": {
            "get_star_ts": 1639393299
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639476605
          },
          "2": {
            "get_star_ts": 1639480256
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639779801
          },
          "2": {
            "get_star_ts": 1639790362
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639716404
          },
          "2": {
            "get_star_ts": 1639717601
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1640519118
          },
          "2": {
            "get_star_ts": 1640525998
          }
        },
        "18": {
          "1": {
            "get_star_ts": 1640739915
          },
          "2": {
            "get_star_ts": 1640743044
          }
        },
        "21": {
          "1": {
            "get_star_ts": 1640080325
          }
        },
        "22": {
          "1": {
            "get_star_ts": 1640194413
          }
        }
      },
      "stars": 38,
      "id": "1554509",
      "local_score": 4552,
      "name": "Gunnar Martin",
      "global_score": 0
    },
    "1554651": {
      "last_star_ts": 1639092858,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638371062
          },
          "2": {
            "get_star_ts": 1638374616
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638458208
          },
          "2": {
            "get_star_ts": 1638458418
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638543160
          },
          "2": {
            "get_star_ts": 1638546619
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638747507
          },
          "2": {
            "get_star_ts": 1638747601
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638820706
          },
          "2": {
            "get_star_ts": 1638825471
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638891208
          },
          "2": {
            "get_star_ts": 1638891530
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639092858
          }
        }
      },
      "global_score": 0,
      "local_score": 1290,
      "stars": 13,
      "id": "1554651",
      "name": "Aidan Murphy"
    },
    "1554805": {
      "global_score": 0,
      "local_score": 1607,
      "stars": 18,
      "name": "Rachel Shah",
      "id": "1554805",
      "last_star_ts": 1639513673,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638374424
          },
          "2": {
            "get_star_ts": 1638376655
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638471132
          },
          "2": {
            "get_star_ts": 1638471927
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638810657
          },
          "2": {
            "get_star_ts": 1638827452
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638834180
          },
          "2": {
            "get_star_ts": 1638844739
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638908941
          },
          "2": {
            "get_star_ts": 1638912620
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638914735
          },
          "2": {
            "get_star_ts": 1639167813
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638992948
          },
          "2": {
            "get_star_ts": 1638993619
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638995333
          },
          "2": {
            "get_star_ts": 1639089904
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639171922
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639513673
          }
        }
      }
    },
    "1558603": {
      "global_score": 0,
      "local_score": 584,
      "stars": 10,
      "name": "Morgan OLeary",
      "id": "1558603",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638897003
          },
          "2": {
            "get_star_ts": 1638897596
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638900926
          },
          "2": {
            "get_star_ts": 1638902959
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638912882
          },
          "2": {
            "get_star_ts": 1638917538
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1642095961
          },
          "2": {
            "get_star_ts": 1642102043
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1642107500
          },
          "2": {
            "get_star_ts": 1642107512
          }
        }
      },
      "last_star_ts": 1642107512
    },
    "1559929": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639076397
          },
          "2": {
            "get_star_ts": 1639079105
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639086951
          }
        }
      },
      "last_star_ts": 1639086951,
      "global_score": 0,
      "stars": 3,
      "id": "1559929",
      "local_score": 128,
      "name": "SlalomAdrian"
    },
    "1561624": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638369593
          },
          "2": {
            "get_star_ts": 1638370683
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638481884
          },
          "2": {
            "get_star_ts": 1638540941
          }
        }
      },
      "last_star_ts": 1638540941,
      "name": "Matt Haneburger",
      "stars": 4,
      "local_score": 333,
      "id": "1561624",
      "global_score": 0
    },
    "1561893": {
      "global_score": 0,
      "local_score": 71,
      "stars": 2,
      "name": "Lucia Melby",
      "id": "1561893",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639321733
          },
          "2": {
            "get_star_ts": 1639324242
          }
        }
      },
      "last_star_ts": 1639324242
    },
    "1561912": {
      "global_score": 0,
      "stars": 6,
      "local_score": 424,
      "name": "mehtaparas",
      "id": "1561912",
      "last_star_ts": 1638995914,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638369990
          },
          "2": {
            "get_star_ts": 1638370796
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638888543
          },
          "2": {
            "get_star_ts": 1638888737
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638891165
          },
          "2": {
            "get_star_ts": 1638995914
          }
        }
      }
    },
    "1561945": {
      "stars": 14,
      "local_score": 1211,
      "id": "1561945",
      "name": "llbarnet",
      "global_score": 0,
      "last_star_ts": 1641325388,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638364622
          },
          "2": {
            "get_star_ts": 1638365781
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638451786
          },
          "2": {
            "get_star_ts": 1638480127
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638568721
          },
          "2": {
            "get_star_ts": 1638571937
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638801830
          },
          "2": {
            "get_star_ts": 1638802123
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639085555
          },
          "2": {
            "get_star_ts": 1639085566
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641319198
          },
          "2": {
            "get_star_ts": 1641320937
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641322788
          },
          "2": {
            "get_star_ts": 1641325388
          }
        }
      }
    },
    "1562700": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "global_score": 0,
      "local_score": 0,
      "stars": 0,
      "name": "Jay Jung",
      "id": "1562700"
    },
    "1562745": {
      "global_score": 0,
      "name": "Stephen Ilhardt",
      "stars": 0,
      "local_score": 0,
      "id": "1562745",
      "completion_day_level": {},
      "last_star_ts": "0"
    },
    "1562940": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "global_score": 0,
      "stars": 0,
      "local_score": 0,
      "id": "1562940",
      "name": "BreezeWater"
    },
    "1563069": {
      "global_score": 0,
      "id": "1563069",
      "stars": 5,
      "local_score": 262,
      "name": "mark podojil",
      "last_star_ts": 1641338447,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638467893
          },
          "2": {
            "get_star_ts": 1638469357
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638474504
          },
          "2": {
            "get_star_ts": 1641323899
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1641338447
          }
        }
      }
    },
    "1563197": {
      "global_score": 0,
      "stars": 0,
      "local_score": 0,
      "id": "1563197",
      "name": "Steven French",
      "last_star_ts": "0",
      "completion_day_level": {}
    },
    "1563223": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "id": "1563223",
      "stars": 0,
      "local_score": 0,
      "name": "Various-Ad-4113",
      "global_score": 0
    },
    "1563435": {
      "global_score": 0,
      "stars": 1,
      "name": "Gins George",
      "local_score": 85,
      "id": "1563435",
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638375560
          }
        }
      },
      "last_star_ts": 1638375560
    },
    "1563836": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "global_score": 0,
      "name": "iudogie",
      "stars": 0,
      "local_score": 0,
      "id": "1563836"
    },
    "1568202": {
      "stars": 5,
      "id": "1568202",
      "local_score": 448,
      "name": "Josh Davis",
      "global_score": 0,
      "last_star_ts": 1638590374,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638401711
          },
          "2": {
            "get_star_ts": 1638407037
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638423429
          },
          "2": {
            "get_star_ts": 1638426670
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638590374
          }
        }
      }
    },
    "1611157": {
      "id": "1611157",
      "stars": 6,
      "local_score": 515,
      "name": "Yebin Choi",
      "global_score": 0,
      "last_star_ts": 1638589376,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638359281
          },
          "2": {
            "get_star_ts": 1638359822
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638581284
          },
          "2": {
            "get_star_ts": 1638581861
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638583576
          },
          "2": {
            "get_star_ts": 1638589376
          }
        }
      }
    },
    "1624682": {
      "global_score": 0,
      "stars": 7,
      "name": "Connie Reynolds",
      "local_score": 698,
      "id": "1624682",
      "last_star_ts": 1638764333,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638367474
          },
          "2": {
            "get_star_ts": 1638367742
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638451150
          },
          "2": {
            "get_star_ts": 1638451968
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638538982
          },
          "2": {
            "get_star_ts": 1638584040
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638764333
          }
        }
      }
    },
    "1625150": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "global_score": 0,
      "id": "1625150",
      "stars": 0,
      "local_score": 0,
      "name": "Amanda Yang"
    },
    "1636057": {
      "local_score": 0,
      "stars": 0,
      "id": "1636057",
      "name": "Jordan Leahey",
      "global_score": 0,
      "completion_day_level": {},
      "last_star_ts": "0"
    },
    "1675432": {
      "stars": 16,
      "local_score": 1594,
      "name": "Barry Ervin",
      "id": "1675432",
      "global_score": 0,
      "last_star_ts": 1639064142,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638393931
          },
          "2": {
            "get_star_ts": 1638394587
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638455914
          },
          "2": {
            "get_star_ts": 1638456050
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638508986
          },
          "2": {
            "get_star_ts": 1638511615
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638828943
          },
          "2": {
            "get_star_ts": 1638990701
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638846832
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1638885227
          },
          "2": {
            "get_star_ts": 1638887281
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1638905810
          },
          "2": {
            "get_star_ts": 1638906983
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1638970580
          },
          "2": {
            "get_star_ts": 1638988294
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639064142
          }
        }
      }
    },
    "1695980": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638929994
          },
          "2": {
            "get_star_ts": 1638930145
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638931082
          },
          "2": {
            "get_star_ts": 1638931226
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638932172
          },
          "2": {
            "get_star_ts": 1642191935
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1642192521
          },
          "2": {
            "get_star_ts": 1642192571
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1642193138
          },
          "2": {
            "get_star_ts": 1642193331
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1642193458
          },
          "2": {
            "get_star_ts": 1642193586
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1642193733
          },
          "2": {
            "get_star_ts": 1642193803
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1642194032
          },
          "2": {
            "get_star_ts": 1642194258
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1642194429
          },
          "2": {
            "get_star_ts": 1642194473
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1642194785
          },
          "2": {
            "get_star_ts": 1642194827
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1642195579
          }
        }
      },
      "last_star_ts": 1642195579,
      "global_score": 0,
      "id": "1695980",
      "stars": 21,
      "local_score": 1415,
      "name": "Miriam L"
    },
    "1728319": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638883217
          },
          "2": {
            "get_star_ts": 1638883345
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638455518
          },
          "2": {
            "get_star_ts": 1638455694
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638540786
          },
          "2": {
            "get_star_ts": 1638882726
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638898501
          },
          "2": {
            "get_star_ts": 1639055963
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639058392
          },
          "2": {
            "get_star_ts": 1639058720
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639059219
          },
          "2": {
            "get_star_ts": 1639068413
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1639661422
          },
          "2": {
            "get_star_ts": 1639661595
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1639149897
          },
          "2": {
            "get_star_ts": 1639153910
          }
        },
        "11": {
          "1": {
            "get_star_ts": 1639321293
          },
          "2": {
            "get_star_ts": 1639321460
          }
        },
        "12": {
          "1": {
            "get_star_ts": 1639324084
          },
          "2": {
            "get_star_ts": 1639325305
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639404902
          },
          "2": {
            "get_star_ts": 1639415955
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639577176
          },
          "2": {
            "get_star_ts": 1639659993
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639574722
          },
          "2": {
            "get_star_ts": 1639575635
          }
        },
        "25": {
          "1": {
            "get_star_ts": 1640439638
          }
        }
      },
      "last_star_ts": 1640439638,
      "name": "ttrebuchon",
      "stars": 27,
      "local_score": 2653,
      "id": "1728319",
      "global_score": 0
    },
    "1731038": {
      "last_star_ts": "0",
      "completion_day_level": {},
      "stars": 0,
      "id": "1731038",
      "local_score": 0,
      "name": "AliArdestaniSlalom",
      "global_score": 0
    },
    "1736774": {
      "stars": 4,
      "name": "Chazz Hall",
      "local_score": 145,
      "id": "1736774",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639494398
          },
          "2": {
            "get_star_ts": 1639507265
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639509800
          },
          "2": {
            "get_star_ts": 1639762315
          }
        }
      },
      "last_star_ts": 1639762315
    },
    "1854656": {
      "local_score": 986,
      "stars": 14,
      "name": "Xiaona Hu",
      "id": "1854656",
      "global_score": 0,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1638806891
          },
          "2": {
            "get_star_ts": 1638807255
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1638808185
          },
          "2": {
            "get_star_ts": 1638808902
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1638811070
          },
          "2": {
            "get_star_ts": 1638825657
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1638843107
          },
          "2": {
            "get_star_ts": 1638843738
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1638999032
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641332632
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641504731
          },
          "2": {
            "get_star_ts": 1641509431
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1641527110
          }
        },
        "9": {
          "1": {
            "get_star_ts": 1641881114
          }
        }
      },
      "last_star_ts": 1641881114
    },
    "1910298": {
      "completion_day_level": {},
      "last_star_ts": "0",
      "local_score": 0,
      "stars": 0,
      "name": "Lexie Kubisiak",
      "id": "1910298",
      "global_score": 0
    },
    "1936351": {
      "name": "icemanaf",
      "stars": 15,
      "local_score": 1304,
      "id": "1936351",
      "global_score": 0,
      "last_star_ts": 1639882536,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639267479
          },
          "2": {
            "get_star_ts": 1639270881
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639273151
          },
          "2": {
            "get_star_ts": 1639273902
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639301806
          },
          "2": {
            "get_star_ts": 1639326790
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1639483000
          },
          "2": {
            "get_star_ts": 1639485217
          }
        },
        "14": {
          "1": {
            "get_star_ts": 1639501779
          },
          "2": {
            "get_star_ts": 1639578954
          }
        },
        "15": {
          "1": {
            "get_star_ts": 1639657976
          }
        },
        "16": {
          "1": {
            "get_star_ts": 1639778334
          },
          "2": {
            "get_star_ts": 1639785022
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1639881337
          },
          "2": {
            "get_star_ts": 1639882536
          }
        }
      }
    },
    "1939341": {
      "completion_day_level": {},
      "last_star_ts": "0",
      "stars": 0,
      "local_score": 0,
      "id": "1939341",
      "name": "JEpifanio90",
      "global_score": 0
    },
    "1946955": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639348690
          },
          "2": {
            "get_star_ts": 1639349107
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639349702
          },
          "2": {
            "get_star_ts": 1639349826
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639354239
          },
          "2": {
            "get_star_ts": 1639362313
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639419212
          },
          "2": {
            "get_star_ts": 1639450673
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639498958
          }
        }
      },
      "last_star_ts": 1639498958,
      "local_score": 477,
      "stars": 9,
      "id": "1946955",
      "name": "Foster Gorman",
      "global_score": 0
    },
    "1983606": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1641255350
          },
          "2": {
            "get_star_ts": 1641255368
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1641494346
          },
          "2": {
            "get_star_ts": 1641494361
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1642189622
          },
          "2": {
            "get_star_ts": 1642189633
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1642189726
          },
          "2": {
            "get_star_ts": 1642189739
          }
        }
      },
      "last_star_ts": 1642189739,
      "global_score": 0,
      "local_score": 308,
      "stars": 8,
      "id": "1983606",
      "name": "tommy le"
    },
    "1986025": {
      "last_star_ts": 1640283763,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1639708173
          },
          "2": {
            "get_star_ts": 1639708422
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1639708924
          },
          "2": {
            "get_star_ts": 1639712413
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1639714922
          },
          "2": {
            "get_star_ts": 1639741693
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1639777753
          },
          "2": {
            "get_star_ts": 1639778835
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1639836267
          },
          "2": {
            "get_star_ts": 1639930961
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1639934723
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1640091461
          },
          "2": {
            "get_star_ts": 1640091816
          }
        },
        "8": {
          "1": {
            "get_star_ts": 1640092406
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1640131797
          },
          "2": {
            "get_star_ts": 1640133458
          }
        },
        "13": {
          "1": {
            "get_star_ts": 1640148354
          },
          "2": {
            "get_star_ts": 1640149165
          }
        },
        "17": {
          "1": {
            "get_star_ts": 1640283763
          }
        }
      },
      "stars": 19,
      "local_score": 1361,
      "id": "1986025",
      "name": "Ryan Bonilla",
      "global_score": 0
    },
    "2039943": {
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1640620835
          },
          "2": {
            "get_star_ts": 1640622039
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1640624873
          },
          "2": {
            "get_star_ts": 1640633333
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1640636512
          },
          "2": {
            "get_star_ts": 1640815024
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1640837788
          },
          "2": {
            "get_star_ts": 1640838560
          }
        },
        "5": {
          "1": {
            "get_star_ts": 1641780102
          },
          "2": {
            "get_star_ts": 1641780111
          }
        },
        "6": {
          "1": {
            "get_star_ts": 1641780965
          },
          "2": {
            "get_star_ts": 1641782011
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1641859332
          },
          "2": {
            "get_star_ts": 1641859962
          }
        }
      },
      "last_star_ts": 1641859962,
      "stars": 14,
      "id": "2039943",
      "local_score": 741,
      "name": "Jordan Holland",
      "global_score": 0
    },
    "2052936": {
      "last_star_ts": 1642399919,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1641447449
          },
          "2": {
            "get_star_ts": 1641447768
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1641780778
          },
          "2": {
            "get_star_ts": 1641780797
          }
        },
        "4": {
          "1": {
            "get_star_ts": 1641790012
          },
          "2": {
            "get_star_ts": 1641790196
          }
        },
        "7": {
          "1": {
            "get_star_ts": 1642399569
          },
          "2": {
            "get_star_ts": 1642399578
          }
        },
        "10": {
          "1": {
            "get_star_ts": 1642399852
          },
          "2": {
            "get_star_ts": 1642399919
          }
        }
      },
      "global_score": 0,
      "local_score": 532,
      "stars": 10,
      "name": "Kevin Ho",
      "id": "2052936"
    },
    "2064476": {
      "last_star_ts": 1642197709,
      "completion_day_level": {
        "1": {
          "1": {
            "get_star_ts": 1641872142
          },
          "2": {
            "get_star_ts": 1641873344
          }
        },
        "2": {
          "1": {
            "get_star_ts": 1641928280
          },
          "2": {
            "get_star_ts": 1641933381
          }
        },
        "3": {
          "1": {
            "get_star_ts": 1642197709
          }
        }
      },
      "global_score": 0,
      "stars": 5,
      "name": "Kali Kimball",
      "local_score": 146,
      "id": "2064476"
    }
  },
  "owner_id": "186556",
  "event": "2021"
}

  export default Object.values(raw.members)
    .filter(({stars}) => stars);